import { NavLink } from "react-router-dom"

const GenerateNavLink = ({ activeClassHandlers, name, to }) => {
    return (
        <NavLink
            className={activeClassHandlers}
            to={to}
            style={{ textDecoration: "none" }}
        >
            <li>{name}</li>
        </NavLink>
    )
}
export default GenerateNavLink