import React, { useEffect, useState } from "react";
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import varlogo from "../../assets/images/varlogo.svg";
import "../footer/footer.css";
import facebook from "../../assets/images/facebook-f-brands.svg";
import twitter from "../../assets/images/twitter-brands.svg";
import linked from "../../assets/images/linkedin-in-brands.svg";
import insta from "../../assets/images/instagram-brands.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fix_pages } from "../../utils/constants";
import useGlobal from "../../hooks/useGlobal";
import { split_by_new_line } from "../../utils";

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";


import {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

const API_KEY = 'AIzaSyAJg4PGbl1XfwJFKo-vQWlUVtH1Zzg3FEQ'


const Footer = () => {
    const { t } = useTranslation();
    const context = useGlobal();


    /* ISOLATED STATES */
    const [dynamicRoutes] = context.dynamicRoutes;
    const [logo] = context.logo;
    const [socials] = context.socials;
    const [email] = context.email;
    const [address] = context.address;

    const [coordinates, setcCoordinates] = useState([])




    const fetchLatLongs = async add => {
        if (!add) {
            return false
        }
        try {
            const results = await getGeocode({ address: add });
            const { lat, lng } = getLatLng(results[0]);
            console.log('Coordi nated>>>>', { lat, lng })
            setcCoordinates({ lat, lng })
        } catch (error) {
            console.log("🚀 ~ file: Contact.jsx:165 ~ fetchLatLongs ~ error", error)
            return false
        }
    }


    useEffect(() => {
        fetchLatLongs(address)
    }, [address])

    useEffect(() => {
        console.log("coordinates>>>>", coordinates)
    }, [coordinates])


    return (
        <div className="footer">
            <Grid
                container
                spacing={{ xs: 2, md: 0 }}
                columns={{ xs: 4, sm: 8, md: 16 }}
            >
                <Grid item xs={12} sm={4} md={4}>
                    <div className="footer-link">
                        <img src={logo} alt="" width="180px" />
                        <h6> {t("socialMedia")} </h6>
                        <p>
                            {
                                Object.keys(socials).length > 0 &&
                                <>
                                    <a
                                        href={socials?.facebook || " "} target='_blank' rel="noreferrer">
                                        {" "}
                                        <img src={facebook} alt="" />{" "}
                                    </a>
                                    <a
                                        href={socials?.twitter || " "} target='_blank' rel="noreferrer">
                                        {" "}
                                        <img src={twitter} alt="" />{" "}
                                    </a>
                                    <a
                                        href={socials?.linkedin || " "} target='_blank' rel="noreferrer">
                                        {" "}
                                        <img src={linked} alt="" />{" "}
                                    </a>
                                    <a
                                        href={socials?.instagram || " "} target='_blank' rel="noreferrer">
                                        {" "}
                                        <img src={insta} alt="" />{" "}
                                    </a>
                                </>
                            }
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <ul className="footer-menu">
                        <a href="/searchdoctor"> {t("searchDoctor")}</a>
                        <li>
                            {
                                dynamicRoutes[fix_pages.about] &&
                                <Link
                                    to={dynamicRoutes[fix_pages.about] || ""}
                                    className="about"
                                >
                                    {" "}
                                    {t("aboutUs")}
                                </Link>
                            }
                        </li>
                        <li>
                            {
                                dynamicRoutes[fix_pages.contact] &&
                                <Link
                                    to={dynamicRoutes[fix_pages.contact] || ""}
                                    className="about"
                                >
                                    {" "}
                                    {t("contactUs")}
                                </Link>
                            }
                        </li>
                        <li>
                            {" "}
                            <Link to="/var-clinic-doctor-profile"> {t("varClinicDoctor")} </Link>{" "}
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <ul className="footer-menu1">
                        <li className="curs"> {t("callUs")} </li>
                        <li> </li>
                        <a href="/" className="a">
                            {t("address")}
                        </a>
                        {
                            address
                            && split_by_new_line(address).map(
                                line => <li>{line}</li>
                            )
                        }
                    </ul>
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                    {
                        email &&
                        <p className="email">
                            <a href={`mailto:${email}`} className="email">
                                {/* {t("careAi")} */}
                                {email}
                            </a>
                        </p>
                    }

                    <div>
                        {
                            (coordinates.lat && coordinates.lng)
                            && <Map
                                googleMapURL={`http://maps.googleapis.com/maps/api/js?key=${API_KEY}`}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100%` }} />}
                                mapElement={<div style={{ height: `8rem` }} />}
                                center={{
                                    lat: coordinates.lat,
                                    lng: coordinates.lng
                                }}
                                points={coordinates}
                            />
                        }
                    </div>
                </Grid>
            </Grid>
            <hr style={{ margin: "0.7rem 0rem", backgroundColor: "grey" }} />
            <div className="footer-copy">
                <div className="term-content">
                    {
                        dynamicRoutes[fix_pages.terms] &&
                        <Link
                            to={dynamicRoutes[fix_pages.terms] || ""}
                            className="term"
                        >
                            {" "}
                            {t("termsConditions")} <span> | </span>
                        </Link>
                    }
                    {
                        dynamicRoutes[fix_pages.privacy] &&
                        <Link
                            to={dynamicRoutes[fix_pages.privacy] || ""}
                            className="privacy"
                        >
                            {" "}
                            {t("privacy")}{" "}

                        </Link>
                    }
                </div>
                <p> &copy; 2022 VAR Clinic. {t("copyRight")} </p>
            </div>
        </div>
    );
};

const Map = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            zoom={13}
            center={props.center}
        >
            <Marker
                position={{ lat: props?.points?.lat, lng: props?.points?.lng }}
            />

        </GoogleMap>
    ))
);

export default Footer;
