import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import './userappointment.css'
import Slider from 'react-slick'
import { generateQuery } from "../../../utils";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getCaller, updateCaller } from '../../../services/api';
import Appointment from '../../../components/common/Cards/AppointmentPatient';
import { appointmentStatus } from '../../../utils/constants';



import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const INITIAL_RESPONSE = {
    rows: [],
    count: 0,
}


const UpcomingAppointment = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [page, setPage] = useState(1)
    const [sliderRef, setSliderRef] = useState(null)
    const [data, setData] = useState(INITIAL_RESPONSE);
    const [currentSlide, setCurrentSlide] = useState(0)


    const __max = Math.ceil(data?.count / 5);


    const handleSliderChange = (index) => {
        setCurrentSlide(index)
        if (index === data?.rows?.length - 1 && page !== __max) {
            const payload = {
                page: page + 1,
            }
            setPage(page => page + 1)
            fetchUpcomingAppointments(payload)
        }
    }

    const fetchUpcomingAppointments = async (payload) => {
        try {
            let URL = `getAllAppointment?status=${appointmentStatus['Upcoming']}`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            const temp = { ...data };
            temp.rows = [...temp?.rows, ...response?.data?.rows]
            temp.count = response?.data?.count;
            setData({ ...temp })
        } catch (e) {
            setData(INITIAL_RESPONSE)
        }
    }

    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        afterChange: handleSliderChange,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    // dots: true
                }
            },
        ]
    };

    const cancelAppointment = async (id) => {
        if (
            window.confirm('Are you sure you want to cancel ?')
        ) {
            const resp = await updateCaller(`updateappointment/${id}?data=Cancelled`)
            if (resp.message) {
                toast.success('Appointment cancelled successfully')
                fetchUpcomingAppointments({})
            } else {
                toast.info('Some error occurred')
            }
        }
    }

    const handleAttendAppointment = (currentAppointment) => navigate(`/userUpcomingReport/${currentAppointment?.appointmentId}`)

    useEffect(() => {
        fetchUpcomingAppointments({})
    }, [])

    return (
        <>
            <div className="user-app-content">
                {/* upcoming Card */}
                <div className="user-upcoming">
                    <h5>
                        {t("upcomingAppointments")} <span> {data?.count} items </span>
                    </h5>
                    <div className="appointment-arrow">
                        <ArrowBackIosNewIcon
                            sx={{
                                mr: '1rem',
                                cursor: 'pointer',
                                color: currentSlide === 0 ? 'grey' : '#3a9cd8'
                            }}
                            onClick={!(currentSlide === 0) && sliderRef?.slickPrev}
                        />
                        {/* {currentSlide + 1} */}
                        <ArrowForwardIosIcon
                            sx={{
                                cursor: 'pointer',
                                color: currentSlide === data?.rows?.length - 1 ? 'grey' : '#3a9cd8'
                            }}
                            onClick={!(currentSlide === data?.rows?.length - 1) && sliderRef?.slickNext}
                        />
                    </div>
                </div>
                <Slider ref={setSliderRef} {...sliderSettings}>
                    {
                        data?.rows?.length > 0
                            ? data?.rows?.map(
                                (el, index) => {
                                    return (
                                        <Appointment
                                            type={appointmentStatus['Upcoming']}
                                            key={`upcoming-card-${index}`}
                                            appointment={el}
                                            handleAttend={handleAttendAppointment}
                                            handleCancelAppointment={cancelAppointment}
                                        />
                                    )
                                }
                            ) : <>
                                <div
                                    style={{
                                        width: '100%',
                                        padding: '5rem 1rem',
                                        textAlign: 'center'
                                    }}

                                >
                                    No Upcoming  Appointment
                                </div>
                            </>
                    }
                </Slider>
            </div>
        </>
    )
}

export default UpcomingAppointment


/* 

 <div className="user-up-content">
                                        <div className="user-up-body">
                                            <button className="user-upcoming-btn">
                                                {el?.status.toUpperCase() ?? "N/a"}
                                            </button>
                                            <div className="user-app-id">
                                                <div className="user-app-body">
                                                    <p> {t("appointmentID")} </p>
                                                    <h4> {el?.appointmentId ?? "N/a"}</h4>
                                                </div>
                                                <div className="user-app-body">
                                                    <p> {t("appointmentTime")} </p>
                                                    <h4>
                                                        {dateFormatWW(date)} {formatFromToTimeSlot(start, end, 'to', false) || '...'}
                                                    </h4>
                                                </div>
                                                <div className="user-app-body">
                                                    <p> {t("doctorName")} </p>
                                                    <h4> {el?.doctor_profile?.name ?? "n/a"} </h4>
                                                </div>
                                                <div className="user-app-body">
                                                    <p> {t("specialtyChosen")} </p>
                                                    <h4> {el?.serviceName ?? "N/a"} </h4>
                                                </div>
                                                <div className="user-app-body">
                                                    <p> {t("patientName")} </p>
                                                    <h4> {el?.patientName ?? "N/a"} </h4>
                                                </div>
                                                <div className="user-gender">
                                                    <div>
                                                        <p> {t("gender")} </p>
                                                        <h4> {el?.gender ?? "N/a"} </h4>
                                                    </div>
                                                    <div className="user-age">
                                                        <p> {t("age")}</p>
                                                        <h4> {el?.age ?? "N/a"} </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-up-btn">
                                                <div className="user-attend">
                                                    <div
                                                        to="#"
                                                        style={{ color: "#2F77B6", cursor: "pointer" }}
                                                        onClick={async () => {

                                                            navigate(`/userUpcomingReport/${el.appointmentId}`)
                                                        }}
                                                    >
                                                        {t("attend")}
                                                    </div>
                                                </div>
                                                <div className="user-cancel"></div>
                                                <div className="user-attend">
                                                    <Link to="#" style={{ color: "#C4C1C1" }} onClick={() => cancelAppointment(el?.appointmentId)}>
                                                        {t("cancel")}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
*/