import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react'

import up from '../../../assets/images/Group -41.png'

import './bookappointment.css'
import 'react-circular-progressbar/dist/styles.css';
import '../../DoctorDashboard/doctorEditProfile/edit.css'

import Layout from '../../../layout/Layout';

import axios from 'axios';
import * as Yup from 'yup';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { toast } from 'react-toastify';
import { ImCancelCircle } from 'react-icons/im'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { getCaller, postCaller, updateCaller } from '../../../services/api';
import { BASE_URL, calculateAgeFromDate, extractFileExtension, getDiscountedAmount, getOnlyNumber, restrictDICOMExtentions } from '../../../utils';
import { appointmentsSettings, profiles, toast_messages } from '../../../utils/constants';


import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next'
import useGlobal from '../../../hooks/useGlobal';


const dicomLabelInitial = "Select Folder";
const [__SELF, __OTHERS] = ['self', 'other'];
const initial_upload_error = { fileErr: '', filesErr: '' };
const otherAtt = { directory: 'true', webkitdirectory: 'true' };
const APPOINTMENT_INITIAL = {
    age: '',
    gender: '',
    studyPath: '',
    description: '',
    patientName: '',
    bookingFor: 'self',
}

const contactSchema = Yup.object().shape({
    patientName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
    age: Yup.number()
        .min(1, 'min age required 18').required('Age is required'),
    studyPath: Yup.string().required(),
    description: Yup.string()
        .min(10, 'Too Short!')
        .max(300, 'Too Long!')
        .required('Message is Required'),
});


const FillDetails = () => {
    /* Initilizers */
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log("🚀 ~ file: FillDetails.jsx:64 ~ FillDetails ~ state", state)
    const {
        tKey: [tKey],
        userDetails: [userDetails]
    } = useGlobal();

    /* constants */

    /* previous page constants */
    const dataReschedule = state?.data;
    const rescheduled = state?.rescheduled;
    console.log("🚀 ~ file: FillDetails.jsx:74 ~ FillDetails ~ rescheduled", rescheduled)

    /* DOM Refs */
    const dicomLabelRef = useRef(); /* Targeting the label of how man */


    /* isolated states */
    const [reschData, setReschData] = useState()
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState([]);
    const [directoryPath, setDirectoryPath] = useState();
    const [isFilePicked, setIsFilePicked] = useState(true);
    const [attachmentsLoader, setAttachmentsLoader] = useState(false);
    const [dicomLoader, setDicomLoader] = useState(false);
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [appointmentId, setAppointmentId] = useState(null)
    const [multipleProgress, setMultipleProgress] = useState(0);
    const [appointmentMaster, setAppointmentMaster] = useState([])

    /* Error Handling State for File Uploading */
    const [uploadError, setUploadError] = useState(initial_upload_error);
    console.log("appointmentId",appointmentId)

    const getRescheduleData = async () => {
        const { appointmentId } = rescheduled;
        const resultData = await getCaller(`getOneAppointment/${appointmentId}`)
        if (resultData?.data) {
            formik.setValues(values => ({
                ...values,
                patientName: resultData?.data?.patientName ?? '',
                age: resultData?.data?.age ?? '',
                gender: resultData?.data?.gender ?? '',
                description: resultData?.data?.description ?? '',
                loginId: resultData?.data?.loginId ?? '',
                appointmentId: resultData?.data?.appointmentId ?? '',
                // patientName:resultData?.data[0]?.patientName ?? '', 
            }))
        }
    }


    const handleUploadErrorMessage = (whatToPrintFor, errMsg) => {
        if (whatToPrintFor === 'FILE') {
            setUploadError(
                (prev) => {
                    return {
                        ...prev,
                        fileErr: errMsg
                    }
                }
            );
        } else {
            setUploadError(
                (prev) => {
                    return {
                        ...prev,
                        filesErr: errMsg
                    }
                }
            );
        }
    }

    const changeHandler = async (event) => {
        setUploadError({})
        try {
            const files = selectedFile.map(e => [e.name, e.size]);
            let filteredFiles = [];
            for (const file of event.target.files) {
                if (!selectedFile.find(e => e.name === file.name && e.size === file.size)) {
                    filteredFiles.push(file);
                }
            }
            if (event.target.files.length > filteredFiles.length) {
                handleUploadErrorMessage('FILE', 'Some Uploaded files were duplicate and thus were omitted')
                return;
            }
            if (filteredFiles.length + selectedFile.length >= 6) {
                handleUploadErrorMessage('FILE', '* Only 5 files are allowed')
                return;
            }
            let filesToUpload = [];
            for (const file of filteredFiles) {
                const fsize = file.size;
                const mimeType = ['png', 'jpg', 'jpeg', 'pdf'];
                let fileType = file.name.split('.')[1]

                const fileActualSize = Math.round((fsize / 1024));
                if (fileActualSize >= 1024) {
                    handleUploadErrorMessage(
                        'FILE',
                        `* The File '${file.name}' is too large, please select a file sized less than 1mb`
                    )
                    return;
                }
                if (!mimeType.includes(fileType)) {
                    handleUploadErrorMessage(
                        'FILE',
                        '* Only JPG, PDF and PNG Format are accepted'
                    )
                    return;
                } else {
                    filesToUpload.push(file);
                    setIsFilePicked(false);
                }
            }
            setSelectedFile([...selectedFile, ...filesToUpload]);
        } catch (err) {
            console.error(err)
        }
        event.target.value = '';
    }


    const formik = useFormik({
        initialValues: APPOINTMENT_INITIAL,
        validationSchema: contactSchema,
        onSubmit: async (values) => {
            if (
                !(window.confirm(`Are you sure you want to save these details?`))
            ) {
                return;
            }


            /* Handling if DICOM File is uploaded or not */
            if (multipleFiles.length <= 0) {
                handleUploadErrorMessage(
                    'FILES',
                    'Please upload DICOM File'
                )
                return;
            }

            // deleting studyPath
            delete values.studyPath;

            // let doctorId = JSON.parse(localStorage.getItem('docData'))
            // let appointmentTime = JSON.parse(localStorage.getItem('date'))
            let doctorId = state?.selectedDoctor;
            let slotDetails = state?.slotDetails;
            delete values.loginId
            doctorId = {
                // 'serviceId': doctorId?.subServiceId,
                'appointmentId': appointmentId,
                'directoryPath': directoryPath,
                'doctorId': doctorId?.loginId,
                'fees': getDiscountedAmount(doctorId?.fees, doctorId?.offer['coupons.discount']),
                'slotId': slotDetails?.slot?.id,
                'slotDate': slotDetails?.date,
                'slot': [{
                    date: slotDetails.date,
                    slotDetails: slotDetails,
                    time: {
                        start: slotDetails.slot?.start,
                        end: slotDetails.slot?.end
                    }
                }]
            }
            let postData = Object.assign(values, doctorId)

            if (rescheduled) {
                let _appointmentId = rescheduled?.appointmentId;

                const rescheResponse = await updateCaller(`userRescheduled/${_appointmentId}`, postData)
                if (rescheResponse?.error === false) {
                    return navigate('/dashboard')
                } else {
                    toast.info(rescheResponse?.msg || toast_messages.common.UNKNOWN)
                }
            } else {
                const response = await postCaller('createAppointment', values);
                if (response?.error === false) {
                    return navigate(
                        '/paymentFill',
                        {
                            state: {
                                data: appointmentId
                            }
                        }
                    )
                } else {
                    return toast.info(response?.msg || toast_messages.common.UNKNOWN)
                }

            }
        }
    }
    );


    const uploadFileBtn = async (_event, doAlert) => {
        setAttachmentsLoader(true)
        let event = _event;
        if (!event) {
            event = {
                target: {
                    files: []
                }
            }
        }

        let formData = new FormData();

        const finalarr = selectedFile.slice(0, 5);
        if (finalarr.length > 0) {

            for (const key of finalarr) {
                if (Math.round((key.size / 1024)) > 1024) {
                    handleUploadErrorMessage(
                        'FILE',
                        '* File size is too Big, select the file less than 1mb'
                    )
                } else {
                    formData.append('docs', key);
                    console.log(key)
                }
            }
        }

        const response = await postCaller('appointmentFile', formData, true);
        if (response) {
            doAlert && toast.info('All Files Uploaded successfully')
        } else {
            doAlert && toast.info('File not Uploaded')
        }
        setAppointmentId(response.appointmentId)
        setAttachmentsLoader(false)
        return response?.appointmentId;
    }

    const isValidDICOMFiles = files => {
        const _files = Array.from(files);

        if (!_files) {
            return false
        }

        for (let i = 0; i < _files.length; i++) {
            const current = _files[i];
            const extension = extractFileExtension(current.name)
            const anyRestrictedExt = restrictDICOMExtentions(extension);
            if (anyRestrictedExt) {
                return false;
            }
        }
        return true;
    }


    const MultipleFileChange = (e) => {
        setLoading(true)
        const files = e.target.files;
        if (!isValidDICOMFiles(files)) {
            setMultipleFiles([]);
            handleUploadErrorMessage(
                'FILES',
                'Invalid DICOM File'
            )
            return;
        }

        if (files?.length > 0) {
            const size = Array.from(files).reduce((acc, next) => acc += next.size, 0)
            if (size > 1000000000 /* 1000 mb (1GB) in Bytes */) {
                setMultipleFiles([]);
                handleUploadErrorMessage(
                    'FILES',
                    'DICOM file can not be more than 1 GB'
                )
                return;
            }
            /* for displaying text only */
            dicomLabelRef.current.innerText = `${files.length} files selected`

            setLoading(false)
            handleUploadErrorMessage({})
            setMultipleFiles(files);
            // setLoading(false) /*  */
        } else {
            setLoading(true)
            setMultipleFiles([]);
            dicomLabelRef.current.innerText = dicomLabelInitial
            handleUploadErrorMessage(
                'FILES',
                'Please upload DICOM File'
            )
        }
    }


    const UploadMultipleFiles = async () => {
        try {
            formik.setFieldError('studyPath', '**required**')
            let _appointmentId = appointmentId;
            if (!appointmentId) {
                _appointmentId = await uploadFileBtn();
            }

            if (multipleFiles.length <= 0) {
                handleUploadErrorMessage(
                    'FILES',
                    '*Please upload a file'
                )
                return;
            }

            let formData = new FormData();
            // const finalarr = multipleFiles.slice(0, 5);

            for (const key of multipleFiles) {
                // if (Math.round(key.size / 1024) > 1024) {
                //   setUploadError('* File size is too Big, select the file less than 1mb');
                // } else {

                //  key.name=window.btoa(key.webkitRelativePath);
                formData.append('dcmfiles', key);

                // console.log('key',key);
                //}
            }

            setLoading(true);
            const multires = await axios(
                `${BASE_URL}/uploadfilemulti`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        booking_id: _appointmentId
                    },
                    data: formData,
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
                        setMultipleProgress(Math.abs(percentage - 10));
                    }
                }
            );

            console.log("🚀 ~ file: FillDetails.jsx:425 ~ UploadMultipleFiles ~ multires?.data?.status", multires?.data)
            if (multires?.data?.status) {
                if (multires.data.directoryPath) {
                    setDirectoryPath(multires.data.directoryPath);
                    formik.setFieldValue('studyPath', multires.data.directoryPath)
                }
                setMultipleProgress(100);
                toast.info('All files uploaded successfully');
            } else {
                setMultipleProgress(0);
                toast.error(multires?.data?.errorMsg || 'Some error...');
            }
        } catch (error) {
            toast.error('Some error...');
            console.error("🚀 ~ file: FillDetails.jsx:374 ~ UploadMultipleFiles ~ error", error)
        } finally {
            if (multipleFiles.length === 0) {
                handleUploadErrorMessage(
                    'FILES',
                    'Please upload a file'
                )
                return;
            }
            setLoading(false)
        }

    };


    const deleteHandler = (index) => {
        const finalarr = [...selectedFile];
        finalarr.splice(index, 1)
        setSelectedFile(finalarr);
        if (finalarr.length === 0) {
            setIsFilePicked(true)
        }
    }
    const resDelete = (index) => {
        const finalarr = [...dataReschedule];
        finalarr.splice(index, 1)
        setReschData(finalarr)
        if (finalarr.length === 0) {
            setReschData(true)
        }
    }

    function cancel() {
        // formik.resetForm({ values: '' })
        navigate('/dashboard');
    }

    /* Appointment API */
    const getAppointmentMaster = async () => {
        const response = await getCaller(`master/profile-settings?type=${profiles.appointment}`)
        setAppointmentMaster(response.data)
    }

    const handleFormikOnSelfOrOther = payload => {
        const { name, age, gender } = payload || { name: '', age: '', gender: '' };
        formik.setFieldValue('patientName', name)
        formik.setFieldValue('age', age)
        formik.setFieldValue('gender', gender?.toLowerCase())
    }

    const decideOnSelfOrOther = () => {
        /* userDetails ---------- Global State that sets when react app boots */
        const booking_for = formik.values.bookingFor;
        console.log('🚀 ~ file: FillDetails.jsx ~ line 448 ~ useEffect ~ booking_for', booking_for)

        if (booking_for === __OTHERS) {
            if (
                formik.values.patientName === ''
                && formik.values.patientName === ''
                && formik.values.patientName === ''
            ) {
                return;
            }
            handleFormikOnSelfOrOther();
        } else {
            const calculatedAge = calculateAgeFromDate(userDetails.dob);
            const payload = {
                name: userDetails.name || '',
                age: calculatedAge || '',
                gender: userDetails.gender || ''
            }
            handleFormikOnSelfOrOther(payload)
        }
    }

    const handleMemberChange = ({ target: { value } }) => {
        const targetMember = userDetails?.members[value];
        console.log("🚀 ~ file: FillDetails.jsx:476 ~ handleMemberChange ~ targetMember", targetMember)
        if (targetMember) {/* checking because there is a -1 index for unselect values */
            handleFormikOnSelfOrOther(targetMember)
        } else {
            handleFormikOnSelfOrOther()
        }
    }

    /* --------------------------------------- */
    /* NOTE-- can not combine these useEffects because userDetails will only change once, so have to call it seperatly */
    useEffect(() => {
        decideOnSelfOrOther();
    }, [formik.values.bookingFor]);

    useEffect(() => {
        decideOnSelfOrOther()
    }, [userDetails])
    /* --------------------------------------- */

    useEffect(() => {
        rescheduled && getRescheduleData();
        getAppointmentMaster();
    }, [])

    // console.log('formik.errors :>> ', formik.errors);
    // console.log('formik.values :>> ', formik.values);
    return (
        <>
            <Layout>
                <div className='step-c'>

                    <form onSubmit={e => e.preventDefault()}>
                        <div className='stepper'>
                            <div className='stepper-content'>
                                <Stepper activeStep={1}>
                                    <Step>
                                        <StepLabel>Appointment</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel> Fill Details </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel> Payment </StepLabel>
                                    </Step>
                                </Stepper>
                            </div>
                            <div className='step-border'></div>
                            <div className='second-step'>
                                <div className='book-for-new'>
                                    <label
                                        htmlFor=''> {appointmentMaster[appointmentsSettings['Booking for']]?.label?.[tKey]}: </label>
                                    <input
                                        id='__self'
                                        type='radio'
                                        value={__SELF}
                                        name='bookingFor'
                                        style={{ marginLeft: '1rem' }}
                                        onChange={formik.handleChange}
                                        checked={formik.values.bookingFor === __SELF}
                                    />
                                    <label
                                        className='not-selectable'
                                        htmlFor='__self'
                                        style={{ marginLeft: '.5rem' }}
                                        disabled={appointmentMaster[appointmentsSettings['Booking for']]?.status == 1 ? false : true}
                                    >
                                        Self
                                    </label>

                                    <input
                                        id='__others'
                                        type='radio'
                                        value={__OTHERS}
                                        name='bookingFor'
                                        style={{ marginLeft: '2rem' }}
                                        onChange={formik.handleChange}
                                        checked={formik.values.bookingFor === __OTHERS}
                                    />

                                    <label
                                        htmlFor='__others'
                                        className='not-selectable'
                                        style={{ marginLeft: '.5rem' }}
                                    >
                                        Other
                                    </label>

                                </div>
                                <div className='edit-second-input'>
                                    <div className='edit-name'>
                                        <label
                                            htmlFor='name'>{appointmentMaster[appointmentsSettings['Patient Name']]?.label?.[tKey]}*</label>
                                        <input type='text' id='name' name='patientName' placeholder='Enter name'
                                            value={formik.values.patientName}
                                            onChange={formik.handleChange}
                                            disabled={
                                                (
                                                    (appointmentMaster[appointmentsSettings['Patient Name']]?.status == 1)
                                                    || formik.values.bookingFor === __SELF
                                                )
                                                    ? false : true}

                                        />
                                        {formik.touched.patientName && formik.errors.patientName && (
                                            <span className='error'>{formik.errors.patientName}</span>
                                        )}
                                    </div>

                                    {/* Only visible when user selects Others option */}
                                    {
                                        formik.values.bookingFor === __OTHERS
                                        && <div className='edit-name' style={{ width: '20%' }}>
                                            <label htmlFor='country'>Member</label>
                                            <select
                                                id='mambers'
                                                name='mambers'
                                                className='edit-select-field'
                                                onChange={handleMemberChange}
                                            >
                                                <option value='-1'>Select Member</option>
                                                {
                                                    Array.isArray(userDetails?.members)
                                                    && (
                                                        userDetails.members.length > 0
                                                            ? userDetails.members.map((item, index) => <option
                                                                key={`patients-members-${index}`}
                                                                value={index}>{item?.name}</option>)
                                                            : <>
                                                                <option value='null' disabled>No Members</option>
                                                            </>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    }
                                    {/* Only visible when user selects Others option */}

                                    <div className='edit-age'>
                                        <label
                                            htmlFor='age'> {appointmentMaster[appointmentsSettings['Age']]?.label?.[tKey]} * </label>
                                        <input type='text' id='age' name='age' placeholder='Enter age'
                                            value={formik.values.age}
                                            onChange={
                                                e => {
                                                    formik.setFieldValue(
                                                        'age',
                                                        getOnlyNumber(formik.values.age, e.target.value, 200) /* Custom function to get only number in string */
                                                    )
                                                }
                                            }
                                            disabled={appointmentMaster[appointmentsSettings['Age']]?.status == 1 ? false : true}

                                        />
                                        {formik.touched.age && formik.errors.age && (
                                            <span className='error'>{formik.errors.age}</span>
                                        )}
                                    </div>
                                    <div className='edit-select'>
                                        <label
                                            htmlFor='country'> {appointmentMaster[appointmentsSettings['Gender']]?.label?.[tKey]} * </label>
                                        <select id='gender' name='gender' className='edit-select-field'
                                            value={formik.values.gender}
                                            onChange={
                                                formik.handleChange
                                            }
                                            disabled={appointmentMaster[appointmentsSettings['Gender']]?.status == 1 ? false : true}

                                        >
                                            <option value='' disabled>Select gender</option>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>

                                        </select>
                                        {formik.touched.gender && formik.errors.gender && (
                                            <span className='error'>{formik.errors.gender}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='edit-textarea-second'>
                                    <label
                                        htmlFor='desc'> {appointmentMaster[appointmentsSettings['Description']]?.label?.[tKey]} </label>
                                    <br />
                                    <textarea name='description' id='desc' placeholder='Describe your problem here'
                                        value={formik.values.description} onChange={
                                            formik.handleChange
                                        }
                                        disabled={appointmentMaster[appointmentsSettings['Description']]?.status == 1 ? false : true}

                                    ></textarea>
                                    {formik.touched.description && formik.errors.description && (
                                        <span className='error'>{formik.errors.description}</span>
                                    )}
                                </div>
                                {/* file upload  */}
                                <div className='file-upload-container'>
                                    <div className='label-file'>
                                        <label
                                            htmlFor=''> {appointmentMaster[appointmentsSettings['Upload Documents']]?.label?.[tKey]}</label>
                                    </div>
                                    <div className='file-container'>
                                        <input type='file' name='' id='uploadImage' multiple='multiple'
                                            accept='image/png, image/jpeg, application/pdf' hidden
                                            onChange={changeHandler}
                                            disabled={appointmentMaster[appointmentsSettings['Upload Documents']]?.status == 1 ? false : true}
                                        />
                                        <div className='upload-file'>
                                            <p>Choose File </p>
                                            <label className='file-input-field' htmlFor='uploadImage'> <img src={up}
                                                alt='' />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {dataReschedule?.data?.fileData?.map((e, index) => (
                                    <>
                                        <li className='file-preview' key={index}>
                                            <div className='file-preview-name'> {e?.fileName} </div>
                                            <div className='delete-icon' onClick={() => resDelete(index)}>
                                                <ImCancelCircle /></div>
                                        </li>
                                    </>
                                ))}
                                {selectedFile && selectedFile?.map((file, index) => (
                                    <li className='file-preview' key={index}>
                                        <div className='file-preview-name'>{file.name} </div>
                                        <div className='delete-icon' onClick={() => deleteHandler(index)}>
                                            <ImCancelCircle /></div>
                                    </li>))}
                                {uploadError.fileErr ? <p className='error'>{uploadError.fileErr}</p> : <></>}
                                <button
                                    onClick={event => uploadFileBtn(event, true)}
                                    className='upload-btn'
                                    disabled={selectedFile.length === 0 || attachmentsLoader}
                                >
                                    Upload File
                                </button>
                            </div>

                            {/* dicom file  */}
                            <div className='file-upload-container-1'>
                                <div className='label-file'>
                                    <label htmlFor=''>
                                        DICOM File *
                                    </label>
                                </div>
                                <div className='file-container'>
                                    <input
                                        className='d-none'
                                        type='file'
                                        id='folder'
                                        {...otherAtt}
                                        onChange={MultipleFileChange}
                                    />
                                    <div className='upload-file'>
                                        <p ref={dicomLabelRef}>Select Folder</p>
                                        <label className='file-input-field' htmlFor='folder'>
                                            <img src={up} alt='' />{' '}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='file-upload-container-1'>
                                <div className='label-file'>
                                </div>
                                <div className='file-container' style={{ border: 'none' }}>
                                    <p className='error'>{uploadError.filesErr}</p>
                                </div>
                            </div>
                            <div className='dicom-file'>
                                {/* {uploadError.filesErr ? <p className='error'>{uploadError.filesErr}</p> : <></>} */}
                                <button
                                    disabled={multipleFiles.length === 0 || loading}
                                    onClick={UploadMultipleFiles}
                                    className='upload-btn'>
                                    Upload File(s){' '}
                                </button>
                                <div className='col-2'
                                    style={{
                                        width: '18%',
                                        padding: '1.5rem'
                                    }}
                                >
                                    <CircularProgressbar
                                        value={multipleProgress}
                                        text={`${multipleProgress}%`}
                                        styles={buildStyles({
                                            rotation: 0.25,
                                            strokeLinecap: 'butt',
                                            textSize: '16px',
                                            pathTransitionDuration: 1,
                                            pathColor: `rgba(45, 85, 255, ${multipleProgress / 100})`,
                                            textColor: '#0033CC',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#3e98c7',
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='first-btn-content'>
                                <button className='step-cancel-btn'
                                    onClick={cancel}
                                >
                                    {t('cancel')}
                                </button>
                                <div className='first-back'>
                                    <button className='back' onClick={() => navigate('/bookappointment')}> Back</button>
                                    <button
                                        type='submit'
                                        disabled={Object.keys(formik.errors)?.length > 0}
                                        className='proceed-btn'
                                        onClick={() => formik.handleSubmit()}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Layout>
        </>
    )
}

export default FillDetails