import React, { useEffect, useState, useCallback, useRef } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import eye from '../../../assets/images/eye.png';
import down from '../../../assets/images/download-4.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dateFormatWW, generateImageURL, generateQuery, showDob } from '../../../utils';
import { getCaller } from '../../../services/api';
import CustomPagination from '../../../components/CustomPagination';
import { CSVLink } from 'react-csv';
import '../userappointment/userappointment.css'
import { constants } from '../../../utils/constants';
import MedicalRecordModal from './MedicalRecordModal';
import useGlobal from '../../../hooks/useGlobal';

const UserMedicalRecord = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { tKey: [tKey] } = useGlobal();

    const searchTextFieldRef = useRef(null);

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [modal, setModal] = useState(false);
    const [records, setRecords] = useState({});

    const maximo = records?.count / 5;


    const handleModalClose = () => setModal(false);
    const handleModalOpen = () => setModal(true);


    const handleDownloadFile = record => {
        window.open(record?.filePath, '_blank')
    }

    const fetchMedicalRecords = async payload => {
        try {
            let URL = `getMedicalRecords?`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setRecords(response?.data || {})
        } catch (e) {
            setRecords({})
        }
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        const payload = {
            ...query,
            searchText: value,
            page: 1
        }
        setQuery(payload)
        fetchMedicalRecords(payload)
    }



    const handleViewAppointment = (currentAppointment) => {
        return navigate(`/userDownloadReport/${currentAppointment?.appointmentId}`)
    }

    const nextData = page => {
        const payload = {
            ...query,
            page
        }
        fetchMedicalRecords(payload)
    }


    useEffect(() => {
        nextData(page)
    }, [page])


    useEffect(() => {
        fetchMedicalRecords({})
    }, [])

    return (
        <>
            <MedicalRecordModal
                open={modal}
                handleClose={handleModalClose}
                refreshData={fetchMedicalRecords}
                setQuery={setQuery}
            />
            <div className="user-past-content">
                <div className="user-past">
                    <h4> Medical Records </h4>
                </div>

                <div className="filter-field">
                    <p> {records?.count} items </p>
                    <div className="filter-search">
                        <div className="user-past-card">
                            <input
                                type="input"
                                id="search-box"
                                placeholder="Search "
                                ref={searchTextFieldRef}
                                onKeyUp={event => event.key === 'Enter' && handleTextFieldButton()}
                            />
                            <label
                                htmlFor="search-box"
                                className="fas fa-search"
                                onClick={handleTextFieldButton}
                            ></label>
                        </div>
                        <div className="filter-button">

                            <button onClick={handleModalOpen}> Filter </button>
                        </div>
                    </div>
                </div>
                <div className='user-table-past'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell> File Name </StyledTableCell>
                                    <StyledTableCell> Date Added </StyledTableCell>
                                    <StyledTableCell> Report for </StyledTableCell>
                                    <StyledTableCell> Appointment ID </StyledTableCell>
                                    <StyledTableCell> Doctor Name </StyledTableCell>
                                    <StyledTableCell> Patient Name </StyledTableCell>
                                    <StyledTableCell> Action </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    records?.rows?.map((item, i) => {
                                        const {
                                            dateAdded,
                                            doctor_profile,
                                            patientName,
                                            service
                                        } = item?.appointment || {};
                                        return (
                                            <>
                                                <StyledTableRow >
                                                    <StyledTableCell component="th" scope="row">
                                                        {item?.fileName ?? constants.DOTS}
                                                    </StyledTableCell>
                                                    <StyledTableCell> {dateFormatWW(dateAdded) ?? constants.DOTS} </StyledTableCell>
                                                    <StyledTableCell> {service?.name?.[tKey] ?? constants.DOTS} </StyledTableCell>
                                                    <StyledTableCell> {item?.appointmentId ?? constants.DOTS} </StyledTableCell>
                                                    <StyledTableCell> {doctor_profile?.name ?? constants.DOTS} </StyledTableCell>
                                                    <StyledTableCell> {patientName ?? constants.DOTS} </StyledTableCell>

                                                    <StyledTableCell
                                                        className='table-edit-one'
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start'
                                                        }}
                                                    >
                                                        <img src={eye} alt=""
                                                            onClick={() => handleViewAppointment(item)}
                                                        />
                                                        <div onClick={() => handleDownloadFile(item)}>
                                                            <img src={down} alt="" />
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>
                <CustomPagination
                    page={page} setPage={setPage} maximo={maximo}
                />
            </div>
        </>
    )
}

const StyledTableRow = styled(TableRow)(
    ({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    })
);

const StyledTableCell = styled(TableCell)(
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#D5F1FF",
            color: "#707070",
            border: 0,
            fontSize: 13,
            fontWeight: 600,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            border: 0,
            fontWeight: 600,
            color: "#1A1919",
            textTransform: "capitalize",
            letterSpacing: 'unset',
        },
    }
);


export default UserMedicalRecord