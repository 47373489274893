import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../doctorAppointment/appointment.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import arrowleft from "../../../assets/images/Path -33.png";
import arrowright from "../../../assets/images/Path -34.png";
import { getCaller } from "../../../services/api";
import { useEffect } from "react";
import GenerateNavLink from "../../../components/common/GenerateNavlink";
import { AppointmentDoctor } from "../../../components/common/Cards/AppointmentDoctor";

const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'doctor-list-tab doctor-list-active-tab' : 'doctor-list-tab' }
const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                // dots: true
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
            },
        },
    ],
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 450,
    borderRadius: "1rem",
    bgcolor: "white",
};



const AllAppointment = () => {
    const path = useLocation();
    const navigate = useNavigate();

    const [sliderRef, setSliderRef] = useState(null);
    const [todayAppointments, setTodayAppointments] = useState([]);

    // const [isLoading, setIsLoading] = useState(false); /* might use for loading */

    const DoctorAppointmentsLinks = [
        {
            path: '',
            activeClassHandlers: () => path?.pathname === '/dashboard/doctor/appointments' ? 'doctor-list-tab doctor-list-active-tab' : 'doctor-list-tab',
            name: 'Review Appointment'
        },
        {
            path: 'upcoming',
            activeClassHandlers: setActiveInactiveClass,
            name: 'Upcoming Appointment'
        },
        {
            path: 'past',
            activeClassHandlers: setActiveInactiveClass,
            name: 'Past Appointment'
        },
    ]


    const fetchTodaysAppointments = async () => {
        try {
            const response = await getCaller('getTodayAppointments');
            if (response?.error === false) {
                setTodayAppointments(response?.data || {})
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handleViewUpcoming = currentAppointment => {
        navigate(`/doctorUpcomingReport/${currentAppointment?.appointmentId}`)
    }

    useEffect(() => {
        fetchTodaysAppointments()
        /* other requests are at their specific components see line: 235 */
    }, []);

    return (
        <>
            <div className="wrapper-upcoming">
                <div className="wrapper-upcoming-head">
                    <div className="wrapper-up-para">
                        <p>Today's Appointments</p>
                        <small style={{ marginLeft: ".5rem" }}>
                            {" "}
                            {todayAppointments?.count} items
                        </small>
                    </div>
                    <div className="">
                        <img
                            src={arrowleft}
                            alt=""
                            style={{ marginRight: "1rem", cursor: "pointer" }}
                            onClick={sliderRef?.slickPrev}
                        />
                        <img
                            src={arrowright}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={sliderRef?.slickNext}
                        />
                    </div>
                </div>
                <Slider ref={setSliderRef} {...settings}>
                    {
                        todayAppointments?.rows?.length > 0
                            ? todayAppointments?.rows?.map(
                                (item, index) => {
                                    return (
                                        <AppointmentDoctor
                                            key={`todays_appointment_${item?.appointmentId || index}`}
                                            appointment={item}
                                            handleViewUpcoming={handleViewUpcoming}
                                        />
                                    )
                                })
                            : <>
                                <div className="wrapper-up-card">
                                    <div style={{ padding: '1rem 3rem' }}>No appointment found</div>
                                </div>
                            </>
                    }
                </Slider>
            </div>

            <div className="doctor-tab-container">
                <ul>
                    {
                        DoctorAppointmentsLinks.map(link => {
                            return (
                                <GenerateNavLink
                                    activeClassHandlers={link.activeClassHandlers}
                                    name={link.name}
                                    to={link.path}
                                />
                            )
                        })
                    }
                </ul>
                <Outlet />
                {/* <div className="" hidden={tabIndex !== 0}>
                    <DoctorReviewAppointment data={tableData} />
                </div>
                <div className="" hidden={tabIndex !== 1}>
                    <DoctorUpcomingAppointment data={tableData} />
                </div>
                <div className="" hidden={tabIndex !== 2}>
                    <DoctorPastAppointment data={tableData} />
                </div> */}
            </div>
        </>
    );
};

export default AllAppointment;
