import { Avatar } from "@mui/material";
import useGlobal from "../../../hooks/useGlobal";
import { generateImageURL } from "../../../utils"

const TeamCard = ({
    data
}) => {
    const { tKey: [tKey] } = useGlobal();


    return (
        <div className="teamcard">
            <div className="teambody">
                <div className='team-image'>
                    <div>
                        <Avatar
                            sx={{
                                height: '100%',
                                width: '100%'
                            }}
                            variant='square'
                            src={data.profile && generateImageURL(data.profile)}
                        />
                    </div>
                </div>
                <div className='team-title'> <h4> {data.name?.[tKey]} </h4>
                    {data.designation?.[tKey]}
                </div>
            </div>
            <div className='teambody-content'>
                <p>{data.about?.[tKey]}</p>
            </div>
        </div>
    )
}

export default TeamCard;