import React, { useState } from 'react'
import Layout from '../../layout/Layout'
import '../contact/terms.css';
import { useEffect } from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { getCaller } from '../../services/api';
import { fix_pages } from '../../utils/constants';
import useGlobal from '../../hooks/useGlobal';
import { Typography } from '@mui/material';

const Terms = () => {
    const [data, setData] = useState([]);
    const { tKey: [tKey] } = useGlobal();
    const getTermsConditions = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages.terms}`);
            if (response?.error === false && response?.data) {
                setData(response?.data);
            } else {
                // can be use to set dummy data or set initial values
            }
        } catch { }
    }


    useEffect(() => {
        getTermsConditions()
    }, [])
    return (
        <Layout>
            <Breadcrumb head="Terms and Condition" />
            <h2 className='terms-heading'>{data?.page_name && data?.page_name?.[tKey]}</h2>
            {
                Array.isArray(data?.content) && data.content.map(item => (
                    <div className="terms-content">
                        <div>{item?.title && item.title[tKey]}</div>
                        <div className="terms-para-content">
                            <p >{item?.content && item?.content?.[tKey]}</p>
                        </div>
                    </div>
                ))
            }

        </Layout>
    )
}

export default Terms