import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import useMasters from '../../../hooks/userMasters';
import useGlobal from '../../../hooks/useGlobal';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
})

const TabsStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '22px',
}
const ParticularTab = {
    width: '9rem',
    borderRadius: '22px',
    fontSize: '80%'
}


export default function BasicTabs({
    changeSlider
}) {
    const { masterServices } = useMasters();

    const { tKey: [tKey] } = useGlobal();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log("🚀 ~ file: ServiceTabs.jsx:45 ~ handleChange ~ newValue", newValue)
        setValue(newValue);
        // changeSlider(newValue)
    };

    return (
        <>
            {
                masterServices?.length >= 2 &&
                <StyledBox>
                    <Tabs
                        sx={TabsStyle}
                        value={value}
                        onChange={handleChange}
                        aria-label='master-services-tabs'>
                        {
                            masterServices.map(
                                (service, index) => (
                                    <Tab
                                        key={`basic-tabs-${index}`}
                                        onClick={() => changeSlider(service?.id)}
                                        sx={ParticularTab}
                                        label={service?.name?.[tKey]}
                                        {...a11yProps(service?.id)}
                                    />
                                )
                            )
                        }

                    </Tabs>
                </StyledBox>
            }
        </>
    );
}
