import "./view.css";
import React, { useEffect, useState } from "react";
import e from "../../../assets/images/varlogo.svg";
import down from "../../../assets/images/Group 5853.png";
import Navbar from "../../../layout/Navbar/Navbar";
import Footer from "../../../layout/footer/Footer";
import { useLocation, useParams } from "react-router-dom";
import { dateFormatWW, dateFormatWW_TIME, destructureSlot, formatFromToTimeSlot, formatTime, showDob } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import file from "../../../assets/images/file.png";
import { BiDownload, BiFile } from "react-icons/bi";
import DicomModal from "../../../components/modal/DicomModal";
import { getCaller } from "../../../services/api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pdf from "react-to-pdf";
import { ImageList, ImageListItem } from "@mui/material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { extensions } from "../../../utils/constants";
import { constants } from '../../../utils/constants';
import useGlobal from '../../../hooks/useGlobal';

const ref = React.createRef();


const UserDownloadReport = (props) => {
    const navigate = useNavigate();
    const { tKey: [tKey] } = useGlobal();

    const { state } = useLocation();
    const { appointmentId } = useParams();

    const [currentAppointment, setCurrentAppointment] = useState();
    const [appointmentReport, setAppointmentReport] = useState([]);

    const [attachmentsImages, setAttachmentsImages] = useState([]);
    const [attachmentsOthers, setAttachmentsOthers] = useState([])

    const handleOpenImage = path => window.open(path, '_blank')

    const backHandle = e => {
        return window.history.back()
    };

    const options = {
        orientation: "landscape",
        unit: "in"
    };


    const fetchAttachments = async () => {
        try {
            const response = await getCaller(`getOneFile/${appointmentId}`)

            /* if found any report result in appointment */
            if (response?.result) {
                setAppointmentReport(response?.result || {})
            }
            let images = [];
            let others = [];
            Array.isArray(response?.data) && response.data.forEach(
                attachment => {
                    const extension = attachment?.fileName?.split('.').pop();
                    extensions.IMAGES.includes(extension) ? images.push(attachment) : others.push(attachment)
                }
            )
            setAttachmentsImages(images)
            setAttachmentsOthers(others)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchAppointment = async () => {
        try {
            const response = await getCaller(`getOneAppointment/${appointmentId}`);
            if (response?.status === true) {
                setCurrentAppointment(response?.data || {})
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        fetchAppointment();
        fetchAttachments();
    }, []);


    const { start, end } = destructureSlot(currentAppointment)?.slot || {};

    return (
        <>
            <Navbar />
            <div className="view-container-1">
                <div className="down-view">
                    <button onClick={backHandle} className="back-btn">
                        {" "}
                        <FaAngleLeft /> Back{" "}
                    </button>
                    <div className="down-view-content" ref={ref}>
                        <div className="view-logo">
                            <img src={e} alt="" />
                        </div>
                        <div className="download-view-content">
                            <div className=""></div>
                            <div className="virtual-1">
                                <h3>VIRTUAL CLINIC SUMMARY</h3>
                            </div>
                            <div className="download-view-content-1">
                                <Pdf
                                    targetRef={ref}
                                    filename="VirtualClinicReport"
                                    options={options}
                                    x={0.1}
                                    y={0.5}
                                    scale={0.75}
                                >
                                    {({ toPdf }) => (
                                        <img
                                            src={down}
                                            alt=""
                                            className="down-img-btn"
                                            onClick={toPdf}
                                        />
                                    )}
                                </Pdf>
                            </div>
                        </div>
                        <div className="down-details">
                            <h4>Appointments Details</h4>
                            <div className="down-details-border1">
                                <div className="common-card">
                                    <div className="common-body">
                                        <p> Appointment ID </p>
                                        <h4> {currentAppointment?.appointmentId ?? "n/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Appointment Date & Time </p>
                                        <h4>
                                            {
                                                dateFormatWW(currentAppointment?.slotDate)
                                            }
                                            {' '}
                                            {
                                                formatFromToTimeSlot(start, end, '-')
                                            }
                                        </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Booking Date </p>
                                        <h4>
                                            {
                                                dateFormatWW_TIME(currentAppointment?.createdAt)
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="down-details-border1">
                                <h4>Patients Details</h4>
                                <div className="common-card">
                                    <div className="common-body">
                                        <p> Patient ID </p>
                                        <h4> {currentAppointment?.user_profile?.loginId ?? "n/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Patient Name </p>
                                        <h4> {currentAppointment?.patientName ?? "n/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Gender </p>
                                        <h4> {currentAppointment?.gender ?? "n/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Age </p>
                                        <h4> {currentAppointment?.age ?? "n/a"} </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="down-details-border2">
                                <h4>Doctor Details</h4>
                                <div className="common-card">
                                    <div className="common-body">
                                        <p>Doctor Name & Designation </p>
                                        <h4> {currentAppointment?.doctor_profile?.name}, {currentAppointment?.service?.name?.[tKey]} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Study Type </p>
                                        <h4> {appointmentReport?.studyType ?? "n/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Speciality </p>
                                        <h4> {currentAppointment?.serviceName?.[tKey] ?? "n/a"} </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="download-clinic">
                            <h4 className="view-heading">Clinical Data</h4>
                            <p> {appointmentReport?.history ?? "n/a"} </p>
                        </div>
                        <div className="download-clinic">
                            <h4 className="view-heading">Brief Summary</h4>
                            <p> {appointmentReport?.briefSummary ?? "n/a"} </p>
                        </div>
                        <div className="download-clinic">
                            <div className="down-rec">
                                <h4>Recommendation</h4>
                                <p> {appointmentReport?.recommendation ?? "n/a"} </p>
                            </div>
                        </div>
                    </div>

                    <div className="view-patient-deatail">
                        <h4>Other details</h4>
                        <div
                            className="view-doc-detail">
                            <div
                                className="down-details-border2"
                                style={{ width: '50%' }}
                            >
                                <div
                                    className="common-card"
                                    style={{
                                        alignItems: 'flex-start',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div className="common-body">
                                        <p>Specialisation Chosen</p>
                                        <h4 className='text-capitalize'>

                                            {`${(currentAppointment?.service?.service_master?.name?.[tKey]) ?? constants.DOTS}` ?? "n/a"}
                                        </h4>
                                    </div>
                                    <div
                                        className="common-body1"
                                        style={{
                                            marginTop: '1rem',
                                            margin: 'unset'
                                        }}
                                    >
                                        <p>Problem Description</p>
                                        <h4 className='text-capitalize'>
                                            {currentAppointment?.description ?? "n/a"}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="view-attach">
                                <div style={{ marginLeft: "1rem" }} >
                                    <p>Attachments</p>
                                    {/* for iterating non images files */}
                                    {
                                        attachmentsOthers.map(
                                            attachment => {
                                                return (
                                                    <div className="attach-btn">
                                                        <button
                                                            className='pdf-btn'
                                                            onClick={() => handleOpenImage(attachment?.filePath)}
                                                        >
                                                            <img src={file} alt="" className="d-file" />
                                                            {attachment?.fileName}
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        )
                                    }

                                    <ImageList
                                        sx={{
                                            width: 400,
                                            height: 400,
                                        }}
                                        cols={3}
                                        rowHeight={150}
                                    >
                                        {/* for iterating image files */}
                                        {
                                            attachmentsImages.map(
                                                (attachment, index) => {
                                                    return (
                                                        <ImageListItem
                                                            key={index}
                                                            sx={{
                                                                position: 'relative',
                                                                borderRadius: '10px',
                                                                mx: 1,
                                                            }}
                                                            onClick={() => handleOpenImage(attachment?.filePath)}
                                                        >
                                                            <PhotoCameraIcon
                                                                sx={{
                                                                    color: '#2F77B6',
                                                                    position: 'absolute',
                                                                    top: 'calc(50% - 10px)',
                                                                    left: 'calc(50% - 10px)',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                            <img
                                                                src={attachment?.filePath}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    )
                                                }
                                            )
                                        }
                                    </ImageList>
                                </div>

                                <div className="viewdicom">
                                    <DicomModal />
                                    {/* <button className="print-btn"> <img src={print} alt="" className="d-file" />  Print Images </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UserDownloadReport;
