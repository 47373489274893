import React from 'react'
import { useTranslation } from 'react-i18next';
import useGlobal from '../../../hooks/useGlobal';
import useMasters from '../../../hooks/userMasters';
import { dateFormatWW, destructureSlot, formatFromToTimeSlot } from '../../../utils';
import { appointmentStatus, constants } from '../../../utils/constants';

export const AppointmentDoctor = ({
    key,
    appointment,
    handleViewUpcoming
}) => {
    const { t } = useTranslation();
    const {tKey: [tKey]} = useGlobal();

    const { start, end } = destructureSlot(appointment)?.slot;

    return (
        <div className="wrapper-up-card" key={key}>
            <div className="wrapper-up-card-body">
                <div className="upcoming text-uppercase"> {appointmentStatus['Upcoming']} </div>
                <div className="wrapper-card-head">
                    <div className="wrapper-card-content">
                        <p> {t("appointmentID")} </p>
                        <div className="wrapper-para"> {appointment?.appointmentId ?? constants.DOTS}</div>
                    </div>
                    <div className="wrapper-card-content">
                        <p> {t("appointmentTimeOnly")} </p>
                        <div className="wrapper-para">
                            {dateFormatWW(appointment?.slotDate)} {formatFromToTimeSlot(start, end, '-', false) || constants.DOTS}
                        </div>
                    </div>
                    <div className="wrapper-card-content">
                        <p>{t("patientName")}</p>
                        <div className="wrapper-para"> {appointment?.patientName} </div>
                    </div>
                    <div className="wrapper-card-content">
                        <p>  {t("gender")}  </p>
                        <div className="wrapper-para"> {appointment?.gender} </div>
                    </div>
                    <div className="wrapper-card-content">
                        <p> {t("age")}</p>
                        <div className="wrapper-para"> {appointment?.age} </div>
                    </div>
                    <div className="wrapper-card-content">
                        <p>{t("specialtyChosen")}</p>
                        <div className="wrapper-para"> {appointment?.serviceName?.[tKey]} </div>
                    </div>
                </div>
                <div className="view-resh-content">
                    <button
                        type="button"
                        className="view-button"
                        onClick={() => handleViewUpcoming(appointment)}
                    >
                        View Details
                    </button>
                    <button
                        className="resch-button"
                        // onClick={handleOpen} /* as for the now, rescheduling is disabled from doctor side */
                        disabled
                    >
                        Reschedule
                    </button>
                </div>
            </div>
        </div>
    )
}
