import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types'
import { getCaller } from '../services/api';

const genderObj = {
    'male': 'male',
    'female': 'female'
}

const handlers = {
    'INITIALIZE': (state, action) => {
        const {
            users,
            services,
            query_status,
            reviews_range,
            reviews_status,
            master_services,
            appointment_status,
            transaction_types
        } = action.payload;
        return {
            ...state,
            'payment': {},
            'user': users || {},
            'gender': genderObj || {},
            'services': services || [],
            'query': query_status || {},
            'reviews': reviews_status || {},
            'reviewsRange': reviews_range || {},
            'appointment': appointment_status || {},
            'masterServices': master_services || [],
            'transactionTypes': transaction_types || {}
        }
    }
}

const reducer = (state, action) => handlers[action.type] ? handlers[action.type](state, action) : state;

const initialState = {
    'user': {},
    'query': {},
    'gender': {},
    'reviews': {},
    'payment': {},
    'services': [],
    'appointment': {},
    'reviewsRange': {},
    'masterServices': [],
    'transactionTypes': {}
}

const MastersContext = createContext(initialState)

MastersProvider.propTypes = {
    children: PropTypes.node,
}
function MastersProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const _initialize = async () => {
        const response = await getCaller('root/masters');
        dispatch({
            type: 'INITIALIZE',
            payload: response?.data || {}
        })
    }

    // useEffect(() => {
    //     _initialize();
    // }, [])

    return (
        <MastersContext.Provider
            value={{
                _initialize,
                ...state
            }}
        >
            {children}
        </MastersContext.Provider>
    );
}





export { MastersContext, MastersProvider }