import twilio from 'twilio-video';
import React, { useState, useEffect, useRef } from "react";

/* Images */
import c from '../../assets/images/Group -161.png'
import d from '../../assets/images/video_ icon.png'
import e from '../../assets/images/Group 5764.png'
import mutedAudioImg from '../../assets/images/icon_audio.png'
import videoCloseImg from '../../assets/images/video_cut_icon.png'

import '../newVideo/videoChat.css';
import useGlobal from '../../hooks/useGlobal';
import { profiles } from '../../utils/constants';

let screenTrack;

const Part = ({
    participant,
    room,
    name,
    isLocal,
    screenShareRef,

    isRemote
}) => {

    const { userDetails: [userDetails] } = useGlobal();


    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);

    const [audioMute, setAudioMuted] = useState(false);

    const [videoClose, setVideoClose] = useState(false);


    let localMediaRef = React.useRef(null);
    const videoRef = useRef();
    const audioRef = useRef();
    // const screenShareRef = useRef();

    const storedParticipantVideoTrack = participant.videoTracks;


    function moveRemoteToBottom() {
        // document.getElementById('remote-video')?.classList.add('bottom-right')
        screenShareRef.current?.classList.remove('d-none')
    }

    function moveRemoteToCenter() {
        // document.getElementById('remote-video')?.classList.remove('bottom-right')
        screenShareRef.current?.classList.add('d-none')
    }

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    const trackSubscribed = (track) => {
        if (track?.name?.endsWith('share')) {
            moveRemoteToBottom()
            track?.attach(screenShareRef.current)
        }
        if (track.kind === "video") {
            setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
    };

    const trackUnsubscribed = (track) => {
        moveRemoteToCenter()
        /* dispatching name from local side that ends with 'share' to detect that it is a screen share */
        if (track?.name?.endsWith('share')) {
            track?.stop();
        }
        else if (track.kind === "video") {
            setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        }
        else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
    };


    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    const toggleVideo = () => {
        const track = [...participant.videoTracks.values()][0]?.track;
        console.log("🚀 ~ file: Part.jsx:89 ~ toggleVideo ~ track", track)
        if (track) {
            setVideoClose(true)
            track?.stop();
            track?.detach();
            participant.unpublishTrack(track);
        } else {
            setVideoClose(false)
            twilio.createLocalVideoTrack().then(track => {
                track.attach(videoRef.current);
                return participant.publishTrack(track);
            });
        }
    }
    const toggleAudio = () => {
        if (audioTracks[0].isEnabled) {
            audioTracks.forEach(track => {
                setAudioMuted(true)
                track.disable();
            })
        } else {
            audioTracks.forEach(track => {
                setAudioMuted(false)
                track.enable();
            })
        }
    }

    const handleScreenShare = (event, force = false) => {
        if (!screenTrack) {
            console.log('Im here');
            /* accessing medias for screen sharing */
            navigator.mediaDevices.getDisplayMedia().then(stream => {

                screenTrack = new twilio.LocalVideoTrack(
                    stream.getTracks()[0],
                    { name: `${window.crypto.randomUUID()}|share` }
                );

                screenTrack.attach(screenShareRef.current)
                moveRemoteToBottom()
                // screenShareRef?.current?.classList?.remove('d-none')
                // videoRef.current.classList?.add('bottom')
                room.localParticipant.publishTrack(screenTrack);
                screenTrack.mediaStreamTrack.onended = (e) => handleScreenShare(e, true);
            }).catch((error) => {
                console.log("🚀 ~ file: Part.jsx:117 ~ navigator.mediaDevices.getDisplayMedia ~ error", error)
                alert('Could not share the screen.')
            });
        } else {
            if (!force) {
                console.log("🚀 ~ file: Part.jsx:133 ~ handleScreenShare ~ force", force)
                if (
                    !(window.confirm('Would you like to stop the screen share ?'))
                ) { return }
            }
            moveRemoteToCenter()
            room.localParticipant.unpublishTrack(screenTrack);
            // screenShareRef?.current?.classList?.add('d-none')
            // videoRef.current.classList?.remove('bottom-right')
            screenTrack?.stop();
            screenTrack = null;
        }
    }

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        <>
            {/* <div className="screen-share">
            </div> */}
            <div className="participant" id={isRemote && 'remote-video'}>
                <video ref={videoRef} autoPlay={true} />
                <audio ref={audioRef} autoPlay={true} muted={false} />

                {
                    isLocal
                    && (
                        <div className="video-tool-kit">
                            {
                                audioMute ?
                                    <img src={mutedAudioImg} alt="" onClick={toggleAudio} height='70px' width='70px' />
                                    :
                                    <img src={c} alt="" onClick={toggleAudio} height='70px' width='70px' />
                            }
                            {
                                videoClose ?
                                    <img src={videoCloseImg} alt="" onClick={toggleVideo} height='70px' width='70px' />
                                    :
                                    <img src={d} alt="" onClick={toggleVideo} height='70px' width='70px' />
                            }

                            {
                                userDetails?.type === profiles.doctor
                                && <img
                                    src={e}
                                    alt=""
                                    onClick={handleScreenShare}
                                />
                            }
                        </div>
                    )
                }
                <span>{name}</span>
            </div>
        </>
    );
};

export default Part;