import * as Yup from 'yup';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import React, { useEffect, useState } from 'react'
import { BiFile } from 'react-icons/bi';
import Modal from '@mui/material/Modal';
import '../AppointmentRecordView/view.css'
import '../AppointmentRecordView/view.css'
import { MdRefresh } from 'react-icons/md'
import { FaAngleLeft } from 'react-icons/fa'
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { LayoutTwo } from '../../../layout/Layout';
import file from '../../../assets/images/file.png';
import { dateFormatWW, formatTime, showDob } from '../../../utils';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import DicomModal from '../../../components/modal/DicomModal';
import { getCaller, postCaller, updateCaller } from '../../../services/api';
import useMasters from '../../../hooks/userMasters';
import { exists } from 'i18next';
import { appointmentStatus, extensions, toast_messages } from '../../../utils/constants';
import { Avatar, ImageList, ImageListItem } from '@mui/material';
import { styled } from '@mui/system';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import RescheduledModal from '../Modal/RescheduleModal';
import useGlobal from '../../../hooks/useGlobal';
import { formatFromToTimeSlot } from '../../../utils';
import { destructureSlot } from '../../../utils';
import { constants } from '../../../utils/constants';

// import ReshecduleModal from '../../../components/modal/ReshecduleModal';

const UserReviewReport = () => {
    const { appointment } = useMasters();
    const { tKey: [tKey] } = useGlobal();

    

    const { state } = useLocation();
    const navigate = useNavigate();

    const currentAppointment = state;
    const { start, end } = destructureSlot(currentAppointment)?.slot;
    const [attachmentsImages, setAttachmentsImages] = useState([]);
    const [attachmentsOthers, setAttachmentsOthers] = useState([]);
    const [open, setOpen] = React.useState(false);

    const type = localStorage.getItem('type')

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);
    const handleOpenImage = path => window.open(path, '_blank')


    const cancelHandle = async reportData => {
        if (window.confirm('Are you sure you want to cancel ?. Your money would be refunded to you wallet.')) {
            const { appointmentId } = reportData;
            const response = await updateCaller(`updateappointment/${appointmentId}?data=${appointmentStatus['Cancelled']}`)
            if (response?.status === true) {
                toast.success(response?.message);
                setTimeout(() => {
                    return navigate('/dashboard');
                }, 1500); /* using settimeout cuz of toast */
            } else {
                toast.info(response?.message || toast_messages.common.UNKNOWN);
            }
        }
    }

    const backHandle = (e) => {
        e.preventDefault()
        navigate('/dashboard')
        localStorage.setItem('index', "0")
    }

    const _showDate = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return dateFormatWW(slot?.date)
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const _formatTime = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return `${formatTime(slot?.time?.start)} - ${formatTime(slot?.time?.end)}`
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const fetchAttachments = async () => {
        try {
            const response = await getCaller(`getOneFile/${currentAppointment?.appointmentId}`)
            let images = [];
            let others = [];
            Array.isArray(response?.data) && response.data.forEach(
                attachment => {
                    const extension = attachment?.fileName?.split('.').pop();
                    extensions.IMAGES.includes(extension) ? images.push(attachment) : others.push(attachment)
                }
            )
            setAttachmentsImages(images)
            setAttachmentsOthers(others)
        }
        catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        fetchAttachments();
    }, [])

    return (
        <>
            <LayoutTwo>
                <div className="view">
                    <button onClick={backHandle} className="back-btn"> <FaAngleLeft /> Back </button>
                    <div className="view-app-detail">
                        <h3>Appointment Details</h3>
                        <div className='common-card'>
                            <div className='common-body'>
                                <p> Appointment ID </p>
                                <h4>{currentAppointment?.appointmentId ?? "n/a"} </h4>
                            </div>
                            <div className='common-body1'>
                                <p> Appointment Date & Time </p>
                                <h4>
                                {dateFormatWW(currentAppointment?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                                    {/* {dateFormatWW(currentAppointment?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS} */}
                                </h4>
                            </div>
                            <div className='common-body1'>
                                <p> Booking Date </p>
                                <h4>{dateFormatWW(currentAppointment?.createdAt)}</h4>
                            </div>
                            <div className='common-body1'>
                                <p> Appointment Status </p>
                                <h4
                                    style={{ color: "#F28519" }}
                                    className='text-uppercase'
                                >
                                    {
                                        appointment[currentAppointment?.status] && appointment[currentAppointment?.status].UI
                                    }
                                </h4>
                            </div>
                        </div>

                        {/* DOCTOR DETAILS */}
                        <div className="view-patient-deatail">
                            <h3>Doctor Details</h3>
                            <div className='common-card'>
                                <div className='common-body'>
                                    <p>Name</p>
                                    <h4>{currentAppointment?.doctor_profile.name ?? "n/a"} </h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Years of Experience </p>
                                    <h4> {currentAppointment?.doctor_profile?.experience ?? "n/a"} </h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Specialisation </p>
                                    <h4 className='text-capitalize'>
                                        {`${(currentAppointment?.service?.service_master?.name)?.[tKey]}` ?? constants.DOTS ??  "n/a"}
                                    </h4>
                                </div>

                            </div>
                        </div>


                        {/* PATIENTS DETAILS */}
                        <div className="view-patient-deatail">
                            <h3>Patient Details</h3>
                            <div className="view-doc-detail">
                                <div className="doc-content">
                                    <div className='common-card'>
                                        <div className='common-body'>
                                            <p> Patient ID  </p>
                                            <h4>{currentAppointment?.user_profile?.patientId ?? "n/a"} </h4>
                                        </div>
                                        <div className='common-body1'>
                                            <p> Patient Name </p>
                                            <h4> {currentAppointment?.patientName ?? "n/a"} </h4>
                                        </div>
                                        <div className='common-body1'>
                                            <p> Gender </p>
                                            <h4 className='text-capitalize'>{currentAppointment?.gender ?? "n/a"}   </h4>
                                        </div>
                                        <div className='common-body1'>
                                            <p> Age </p>
                                            <h4>  {currentAppointment?.age ?? "n/a"} </h4>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "1rem" }}>
                                        <p>Specialisation Chosen</p>
                                        <span className='text-capitalize'>
                                            {`${(currentAppointment?.service?.service_master?.name)?.[tKey]}` ?? constants.DOTS ??  "n/a"}
                                        </span>
                                    </div>
                                    <div style={{ marginTop: "1rem" }}>
                                        <p>Problem description</p>
                                        <span>
                                            {currentAppointment?.description}
                                        </span>
                                    </div>
                                </div>
                                <div className="view-attach">
                                    <div style={{ marginLeft: "1rem" }} >
                                        <p>Attachments</p>
                                        <div className="viewdicom">
                                            <DicomModal study_path={currentAppointment?.study_path} />
                                            {/* <button className="print-btn" onClick={downloadImage}> <img src={print} alt="" className="d-file" />  Print Files </button> */}
                                        </div>
                                        {/* for iterating non images files */}
                                        {
                                            attachmentsOthers.map(
                                                attachment => {
                                                    return (
                                                        <div className="attach-btn">
                                                            <button
                                                                className='pdf-btn'
                                                                onClick={() => handleOpenImage(attachment?.filePath)}
                                                            >
                                                                <img src={file} alt="" className="d-file" />
                                                                {attachment?.fileName}
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }

                                        <ImageList
                                            sx={{
                                                width: 400,
                                                height: 250,
                                            }}
                                            cols={3}
                                            rowHeight={150}
                                        >
                                            {/* for iterating image files */}
                                            {
                                                attachmentsImages.map(
                                                    (attachment, index) => {
                                                        return (
                                                            <ImageListItem
                                                                key={index}
                                                                sx={{
                                                                    position: 'relative',
                                                                    borderRadius: '10px',
                                                                    mx: 1,
                                                                }}
                                                                onClick={() => handleOpenImage(attachment?.filePath)}
                                                            >
                                                                <PhotoCameraIcon
                                                                    sx={{
                                                                        color: '#2F77B6',
                                                                        position: 'absolute',
                                                                        top: 'calc(50% - 10px)',
                                                                        left: 'calc(50% - 10px)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                />
                                                                <img
                                                                    src={attachment?.filePath}
                                                                    loading="lazy"
                                                                />
                                                            </ImageListItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </ImageList>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cancel-btn">
                            <button className="c-btn" onClick={() => cancelHandle(currentAppointment)} > <i className="fa-regular fa-circle-xmark"></i> Cancel Appointment</button>
                            <div className="">
                                {type === 'doctor' ? (<div>
                                    <button onClick={() => handleOpen()} className='r-btn-1' > <MdRefresh style={{ fontSize: "1.5rem", marginRight: "0.5rem" }} />  Reschedule </button>
                                </div>) : (<div>
                                    <button onClick={() => handleOpen()} className='r-btn' > <MdRefresh style={{ fontSize: "1.5rem", marginRight: "0.5rem" }} />  Reschedule </button>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>

                <RescheduledModal
                    open={open}
                    handleClose={handleClose}
                    currentAppointment={currentAppointment}
                />
            </LayoutTwo>
        </>
    )
}

export default UserReviewReport