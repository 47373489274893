import './index.css';
import App from './App';
import './utils/locales/i18n'
import ReactDOM from 'react-dom/client';
import './components/dicomViewer/DicomScreen'
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query'
import GlobalState from './context/GlobalState';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MastersProvider } from './context/MasterContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
root.render(
    <GlobalState>
        <MastersProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </LocalizationProvider>
        </MastersProvider>
    </GlobalState>
);