import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { showDob, formatTime, generateQuery } from "../../../utils";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import s from "../../../assets/images/search.png";
import close from "../../../assets/images/cross.png";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import { getCaller, updateCaller } from "../../../services/api";
import CustomPagination from "../../../components/CustomPagination";
import WetReportModal from "./Modals/WetReport";
import { appointmentStatus, toast_messages } from "../../../utils/constants";
import DoctorAppointmentTable from "./DoctorAppointmentTable";
import { toast } from "react-toastify";


const DoctorReviewAppointment = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [dataOne, setDataOne] = useState();
    const [reportModal, setReportModal] = useState(false);
    const [appointmentId, setAppointmentId] = useState(null);


    const [appointments, setAppointments] = useState([]);

    const openWetReportModal = () => setReportModal(true)
    const closeWetReportModal = () => setReportModal(false)

    const handleUpcoming = async (id) => {
        setAppointmentId(id);
        openWetReportModal();
        // const resp = await updateCaller(`updateappointment/${id}?data=upcoming`);

        // if (resp.data[0] > 0) {
        //   alert("status changes upcoming");
        // } else {
        //   alert("status already upcoming");
        // }
        // console.log(resp.data[0], "resp");
    };

    const fetchReviewAppointments = async payload => {
        try {
            let URL = `getAllAppointment?status=${appointmentStatus['inReview']}`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setAppointments(response?.data || {})
        } catch (e) {
            setAppointments({})
        }
    }

    const handleViewAppointment = (currentAppointment) => {
        navigate(`/doctorReviewReport`, {
            state: currentAppointment
        })
    }

    const handleAccept = (currentAppointment) => {
        if (currentAppointment?.appointmentId) {
            setAppointmentId(currentAppointment?.appointmentId);
            openWetReportModal();
        } else {
            toast.info(toast_messages.common.UNKNOWN)
        }
    }

    const handleReject = async (currentAppointment) => {
        if (window.confirm('Are you sure you want to reject this appointment ?')) {
            try {
                const response = await updateCaller(`updateAppointment/${currentAppointment?.appointmentId}?data=${appointmentStatus['reject']}`)
                if (response?.status === true) {
                    toast.success(response?.message)
                    fetchReviewAppointments({})
                } else {
                    return toast.info(response?.message || toast_messages.common.UNKNOWN)
                }
            } catch (err) {
                console.error(err);
                toast.info(toast_messages.common.UNKNOWN)
            }
        }
    }

    useEffect(() => {
        fetchReviewAppointments();
    }, [])

    return (
        <>
            <div className="tabel-tab">
                <DoctorAppointmentTable
                    type={appointmentStatus['inReview']}
                    appointments={appointments}
                    refreshData={fetchReviewAppointments}
                    handleViewReview={handleViewAppointment}
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                />
            </div>

            <WetReportModal
                appointmentId={appointmentId}
                close={closeWetReportModal}
                open={reportModal}
                refreshData={fetchReviewAppointments}
            />
        </>
    );
};

export default DoctorReviewAppointment;