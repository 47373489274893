import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import trick from '../../../assets/images/Group 5571.png';
import { useTranslation } from 'react-i18next'
import './bookappointment.css'
import './cardp.css'
import Layout from '../../../layout/Layout';
import { getCaller, getCallerById, postCaller } from '../../../services/api';
import jsPDF from "jspdf";
import "jspdf-autotable";
import useGlobal from '../../../hooks/useGlobal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Skeleton, Stack, styled, useTheme } from '@mui/material';

const CompletePayment = (props) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); /*Removing header and footer, because in Android */


    const navigate = useNavigate();
    const { tKey: [tKey] } = useGlobal();

    const { state, search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const [appointment, setAppointment] = useState(null);
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    let query = {}
    urlParams.forEach((val, key) => {
        query[key] = val
    })

    // if (state?.data) {
    //     for (const key in state?.data) {
    //         query[key] = state?.data[key]
    //     }
    // } else {
    //     urlParams.forEach((val, key) => { query[key] = val })
    // }

    if (localStorage.getItem('PaymentType' === 'Card')) {
        localStorage.setItem("TrackId", query.TrackId);
    }
    // let bookDetails = state?.data;

    const handleBook = () => {
        navigate('/dashboard')
        localStorage.setItem('index', "0")
    }
    const handleRetry = () => {
        navigate('/paymentFill');
    }
    const getAppointmentDetails = async () => {
        // const urlParams = new URLSearchParams(window.location.search);
        // let query = {}
        // //const query = window.location.search;
        // urlParams.forEach((val,key)=>{query[key]=val})
        // console.log(query);
        // debugger;

        // const response = await getCaller(`getOneAppointment/${localStorage.getItem("TrackId")}`);


        const response = await getCaller(`public/appointment-details/${query.TrackId}`);
        setAppointment(response.data);
        setDate(response?.data?.slot[0]?.date);
        setTime(response?.data?.slot[0]?.time);
    }
    const sendPaymentDetails = async () => {
        // const jsonBody = { query }
        const jsonBody = {
            AuthCode: query.AuthCode,
            ECI: query.ECI,
            PaymentId: query.PaymentId,
            RRN: query.RRN,
            ResponseCode: query.ResponseCode,
            Result: query.Result,
            SubscriptionId: query.SubscriptionId,
            TrackId: query.TrackId,
            TranId: query.TranId,
            UserField1: query.UserField1,
            UserField3: query.UserField3,
            UserField4: query.UserField4,
            UserField5: query.UserField5,
            amount: query.amount,
            cardBrand: query.cardBrand,
            cardToken: query.cardToken,
            email: query.email,
            maskedPAN: query.maskedPAN,
            payFor: query.payFor,
            responseHash: query.responseHash
        }
        const response = await postCaller(`pg/response`, jsonBody);
    }

    const fetchInSync = async () => {
        /* have to send payment details before fetching appointment details */
        await sendPaymentDetails();

        /* have to wait till payment response is satisfied  */
        await getAppointmentDetails();
    }

    useEffect(() => {
        fetchInSync()
        // if (localStorage.getItem('PaymentType' === 'Card')) {
        // }
        // localStorage.removeItem('PaymentType');
        // localStorage.removeItem('walletPaymentStatus');
        // localStorage.removeItem('TrackId');
    }, [])

    const { t } = useTranslation()
    const cData = [
        { cpara: `${t('appointmentID')}` },
        { cpara: `${t('appointmentTime')}` },
        // { cpara: `${t('doctorName')}` },
        { cpara: `${t('Doctor Name')}` },
        { cpara: `${t('specialtyChosen')}` },
        { cpara: `${t('payment')}` },
        { cpara: `${t('Transaction Id')}` }
    ]
    const c1Data = [
        { c1para: `${appointment?.appointmentId ?? null}` },
        { c1para: `${date}, ${time?.start} - ${time?.end}` },
        { c1para: `${appointment?.doctor_profile?.name ?? null}` },
        { c1para: `${appointment?.service?.service_master?.name?.[tKey]}, ${appointment?.service?.name?.[tKey]}` },
        { c1para: `SAR ${appointment?.transaction?.amount ?? null}` },
        { c1para: `${appointment?.transaction?.transactionId ?? null}` }
        // { c1para: `${query.TranId ?? "N/A"}` }
    ]
    // const walletPaymentStatus = localStorage.getItem('walletPaymentStatus');

    const generatePDF = async () => {
        const doc = new jsPDF();
        const tableColumn = ["Appointment ID", "Date", "Appointment Time", "Doctor Name", "Specialty Chosen", "Payment", "Status", "Transaction Id"];
        const tableRows = [];
        // appointment.forEach(data => {
        const pdfData = [
            appointment.appointmentId,
            date,
            `${time?.start} - ${time?.end}`,
            appointment.doctor_profile.name,
            `${appointment?.service?.service_master?.name?.[tKey]}, ${appointment?.service?.name?.[tKey]}`,
            `SAR ${appointment?.transaction?.amount ?? ""}`,
            query.Result,
            `${appointment?.transaction?.transactionId ?? "N/A"}`,
        ];
        tableRows.push(pdfData);
        // });

        // startY is basically margin-top
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        const pdfDocDate = Date().split(" ");
        // we use a date string to generate our filename.
        const dateStr = pdfDocDate[0] + pdfDocDate[1] + pdfDocDate[2] + pdfDocDate[3] + pdfDocDate[4];
        // ticket title. and margin-top + margin-left
        doc.text("Your Payment Receipt", 14, 15);
        // we define the name of our PDF file.
        doc.save(`paymentreport_${dateStr}.pdf`);
    }

    return (
        <>
            <Layout restrictHeaderFooter={isMobile}>
                <div className="second-step">
                    <div className="complete-step">
                        {query.Result === 'completed' || query.Result === 'completed' || query.Result === 'Successful'
                            // || walletPaymentStatus === 'Success' 
                            ?
                            <div className="complete-step-content">
                                <img src={trick} alt="" />
                                <p>Your payment was successful.<br /> Below are your appointments details.</p>
                            </div>
                            :
                            <div>
                                <div className="complete-step-content" style={{ height: "50vh" }}>
                                    <i class="fa-solid fa-xmark"></i>
                                    <div style={{ display: "block" }}></div>
                                    <p>Payment Status: {query.Result}</p>
                                    <button className='resh-cancel' onClick={handleRetry}> Retry Payment</button>
                                </div>
                                {/* <i class="fa-solid fa-xmark"></i>
                                <p>Payment Status: {query.Result}</p>
                                <button className='resh-cancel' onClick={handleRetry}> Retry Payment </button> */}
                            </div>
                        }
                    </div>
                    {query.Result === 'completed' || query.Result === 'completed' || query.Result === 'Successful'
                        // || walletPaymentStatus === 'Success' 
                        ?
                        <div className='complete-apt-container'>
                            <p className='your-sum'>Appointment Details</p>
                            <div className="complete-apt">
                                <div className="complete-apt-para">
                                    {
                                        cData.map((e, i) => {
                                            return (
                                                <p key={`payment-heading${i}`}
                                                    className='text-capitalize'> {e.cpara} </p>
                                            )
                                        })
                                    }
                                </div>
                                <div className="complete-apt-para1">
                                    {
                                        c1Data.map((e, i) => {
                                            return (
                                                <p
                                                    key={`payment-description-${i}`}
                                                    className='text-capitalize'>
                                                    {
                                                        appointment
                                                            ? e.c1para
                                                            : <Skeleton variant="text" sx={{ fontSize: '1rem', width: '15rem' }} />
                                                    }
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> : <></>}

                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item md={6} xs={12}>
                            <ResponsiveButton onClick={handleBook}> Go To My Appointments </ResponsiveButton>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ResponsiveButton onClick={generatePDF}> Print Receipt </ResponsiveButton>
                        </Grid>
                    </Grid>
                    {/* { query.Result !== "Success" || query.Result !=="Successful" || walletPaymentStatus === 'Success' n?
                        <button className='resh-cancel' onClick={handleRetry}> Retry Payment </button>:<></>} */}
                </div>
            </Layout>
        </>
    )
}

/* 
.resh-cancel {
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: white;
    color: var(--steel-blue);
    font-size: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    justify-content: center;
    cursor: pointer;
}
 */

const ResponsiveButton = styled('button')(
    ({ theme }) => ({
        width: '90%',
        border: ' 1px solid var(--steel-blue)',
        color: 'var(--steel-blue)',
        backgroundColor: theme.palette.background.default,
        fontSize: '1rem',
        padding: '1rem 1rem',
        cursor: 'pointer',
        margin: '0 1rem',
        borderRadius: '3rem',

        [theme.breakpoints.down('sm')]: {
            padding: '.5rem',
            fontSize: '100%',
        }

    })
)
export default CompletePayment;