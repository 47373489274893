import { Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import * as Yup from "yup";
import { useFormik } from "formik";
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCaller, postCaller, updateCaller } from "../../../../services/api";
import { checkAvailability } from "../../../../utils";
import { toast_messages } from "../../../../utils/constants";
import { toast } from "react-toastify";
import { ThemeButton, UnstyledButton } from "../../../../components/common/Button";
import { styled } from "@mui/material/styles";




const wetSchema = Yup.object().shape({
    studyType: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is Required"),
    bodyPart: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Body Part is Required"),
    history: Yup.string()
        .min(10, "Too Short!")
        .max(300, "Too Long!")
        .required("History is Required"),
    briefSummary: Yup.string()
        .min(10, "Too Short!")
        .max(300, "Too Long!")
        .required("BriefSummary is Required"),
    recommendation: Yup.string()
        .min(10, "Too Short!")
        .max(300, "Too Long!")
        .required("Recommendation is Required"),
});


const WetReportModal = props => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState();

    const inputRef = useRef(null);

    const open = props.open;
    const handleClose = props.close;
    const appointmentId = props.appointmentId;
    const _already = props.fetchPrevious;


    const formik = useFormik({
        initialValues: {
            studyType: '',
            bodyPart: '',
            history: '',
            briefSummary: '',
            recommendation: '',
            docs: new File(["foo"], "foo.txt")
        },
        validationSchema: wetSchema,
        onSubmit: async (values) => {
            try {
                setLoader(true)
                if (_already) {
                    return haveToUpdate(values)
                }

                const formData = new FormData();

                formData.append('studyType', values.studyType);
                formData.append('bodyPart', values.bodyPart);
                formData.append('history', values.history);
                formData.append('briefSummary', values.briefSummary);
                formData.append('recommendation', values.recommendation);
                formData.append('appointmentId', appointmentId);
                formData.append('docs', values.docs)


                const response = await postCaller('createWetReport', formData, true);
                if (response?.status === true) {
                    navigate('/dashboard/doctor/appointments', { replace: true });
                    handleCloseAndReset()
                    toast.success(response?.msg)
                    props?.refreshData && props.refreshData({})
                } else {
                    toast.info(response?.msg || toast_messages.common.UNKNOWN)
                }
                setLoader(false)
            } catch (err) {
                setLoader(false)
                toast.info(toast_messages.common.UNKNOWN)
            }
        },
    });

    const haveToUpdate = async values => {

        // const formData = wetFile;
        const formData = new FormData();
        formData.append('history', values.history);
        formData.append('bodyPart', values.bodyPart);
        formData.append('studyType', values.studyType);
        formData.append('appointmentId', appointmentId);
        formData.append('wet_ReportId', values.wet_ReportId);
        formData.append('briefSummary', values.briefSummary);
        formData.append('recommendation', values.recommendation);

        const response = await postCaller('updateWetReport', formData, true);
        if (response) {
            await getCaller(`completeAppointment?id=${appointmentId}`);
            navigate('/dashboard/doctor/appointments', { state: { videoEnded: true }, replace: true });
            /* Handling toast at dashboard cause toast.success is not working here */
        }
    }

    const fetchPreviousReport = async () => {
        const res = await getCaller(`getOneFile/${appointmentId}`)
        delete res.data
        formik.setValues(values => ({
            ...values,
            wet_ReportId: res.result?.wet_ReportId,
            studyType: res.result?.studyType ?? "",
            bodyPart: res.result?.bodyPart ?? "",
            history: res.result?.history ?? "",
            briefSummary: res.result?.briefSummary ?? "",
            recommendation: res.result?.recommendation ?? "",
        }))
    }


    const handleAttachment = ({ target }) => {
        const [file] = target?.files || [null];
        if (!file) return;
        if (!checkAvailability(file.type)) {
            formik.setFieldError('docs', 'only images and PDF')
            target.value = null;
            return;
        }
        formik.setFieldValue('docs', file)
    }

    const handleCloseAndReset = () => {
        formik.resetForm()
        handleClose()
    }

    useEffect(() => {
        _already && fetchPreviousReport()
    }, [appointmentId])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <StyledBox>
                    <div className="scroll">
                        <div className="rescPopup">
                            <h4> Wet Report </h4>
                        </div>
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                padding: "1rem",
                                overflow: 'scroll',
                                overflowX: 'hidden',
                                height: '100%'
                            }}
                        >
                            <div className="wet-input-content">
                                {/* Study Type */}
                                <div className='wet-form-content' >
                                    <label htmlFor=""> Study Type </label> <br />
                                    <input
                                        type="text"
                                        placeholder='Enter'
                                        name='studyType'
                                        className="wet-input"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.studyType}
                                    />
                                    <div className="error">
                                        {
                                            formik.touched.studyType
                                            && formik.errors.studyType
                                            && formik.errors.studyType
                                        }
                                    </div>
                                </div>

                                {/* Body Part */}
                                <div className='wet-form-content'>
                                    <label htmlFor=""> Body Part </label> <br />
                                    <input type="text" placeholder='Enter' name='bodyPart' className="wet-input"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.bodyPart}
                                    />
                                    <div className="error">
                                        {
                                            formik.touched.bodyPart
                                            && formik.errors.bodyPart
                                            && formik.errors.bodyPart
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Clinic Data */}
                            <div className='reshText'>
                                <label htmlFor="Comment" > Clinical Data </label> <br />
                                <textarea
                                    name="history"
                                    placeholder='Describe reason'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.history}
                                ></textarea>
                                <div className="error">
                                    {
                                        formik.touched.history
                                        && formik.errors.history
                                        && formik.errors.history
                                    }
                                </div>
                            </div>

                            {/* Brief Summary */}
                            <div className='reshText'>
                                <label htmlFor="Comment" > Brief Summary</label> <br />
                                <textarea
                                    name="briefSummary"
                                    placeholder='Describe reason'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.briefSummary}
                                ></textarea>
                                <div className="error">
                                    {
                                        formik.touched.briefSummary
                                        && formik.errors.briefSummary
                                        && formik.errors.briefSummary
                                    }
                                </div>
                            </div>

                            {/* Recommendation */}
                            <div className='reshText'>
                                <label htmlFor="Comment"> Recommendation </label> <br />
                                <textarea
                                    name="recommendation"
                                    placeholder='Describe reason'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.recommendation}
                                ></textarea>
                                <div className="error">
                                    {
                                        formik.touched.recommendation
                                        && formik.errors.recommendation
                                        && formik.errors.recommendation
                                    }
                                </div>
                            </div>

                            <div className="">
                                <label htmlFor="upload"> Attached File </label>
                                <div className="wet-file">
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        name="docs"
                                        onChange={handleAttachment}
                                    />
                                </div>
                                <div className="error">
                                    {
                                        formik.touched.docs
                                        && formik.errors.docs
                                        && formik.errors.docs
                                    }
                                </div>
                            </div>

                            <div className="reshbtn-content">
                                <div className="report-cancel-btn">
                                    <UnstyledButton
                                        className='theme-button'
                                        text='Cancel'
                                        onClick={handleClose}
                                    />
                                </div>
                                <div className="report-submit-btn">
                                    <ThemeButton
                                        loading={loader}
                                        className='theme-button'
                                        text='Submit'
                                        type='submit'
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </StyledBox>
            </Fade >
        </Modal >
    )
}


const StyledBox = styled(Box)(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: "100%",
        maxHeight: 600,
        overflow: "hidden",
        borderRadius: "1rem",
        bgcolor: 'white',
        display: "block",
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    })
)


export default WetReportModal;