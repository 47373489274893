import React, { useState } from 'react';


import '../components/custom.css'
const CustomPagination = ({ page, setPage, maximo }) => {
    const [input, setInput] = useState(1);

    const nextPage = () => {
        setInput(parseInt(input) + 1);
        setPage(parseInt(page) + 1);
    };

    const previousPage = () => {
        setInput(parseInt(input) - 1);
        setPage(parseInt(page) - 1);
    };

    const onKeyDown = e => {
        if (e.keyCode == 13) {
            setPage(parseInt(e.target.value));
            if (
                parseInt(e.target.value < 1) ||
                parseInt(e.target.value) > Math.ceil(maximo) ||
                isNaN(parseInt(e.target.value))
            ) {
                setPage(1);
                setInput(1);
            } else {
                setPage(parseInt(e.target.value));
            }
        }
    };

    const onChange = e => {
        setInput(e.target.value);
    };

    return (
        <div className='custom-page'>
            <div className="">
                {/* {maximo} */}
            </div>
            <div className='page-container' >
                <button to="#" disabled={page === 1 || page < 1} onClick={previousPage}>
                    Prev
                </button>
                <div className="custom-input" >
                    {input}
                </div>
                {/* <input
                    onChange={e => onChange(e)}
                    onKeyDown={e => onKeyDown(e)}
                    name="page"
                    autoComplete="off"
                    value={input}
                    className="custom-input"
                /> */}
                {/* <p> de {maximo} </p> */}
                <button to="#"
                    disabled={page === Math.ceil(maximo) || page > Math.ceil(maximo)}
                    onClick={nextPage}
                >
                    Next
                </button>
            </div>
        </div>
    );
};
export default CustomPagination