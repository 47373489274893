import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { s3constant } from './../../utils/constants';

// import CornerstoneElement from '../dicomViewer/CornerStone'
import { useNavigate } from 'react-router-dom';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function DicomModal(props) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleClickOpen = () => {
        setOpen(true);
        navigate('/dicomscreen')
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenNew = () => {
        setOpen(true);
        navigate('/dicomscreennew', { state: { studypath: props.study_path } })
    };

    // const imageId =
    //     "https://rawgit.com/cornerstonejs/cornerstoneWebImageLoader/master/examples/Renal_Cell_Carcinoma.jpg";

    // const stack = {
    //     imageIds: [imageId],
    //     currentImageIdIndex: 0
    // };

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <div>
            {/* <button onClick={handleClickOpen}> View Images on Dicom Viewer <i className="fa-solid fa-arrow-right "></i> </button> */}
            {/* { props.study_path&&            <button onClick={handleClickOpenNew}> Click to open Dicom Study <i className="fa-solid fa-arrow-right "></i> </button>
            
           } */}
            {
                props.study_path
                && <button onClick={() => openInNewTab('http://43.205.162.163:8000/viewer/dicomjson?url=' + s3constant.S3URL + 'ohif/' + props.study_path + '/study.json')}> Click to open Dicom Study
                </button>
            }
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Dicom Viewer
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {/* <CornerstoneElement stack={stack} /> */}
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
