import * as React from 'react';
import { styled } from '@mui/material/styles';
import { theme } from "../utilities/colors.js";
import { LoadingButton } from '@mui/lab';



const { button: { background, on_hover_background, on_hover_text } } = theme;

export const ThemeButton = ({ text, onClick, loading, disabled, type, className, sx }) => {
    return (
        <CustomStyledThemeButton
            variant="contained"
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            type={type}
            className={className}
            sx={sx}
        >
            {text}
        </CustomStyledThemeButton>
    );
}

export const UnstyledButton = ({ text, onClick, loading, disabled, type, className, sx }) => {
    return (
        <UnstyledThemeButton
            variant="contained"
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            type={type}
            className={className}
            sx={sx}
        >
            {text}
        </UnstyledThemeButton>
    );
}

const CustomStyledThemeButton = styled(LoadingButton)({
    height: '100%',
    width: '100%',
    color: '#eee',
    borderRadius: 'inherit',
    backgroundColor: background,
    fontFamily: "inherit",
    fontSize: "inherit",
    // '&:hover': {
    //     backgroundColor: on_hover_background,
    //     color: on_hover_text,
    // },
    textTransform: "inherit",
});

const UnstyledThemeButton = styled(LoadingButton)({
    height: '100%',
    width: '100%',
    color: "inherit",
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
    fontFamily: "inherit",
    fontSize: "inherit",
    '&:disabled': {
        opacity: '.5'
    },
    '&:hover': {
        // backgroundColor: '',
        // color: on_hover_text,
    },
    textTransform: "inherit",
});