import React from 'react';


import './cards.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    dateFormatWW,
    destructureSlot,
    formatFromToTimeSlot
} from '../../../utils';
import { appointmentStatus, constants } from '../../../utils/constants';
import useGlobal from '../../../hooks/useGlobal';

/* moving this function out of main component to make it run only once */
const DecideClass = (type) => {
    switch (type) {
        case appointmentStatus['Upcoming']:
            return `user-upcoming-btn text-uppercase`

        case appointmentStatus['inReview']:
            return `user-inreview-btn text-uppercase`

        default:
            return `user-upcoming-btn`
    }
}

const Appointment = ({
    key,
    type,
    appointment,
    handleAttend,
    handleViewDetails,
    handleCancelAppointment,
}) => {
    const { t } = useTranslation();
    const { tKey: [tKey] } = useGlobal();

    const { start, end } = destructureSlot(appointment)?.slot;


    return (
        <div key={key} className="user-up-content">
            <div className="user-up-body">
                <div className={DecideClass(type)}>
                    {appointment?.status ?? constants.DOTS}
                </div>
                <div className="user-app-id">
                    <div className="user-app-body">
                        <p> {t("appointmentID")} </p>
                        <h4> {appointment?.appointmentId ?? constants.DOTS}</h4>
                    </div>
                    <div className="user-app-body">
                        <p> {t("appointmentTime")} </p>
                        <h4>
                            {dateFormatWW(appointment?.slotDate)} {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                        </h4>
                    </div>
                    <div className="user-app-body">
                        <p> {t("doctorName")} </p>
                        <h4 className='text-capitalize'> {appointment?.doctor_profile?.name ?? constants.DOTS} </h4>
                    </div>
                    <div className="user-app-body">
                        <p> {t("specialtyChosen")} </p>
                        <h4
                            className='text-capitalize'
                        >
                            {appointment?.service?.service_master?.name?.[tKey] ?? constants.DOTS}
                        </h4>
                    </div>
                    <div className="user-app-body">
                        <p> {t("patientName")} </p>
                        <h4
                            className='text-capitalize'
                        > {appointment?.patientName ?? constants.DOTS} </h4>
                    </div>
                    <div className="user-gender">
                        <div>
                            <p> {t("gender")} </p>
                            <h4
                                className='text-capitalize'
                            > {appointment?.gender ?? constants.DOTS} </h4>
                        </div>
                        <div className="user-age">
                            <p> {t("age")}</p>
                            <h4> {appointment?.age ?? constants.DOTS} </h4>
                        </div>
                    </div>
                </div>
                <div className="user-up-btn">
                    <div className="user-attend">
                        <div
                            to="#"
                            style={{ color: "#2F77B6", cursor: "pointer" }}
                            onClick={
                                type === appointmentStatus['Upcoming']
                                    ? () => handleAttend(appointment)
                                    : () => handleViewDetails(appointment)
                            }
                        >
                            {
                                type === appointmentStatus['Upcoming']
                                    ? t("attend")
                                    : t("viewDetails")
                            }
                        </div>
                    </div>
                    <div className="user-cancel"></div>
                    <div className="user-attend">
                        <div
                            style={{ color: "#C4C1C1" }}
                            className={appointmentStatus['Upcoming'] ? 'disabled-only' : ''}
                            onClick={
                                type === appointmentStatus['inReview']
                                    ? () => handleCancelAppointment(appointment?.appointmentId)
                                    : () => { }
                            }
                        >
                            {t("cancel")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Appointment