import React, { useEffect, useState } from 'react';
import './review.css';
import { useTranslation } from 'react-i18next';
import { getCaller } from '../../../services/api';
import CustomPagination from '../../../components/CustomPagination';
import { dateFormat, dateFormatWW, generateQuery } from '../../../utils';
import { constants } from '../../../utils/constants';
import { DateRangePicker } from 'rsuite';


const Review = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');

    const [calenderValue, setCalenderValue] = useState([null, null])

    const [records, setRecords] = useState({});

    const maximo = records?.count / 5;

    const fetchReviews = async (payload = {}) => {
        try {
            let URL = `getallReview?limit=4`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setRecords(response || {})
        } catch (error) {
            console.log("🚀 ~ file: Review.jsx:33 ~ fetchReviews ~ error", error)
        }
    }

    const nextData = page => {
        const payload = {
            ...query,
            page
        }
        fetchReviews(payload)
    }

    const handleStarRangeChange = ({ target: { value } }) => {
        if (value == '-1') {
            setQuery(prev => ({ ...prev, page: 1, rating: null }))
            fetchReviews({ page: 1, rating: null })
            return;
        }

        setQuery({
            page: 1,
            rating: value
        })
        fetchReviews({
            page: 1,
            rating: value
        })
    }


    useEffect(() => {
        if (calenderValue[0] === null) {
            setQuery(prev => ({
                ...prev,
                dateFrom: null,
                dateTo: null
            }))
            fetchReviews({
                ...query,
                page: 1,
                dateFrom: null,
                dateTo: null
            })
            return () => { }
        }

        const [from, to] = calenderValue;
        const _data = {
            ...query,
            page: 1,
            dateFrom: from && dateFormat(from),
            dateTo: to && dateFormat(to)
        }
        setQuery(_data)
        fetchReviews(_data)

    }, [calenderValue])



    useEffect(() => {
        nextData(page)
    }, [page])

    useEffect(() => {
        fetchReviews()
    }, [])

    return (
        <div className="side-right-content">
            <div className="side-right-head">
                <h2> Reviews <span> {records?.count} Items </span> </h2>
            </div>
            <div className="review">
                <div className="review-btn-container">
                    <span>Rating</span>
                    <select onChange={handleStarRangeChange}>
                        <option value="-1"> --Select-- </option>
                        <option value="1"> 1 Star</option>
                        <option value="2"> 2 Star </option>
                        <option value="3"> 3 Star </option>
                        <option value="4"> 4 Star </option>
                        <option value="6"> 5 Star </option>
                    </select>
                </div>
                <div className=''>
                    <DateRangePicker
                        ranges={[]}
                        className="width-full text-capitalize"
                        // showOneCalendar // to show only one calender
                        value={calenderValue}
                        onChange={newValue => setCalenderValue(newValue || [null, null])}
                        name="valid"
                        format="dd-MM-yyyy"
                        placeholder="Select Date Range"
                        placement='auto'
                    />
                </div>
            </div>
            <div className="reviewcard">
                {
                    Array.isArray(records?.data)
                        && records.data?.length > 0
                        ? records.data.map(
                            el => {
                                return (
                                    <div className="reviewcard-content">
                                        <div className="review-rating">
                                            <div className="review-list">
                                                <p>{t('rating')} </p>
                                                <h4>{el?.rating ?? constants.N_A} </h4>
                                            </div>
                                            <div className="review-list">
                                                <p> {t('reviewBy')} </p>
                                                <h4> {el?.user_profile?.name ?? constants.N_A} </h4>
                                            </div>
                                            <div className="review-list">
                                                <p> {t('dateGiven')} </p>
                                                <h4> {dateFormatWW(el?.createdAt) ?? constants.N_A} </h4>
                                            </div>
                                        </div>
                                        <div className='review-text'> <h5> Review Text </h5>
                                            <p>{el?.message ?? 'N/a'}</p>
                                        </div>
                                    </div>
                                )
                            }
                        )
                        : <div style={{ width: '100%', textAlign: 'center' }}> <h3>Opps, No reviews found</h3> </div>
                }
            </div>
            <CustomPagination page={page} setPage={setPage} maximo={maximo} />
        </div>
    )
}

export default Review