import './modal.css';
import { useState } from 'react';
import { useFormik } from 'formik';
import { DateRangePicker } from 'rsuite';
import { dateFormat } from '../../../utils';
import { FaRegTimesCircle } from 'react-icons/fa';
import { Backdrop, Box, Fade, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import useMasters from '../../../hooks/userMasters';
import useGlobal from '../../../hooks/useGlobal';
import { constants } from '../../../utils/constants';

const MODAL_INPUT_SIZE = 'small';
const MedicalRecordModal = ({
    open,
    handleClose,
    setQuery,
    refreshData
}) => {
    const { tKey: [tKey] } = useGlobal();

    const {
        services,
        gender
    } = useMasters();

    const formikIntialValues = {
        appointmentId: '',
        doctorName: '',
        patientName: '',
        gender: '',
        serviceId: '',
    }

    const formik = useFormik({
        initialValues: formikIntialValues,
        onSubmit: data => {
            const [from, to] = calenderValue;
            const _data = {
                ...data,
                page: 1,
                dateAddedFrom: from && dateFormat(from),
                dateAddedTo: to && dateFormat(to)
            }
            setQuery(_data)
            refreshData(_data)
            handleClose()
        }
    });


    const [calenderValue, setCalenderValue] = useState([null, null])

    const handleResetFormAndCloseForm = () => {
        formik.resetForm();
        setCalenderValue([null, null])
        refreshData({ page: 1 })
        handleClose()
    }


    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="query-pop-up">
                                <h4> Filter Appointments </h4>
                                <span onClick={handleClose}>
                                    <FaRegTimesCircle
                                        style={{
                                            width: "2rem",
                                            height: "1.5rem"
                                        }}
                                    />
                                </span>
                            </div>

                            {/* Content */}
                            <Typography id="transition-modal-description" className='query-para' >
                                <div className='query-body'>

                                    <div className="query-details">
                                        <p>Date Added</p>
                                        <DateRangePicker
                                            ranges={[]}
                                            className="width-full text-capitalize"
                                            showOneCalendar // to show only one calender
                                            value={calenderValue}
                                            onChange={newValue => setCalenderValue(newValue || [null, null])}
                                            name="valid"
                                            format="dd-MM-yyyy"
                                            placeholder="Select Date Range"
                                            placement='auto'
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Reported For</p>
                                        <Select
                                            className="width-full text-capitalize"
                                            size={MODAL_INPUT_SIZE}
                                            name='serviceId'
                                            onChange={formik.handleChange}
                                            value={formik.values.serviceId}
                                        >
                                            <MenuItem
                                                className='text-capitalize'
                                                key={null}
                                                value={null}
                                            >
                                                ---select---
                                            </MenuItem>
                                            {
                                                services.length > 0
                                                    ? services.map(key => {
                                                        return (
                                                            <MenuItem
                                                                className='text-capitalize'
                                                                key={key?.serviceId}
                                                                value={key?.serviceId}
                                                            >

                                                                {/* {key?.service_master?.name} - {key.UI} */}
                                                                {key?.service_master?.name?.[tKey] ?? constants.DOTS} - {key.UI}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : <NoItemFound />
                                            }
                                        </Select>
                                    </div>


                                    <div className="query-details">
                                        <p>Appointment ID</p>
                                        <TextField
                                            type={'text'}
                                            value={formik.values.appointmentId}
                                            className="width-full"
                                            size='small'
                                            name='appointmentId'
                                            onChange={formik.handleChange}
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Patient Name</p>
                                        <TextField
                                            type={'text'}
                                            className="width-full"
                                            size='small'
                                            name='patientName'
                                            onChange={formik.handleChange}
                                            value={formik.values.patientName}
                                        />
                                    </div>

                                    <div className="query-details">
                                        <p>Patient Name</p>
                                        <TextField
                                            type={'text'}
                                            className="width-full"
                                            size='small'
                                            name='doctorName'
                                            onChange={formik.handleChange}
                                            value={formik.values.doctorName}
                                        />
                                    </div>

                                </div>
                            </Typography>

                            {/*  */}
                            <div className="query-btn-content">
                                <div className="query-mark-btn">
                                    <button
                                        className='mark-query text-white'
                                        style={{
                                            backgroundColor: 'var(--steel-blue)',
                                            color: 'white'
                                        }}
                                        type='submit'
                                    >
                                        Filter
                                    </button>

                                    <div
                                        className='mark-query text-white'
                                        onClick={handleResetFormAndCloseForm}
                                        disabled
                                    >
                                        Reset
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

function NoItemFound() {
    return <MenuItem>No Items</MenuItem>
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height:450,
    borderRadius: "1rem",
    bgcolor: 'white',

    // boxShadow: 24,
    // paddingTop:"1rem"
};


export default MedicalRecordModal