import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import './userwallet.css'
import '../userappointment/userappointment.css'
import { getCaller } from '../../../services/api';
import s from '../../../assets/images/search.png'
import close from '../../../assets/images/cross.png'
import { useTranslation } from 'react-i18next';
import CustomPagination from '../../../components/CustomPagination';
import { showDob, formatTime, destructureSlot, dateFormatWW, formatFromToTimeSlot, generateQuery } from "../../../utils";
import { Tooltip } from '@mui/material';
import { constants } from '../../../utils/constants';
import WalletFilterModal from './WalletFilterModal';

const UserWallet = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();


    const searchTextFieldRef = useRef(null);

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [modal, setModal] = useState(false);

    const [walletBalance, setWalletBalance] = useState(0);
    const [records, setRecords] = useState({});

    const maximo = records?.count / 5;


    const handleModalClose = () => setModal(false);
    const handleModalOpen = () => setModal(true);


    const handleDownloadFile = record => {
        window.open(record?.filePath, '_blank')
    }

    const fetchWalletRecords = async payload => {
        try {
            let URL = `profile/wallet?`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setRecords(response?.data || {})
        } catch (e) {
            setRecords({})
        }
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        const payload = {
            ...query,
            searchText: value,
            page: 1
        }
        setQuery(payload)
        fetchWalletRecords(payload)
    }


    const fetchWalletBalance = async () => {
        try {
            const response = await getCaller('showwalletBalance');
            if (response?.walletBalance) {
                setWalletBalance(response?.walletBalance)
            }
        }
        catch (err) {
            console.error(err.message);
        }
    }


    const handleViewAppointment = (currentAppointment) => {
        return navigate(`/userDownloadReport/${currentAppointment?.appointmentId}`)
    }

    const nextData = page => {
        const payload = {
            ...query,
            page
        }
        fetchWalletRecords(payload)
    }

    useEffect(() => {
        nextData(page)
    }, [page])


    useEffect(() => {
        fetchWalletRecords({})
        fetchWalletBalance()
    }, [])


    const sliceText = (text) => {
        if (!text || text.length < 50) return text;
        return `${text.slice(0, 50)}...`
    }

    return (
        <>
            <WalletFilterModal
                open={modal}
                handleClose={handleModalClose}
                refreshData={fetchWalletRecords}
                setQuery={setQuery}
            />
            <div className='user-wallet-container'>
                <div className="user-wallet">
                    <div className="user-wallet-content">
                        <div className="user-wallet-content1">
                            <p>{t('walletBalance')}</p>
                            <h4> SAR {walletBalance}.00 <small> As on {dateFormatWW()} </small> </h4>
                        </div>
                        {/* <button className='resch-button' onClick={() => navigate('/addfunds')}>{t('addFunds')}</button> */}
                    </div>
                </div>
                <div className="user-past-content">
                    <div className="filter-field">
                        <p> {records?.count} items </p>
                        <div className="filter-search">
                            <div className="user-past-card">
                                <input
                                    type="input"
                                    id="search-box"
                                    placeholder="Search "
                                    ref={searchTextFieldRef}
                                    onKeyUp={event => event.key === 'Enter' && handleTextFieldButton()}
                                />
                                <label
                                    htmlFor="search-box"
                                    className="fas fa-search"
                                    onClick={handleTextFieldButton}
                                ></label>

                            </div>
                            <div className="filter-button">
                                <button onClick={handleModalOpen}> Filter </button>
                            </div>
                        </div>
                    </div>
                    <div className="user-table-past">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell> Appointment ID </StyledTableCell>
                                        <StyledTableCell> Description </StyledTableCell>
                                        <StyledTableCell> Date </StyledTableCell>
                                        <StyledTableCell> Type </StyledTableCell>
                                        <StyledTableCell style={{ textAlign: "right" }}> Amount(SAR) </StyledTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {
                                        records?.rows?.map((item, index) => {
                                            const {
                                                amount,
                                                type,
                                                createdAt
                                            } = item?.transaction || {};

                                            return (
                                                <StyledTableRow key={`wallet_listing_${index}`}>
                                                    <StyledTableCell component="th" scope="row"> {item?.appointmentId ?? constants.DOTS} </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Tooltip title={item?.description || ''}>
                                                            <>
                                                                {sliceText(item?.description)}
                                                            </>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {dateFormatWW(createdAt) ?? constants.DOTS}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {type ?? constants.DOTS} </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "right" }} > {amount ?? constants.DOTS} </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <CustomPagination
                        page={page} setPage={setPage} maximo={maximo}
                    />
                </div>
            </div>
        </>
    )
}


const StyledTableRow = styled(TableRow)(
    ({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    })
);

const StyledTableCell = styled(TableCell)(
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#D5F1FF",
            color: "#707070",
            border: 0,
            fontSize: 13,
            fontWeight: 600,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            border: 0,
            fontWeight: 600,
            color: "#1A1919",
            textTransform: "capitalize",
            letterSpacing: 'unset',
        },
    }
);


export default UserWallet