import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from "firebase/messaging";

let _token = 'BObSQrrWh4VzQfUroHeAIRfGXD784Y9LNcHsMahJUBCrdkMRBKKaK49dvosX7JFCZTs3wziU6WxLv3BJ0uVNVA8'
console.log(">>>>>>>>>")

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBiWsDdZjcDfldYzRaELQXD1HfA3fsCrxw",
    authDomain: "var-s-doctor-app.firebaseapp.com",
    projectId: "var-s-doctor-app",
    storageBucket: "var-s-doctor-app.appspot.com",
    messagingSenderId: "389039206377",
    appId: "1:389039206377:web:030812aeafb3e8dbce6578",
    measurementId: "G-YEK69KD8YS"
};
// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive


const app = initializeApp(firebaseConfig);


//export const requestForToken = async (currentToken) => {
//    const messaging = getMessaging(app);
//
//      return new Promise((resolve,reject) =>
//        getToken(messaging, { vapidKey: _token }).then((currentToken) => {
//
//      if (currentToken) {
//        resolve(currentToken)
//      } else {
//        console.log('No registration token available. Request permission to generate one.');
//      }
//    })
//
//       )};


export const requestForToken = async () => {
    const messaging = getMessaging(app);

    let currentToken = "";
    try {
        currentToken = await getToken(messaging, {vapidKey: _token});
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }

    console.log("🚀 ~ file: firebase.js ~ line 42 ~ const_getToken= ~ currentToken", currentToken)
    return currentToken;
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
// export const onMessageListener = async () =>
// new Promise((resolve) =>
//     (async () => {
//         const messagingResolve = await messaging;
//         onMessage(messagingResolve, (payload) => {
//             // console.log('On message: ', messaging, payload);
//             resolve(payload);
//         });
//     })()
// );