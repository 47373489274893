import { Backdrop, Box, Fade, Modal, Rating, Stack, styled } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { postCaller } from '../../../../services/api'
import { responseMessages } from '../../../../utils/responseMessages'
import { ThemeButton, UnstyledButton } from '../../../common/Button'


const initialValues = {
    rating: 0,
    message: ''
}

const EndCallUser = ({
    open,
    endCall,
    handleClose,
    isForceClosed,
    appointmentData,
}) => {
    const navigate = useNavigate();


    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                values.appointmentId = appointmentData?.appointmentId
                values.doctorId = appointmentData?.doctorId
                const response = await postCaller('createReview', values)
                if (response?.error === false) {
                    toast.success(response?.msg)
                    endCall()
                    navigate('/dashboard')
                } else {
                    toast.info(response?.msg || responseMessages.UNKNOWN_ERROR)
                }
            } catch (err) {
                console.error("🚀 ~ file: EndCall.jsx:40 ~ onSubmit: ~ err", err)
            }
            finally {
                setSubmitting(false)
            }
        }
    });


    const handlePatientEndCall = () => {
        // if (isForceClosed) {
        // }
        handleClose()
        endCall()
        // navigate(`/userUpcomingReport/${appointmentData?.appointmentId}`, { replace: true })
        navigate(`/dashboard`, { replace: true })
    }


    const { handleChange, values, handleSubmit, setFieldValue, isSubmitting } = formik;
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <StyledBox>
                        <div className="">
                            <div className="rescPopup">
                                <h4> End call </h4>
                            </div>
                            <form onSubmit={handleSubmit} >
                                <div className="rating-content">
                                    <p>Ratings</p>
                                    <div className='rating-star'>
                                        <Stack spacing={1}>
                                            <Rating
                                                type='number'
                                                name='rating'
                                                precision={0.5}
                                                defaultValue={2.5}
                                                value={values.rating}
                                                onChange={({ target: { value } }) => setFieldValue('rating', parseFloat(value))}
                                            />
                                        </Stack>
                                    </div>

                                    <div className='rating-feed'>
                                        <label htmlFor=""> Leave your message about the appointment </label> <br />
                                        <textarea
                                            name="message"
                                            value={values.message}
                                            onChange={handleChange}
                                        >
                                        </textarea>
                                    </div>

                                    <div className="reshbtn-content">
                                        {
                                            <div className="report-cancel-btn">
                                                <UnstyledButton
                                                    className='theme-button'
                                                    text='Cancel'
                                                    onClick={handlePatientEndCall}
                                                />
                                            </div>
                                        }
                                        <div className="report-submit-btn">
                                            <ThemeButton
                                                loading={isSubmitting}
                                                className='theme-button'
                                                text='Submit'
                                                type='submit'
                                            />
                                        </div>
                                        {/* <button className='resh-cancel' onClick={handleClose}> Cancel </button>
                                        <button type="submit" className='resh-submit'> Submit </button> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </StyledBox>
                </Fade>
            </Modal>
        </div>
    )
}

const StyledBox = styled(Box)(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: "100%",
        maxHeight: 600,
        overflow: "hidden",
        borderRadius: "1rem",
        bgcolor: 'white',
        display: "block",
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    })
)

export default EndCallUser