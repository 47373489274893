
import '../Dashboard/dashboard.css'
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import useGlobal from '../../hooks/useGlobal';
import { useTranslation } from 'react-i18next';
import { LayoutTwo } from '../../layout/Layout';
import { profiles } from '../../utils/constants';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import GenerateNavLink from '../../components/common/GenerateNavlink';



/* utility functions */
const setActiveInactiveClass = ({ isActive }) => { return isActive ? 'tablink active-side' : 'tablink' }


/* -------------------MAIN COMPONENT----------------------*/
const Dashboard = () => {
    const context = useGlobal()
    const path = useLocation();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [, setUserProfile] = context.userProfile;
    const [userDetails, setUserDetails] = context?.userDetails;


    const logOutClick = () => {
        if (window.confirm('Are You Sure You Want To Logout?')) {
            setUserProfile(null);
            setUserDetails(null)
            localStorage.clear();
            navigate('/login');
        };
    }

    const { t } = useTranslation();

    const UserNavlinks = [
        {
            path: '',
            activeClassHandlers: () => path?.pathname === '/dashboard' ? 'tablink active-side' : 'tablink',
            name: t('appointment')
        },
        {
            path: 'user/medical',
            activeClassHandlers: setActiveInactiveClass,
            name: 'MDEICAL RECORD'
        },
        {
            path: 'user/account',
            activeClassHandlers: setActiveInactiveClass,
            name: 'ACCOUNT'
        },
        {
            path: 'user/wallet',
            activeClassHandlers: setActiveInactiveClass,
            name: 'WALLET'
        }
    ]

    const DoctorNavLinks = [
        {
            path: '',
            activeClassHandlers: () => path?.pathname === '/dashboard' ? 'tablink active-side' : 'tablink',
            name: 'ACCOUNT'
        },
        {
            path: 'doctor/appointments',
            activeClassHandlers: setActiveInactiveClass,
            name: t('appointment')
        },
        {
            path: 'doctor/reviews',
            activeClassHandlers: setActiveInactiveClass,
            name: t('review')
        },

    ]


    const DecideLinks = ({ type }) => {
        switch (type) {
            case profiles.user:
                return (
                    <>
                        <ul>
                            {
                                UserNavlinks.map(link => {
                                    return (
                                        <GenerateNavLink
                                            activeClassHandlers={link.activeClassHandlers}
                                            name={link.name}
                                            to={link.path}
                                        />
                                    )
                                })
                            }
                            <li
                                className={'tablink'}
                                onClick={logOutClick}
                            >
                                LOGOUT
                            </li>
                        </ul>
                    </>
                )

            case profiles.doctor:
                return (
                    <>
                        <ul>
                            {
                                DoctorNavLinks.map(link => {
                                    return (
                                        <GenerateNavLink
                                            activeClassHandlers={link.activeClassHandlers}
                                            name={link.name}
                                            to={link.path}
                                        />
                                    )
                                })
                            }
                            <li
                                className={'tablink'}
                                onClick={logOutClick}
                            >
                                LOGOUT
                            </li>
                        </ul>
                    </>
                )


            default:
                return <>
                    <ul>
                        <li
                            className={'tablink'}
                            onClick={logOutClick}
                        >
                            LOGOUT
                        </li>
                    </ul>
                    Please refresh Or login again
                </>

        }
    }

    return (
        <>
            <LayoutTwo>
                <div className="side-back">
                    <Breadcrumb head="Doctor" head1="Account" />
                    <div className='side-container'>

                        <div className="side-left">
                            <DecideLinks type={userDetails?.type} />
                            {/* 
                            {type === 'user' ? <ul>
                                <li className={`tablink ${index === 0 ? 'active-side' : null}`} onClick={() => { setIndex(0) }}> {t('appointment')}   </li>
                                <li className={`tablink ${index === 1 ? 'active-side' : null}`} onClick={() => { setIndex(1) }}> MEDICAL RECORDS  </li>
                                <li className={`tablink ${index === 2 ? 'active-side' : null}`} onClick={() => { setIndex(2) }}> ACCOUNT </li>
                                <li className={`tablink ${index === 3 ? 'active-side' : null}`} onClick={() => { setIndex(3) }}> WALLET </li>
                                <li className={`tablink`}
                                    onClick={logOutClick}
                                > LOGOUT </li>
                            </ul>
                                : <ul>
                                    <li className={`tablink ${index === 0 ? 'active-side' : null}`} onClick={() => { setIndex(0) }} >  ACCOUNT  </li>
                                    <li className={`tablink ${index === 1 ? 'active-side' : null}`} onClick={() => { setIndex(1) }}> {t('appointment')}  </li>
                                    <li className={`tablink ${index === 2 ? 'active-side' : null}`} onClick={() => { setIndex(2) }}> {t('review')} </li>
                                    <li className={`tablink ${index === 3 ? 'active-side' : null}`} onClick={
                                        () => { localStorage.clear(); navigate('/login') }}> {t('logout')} </li>
                                </ul>} */}
                        </div>
                        <div className="side-right">
                            <Outlet />
                            {/* <DecideRendering /> */}
                            {/* {type === 'user' ? (index === 0 ? <UserAppointment /> :
                                index === 1 ? <UserMedicalRecord /> :
                                    // index === 2 ? <UserAccount /> : <UserWallet />
                                    <UserAccount />
                            )
                                :
                                (index === 0 ? < DoctorProfileAccount /> :
                                    index === 1 ? <AllAppointment /> : <Review />)} */}
                        </div>
                    </div>
                </div>
            </LayoutTwo>
        </>
    )
}

export default Dashboard





    // const DecideRendering = () => {
    //     switch (type) {
    //         case profiles.user:
    //             if (index === 0) {
    //                 return <UserAppointment />
    //             } else if (index === 1) {
    //                 return <UserMedicalRecord />
    //             } else if (index === 2) {
    //                 return <UserAccount />
    //             } else if (index === 3) {
    //                 return <UserWallet />
    //             }

    //         case profiles.doctor:
    //             if (index === 0) {
    //                 return <DoctorProfileAccount />
    //             } else if (index === 1) {
    //                 return <AllAppointment />
    //             } else {
    //                 return <Review />
    //             }

    //         default:
    //             break;
    //     }
    // }
