import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import next from '../../assets/images/Path -34.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Breadcrumb(props) {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="#3A9CD8" style={{ cursor: "pointer" }} onClick={() => navigate('/')}>
      {t('home')}
    </Link>,

    <Typography key="3" color="#3A9CD8" style={{ cursor: "pointer" }}>
      {props.head}
    </Typography>,
    //   <Typography key="3" color="#3A9CD8" style={{cursor:"pointer"}}>
    //   {props.head1}
    // </Typography>
    <Typography key="3" color="#3A9CD8" style={{ cursor: "pointer" }}>
      {props.head2}
    </Typography>,
  ];

  return (
    <Stack spacing={2} style={{ paddingLeft: "2rem", paddingRight: "4rem", paddingTop: '1rem' }}>

      <Breadcrumbs
        separator={<img src={next} alt="" style={{ width: "0.438rem", height: "0.813rem" }} />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
