import * as React from "react";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import { useTranslation } from "react-i18next";
import { generateImageURL } from "../../../utils";
import { getHomepageActiveCats } from "../Calls";
import { Avatar, Card, CardContent, CardMedia, styled, Typography } from "@mui/material";
import useGlobal from "../../../hooks/useGlobal";
import { useNavigate } from "react-router-dom";
import { minHeight, minWidth } from "@mui/system";


export default function SliderCard(props) {
    const { t } = useTranslation();
    const { tKey: [tKey] } = useGlobal();
    const navigate = useNavigate();


    const slider = React.useRef();

    const {
        Title,
        SubTitle,
        masterId,
        Categories
    } = props;

    const [cats, setCats] = React.useState([]);


    const settings = {
        // dots: true,
        className: "center",
        // infinite: true,
        centerPadding: "60px",
        slidesToShow: Categories.length < 6 ? Categories.length : 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        touchThreshold: 500,
        swipeToSlide: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    className: "center",
                    // infinite: true,
                    // dots: true,
                    // slidesToShow: 6,
                    // slidesToScroll: cats?.length / 6,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    className: "center",
                    // slidesToShow: 2,
                    // slidesToScroll: cats?.length / 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    className: "center",
                    // slidesToShow: 1,
                    // slidesToScroll: cats?.length / 1,
                }
            }
        ]
    };

    const StyledCard = styled(Card)(
        {
            minHeight: "251px",
            fontSize: "15px !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }
    )

    const getHomePageCats = async () => {
        let response = await getHomepageActiveCats(masterId);
        setCats(response);
    }

    const handleCategoryRedirect = (cat) => {
        console.log("______________cat_________________", cat)
        navigate(
            '/searchdoctor',
            {
                state: {
                    fromCategory: true,
                    serviceId: cat?.serviceId,
                    catName: cat?.sub_services.en
                }
            }
        )
    }

    function scroll(e) {
        if (slider === null)
            return 0;

        e?.wheelDelta > 0 ? (
            slider.current?.slickNext()
        ) : (
            slider.current?.slickPrev()
        );
    };

    React.useEffect(() => {
        getHomePageCats();

        window.addEventListener("wheel", scroll, true);

        return () => {
            window.removeEventListener("wheel", scroll, true);
        };
    }, []);

    return (
        <>
            <div className="sliderr">
                <div className="slidercontent">
                    {/* <h2>{t('consultTopDoctorsOnline')}</h2> */}
                    <h2>
                        {Title[tKey]}
                    </h2>
                    <p>
                        {SubTitle[tKey]}
                    </p>
                </div>

                <Slider {...settings}>
                    {
                        Categories && Categories.map(
                            (cat, i) => (

                                <StyledCard
                                    key={`slider-categories-${i}`}
                                    sx={{ maxWidth: 200 }}
                                    onClick={() => handleCategoryRedirect(cat)}
                                >
                                    <Avatar
                                        height="140"
                                    sx={{
                                        height: 140,
                                        width: 140,
                                        textAlign: "center",
                                        justify : "center",
                                        margin: "0 auto"
                                    }}
                                    src={generateImageURL(cat.image)}
                                    variant='square'
                                    />
                                    {/* <CardMedia
                                        component="img"
                                        alt="green iguana"
                                    /> */}
                                    <CardContent>
                                        <Typography gutterBottom
                                            variant="h6"
                                            component="div"
                                            fontSize={"15px !important"}
                                        >
                                            {cat.sub_services?.[tKey]}
                                        </Typography>
                                    </CardContent>
                                </StyledCard>

                            )
                        )
                    }
                </Slider>
            </div>
        </>
    );
}

