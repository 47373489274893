// const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const API_BASE_URL = 'http://develop-var-api.staqo.com';
// const API_BASE_URL = 'http://192.168.43.252:6001';

export const registerUser = async (payload) => {
    const url = `${API_BASE_URL}/registerUser`;
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*"
        }, body: JSON.stringify(payload)
    });
    return res.json();
}

export const loginUser = async (payload) => {
    const url = `${API_BASE_URL}/loginUser`;
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            "content-type": 'application/json',
            // "Access-Control-Allow-Origin": "*"
        }, body: JSON.stringify(payload)
    });
    return res.json();
}

export const getCaller = async (url) => {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(response => response.json()).catch(error => console.log(error))
    return response
}

export const getCallerById = async (url, id) => {
    const response = await fetch(`${API_BASE_URL}/${url}/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(response => response.json()).catch(error => console.log(error))
    console.log(response)
    return response
}


export const postCaller = async (url, data, form) => {
    let applicationHeader = {};
    if (!form) {
        applicationHeader = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(`${API_BASE_URL}/${url}`, {
        method: 'POST',
        headers: {
            ...applicationHeader,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: (!form) ? JSON.stringify(data) : data
    }).then(response => response.json()).catch(error => console.log(error))
    return response
}

export const deleteCaller = async (url, id) => {
    const response = await fetch(`${API_BASE_URL}/${url}/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(response => response.json()).catch(error => console.log(error))
    return response
}

export const updateCaller = async (url, data, formData = false) => {
    let headers = {};
    /* If user does not want to send with form data */
    if (!formData) {
        headers = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(`${API_BASE_URL}/${url}`, {
        method: 'PUT',
        headers: {
            ...headers,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: !formData ? JSON.stringify(data) : data

    }).then(response => response.json()).catch(error => console.log(error))
    return response
}


export const postQuery = async (data) => {
    const response = await fetch(`${API_BASE_URL}/createQuery`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    }).then(response => response.json()).catch(error => console.log(error));
    return response;
}