import * as Yup from "yup";
import { useRef } from "react";
import "../pages/loginregister.css";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import OtpInput from "react-otp-input";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Navbar from "../layout/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import two from "../assets/images/register.png";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { getCaller, registerUser, updateCaller } from "../services/api";
import { injectStyle } from "react-toastify/dist/inject-style";
import TimerButton from "./TimerButton";
import { ThemeButton, UnstyledButton } from "../components/common/Button";
import { toast_messages } from "../utils/constants";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";

/* ICONS */
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #2f77b6",
    boxShadow: 24,
    padding: "0.5rem 1rem 1rem 1rem",
    borderRadius: "0.25rem",
};

const RegisterSchema = Yup.object().shape({
    mobile: Yup.string()
        .min(9, "Incorrect mobile number")
        .required("Mobile number is required"),
    password: Yup.string()
        .min(6, "Password is too small")
        .max(20, "Password is too large")
        .required("Password is required"),
});

const formikIntialValues = {
    mobile: "",
    password: "",
    type: "user",
    countryCode: "+91"
}


const Register = () => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const [code, setCode] = useState("");
    const ref = useRef(null);
    const [userRes, setUserRes] = useState();

    const passwordRef = useRef(null);

    const [eyeVisibility, setEyeVisibility] = useState(false); /* for handling eye button */

    const [initialTImer, setInitialTimer] = useState(0)
    const [loadingV, setLoadingV] = useState(false) /* loadingV -> loader for verify otp button */
    const [loaderS, setLoaderS] = useState(false) /* loaderS -> loader for submit button */


    const navigate = useNavigate();


    const formik = useFormik({
        initialValues: formikIntialValues,
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            try {
                setLoaderS(true)
                let res = await registerUser(values);
                setUserRes(res);

                if (res.error === false) {
                    setLoaderS(false) /* stopping loading as soon response is fetched */
                    /* open only when otp proceass is found */
                    setOpen(true);
                    const { doProceed } = res.data;
                    if (doProceed.error === true) {
                        setInitialTimer(doProceed.data?.timer)
                    } else {
                        setInitialTimer(doProceed.data?.timer)
                        toast.info(doProceed?.msg)
                    }
                } else {
                    toast.info(res.msg)
                }
                setLoaderS(false)
            } catch (e) {
                setLoaderS(false)
            }
        },
    });

    const { errors, handleChange, values, setFieldValue, touched, handleBlur, setFieldTouched, resetForm, setValues } = formik;

    const toggleEyeIcon = () => {
        setEyeVisibility(!eyeVisibility);
        passwordRef.current.type = eyeVisibility ? 'password' : 'text';
    }

    const handleClose = () => {
        // setLoaderS(false);
        setLoadingV(false)
        setOpen(false);
        setInitialTimer(0)
        ref.current.value = ''
        setCode("")
    };

    const handleOtpChange = (code) => {
        setCode(code);
        ref.current.value = "";
    };


    const handleOtpVerify = async () => {
        try {
            setLoadingV(true);
            const payload = {
                id: userRes?.data?.loginId,
                otp: code,
                password: formik.values.password
            }
            console.log("🚀 ~ file: Register.jsx:129 ~ handleOtpVerify ~ userRes", userRes)
            console.log("🚀 ~ file: Register.jsx:129 ~ handleOtpVerify ~ code", code)

            const response = await updateCaller('verify', payload)

            if (response?.error === false) {
                setLoadingV(false)
                toast.success(response?.msg)
                navigate('/login')
                setOpen(false)
            } else {
                setLoadingV(false)
                // setOpen(false)
                setCode("")
                toast.info(response?.msg)
            }
        } catch (e) {
            console.error("🚀 ~ file: Register.jsx:144 ~ handleOtpVerify ~ e", e)
            setLoadingV(false);
            toast.error(toast_messages.common.UNKNOWN)
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            navigate("/dashboard");
        }
    }, []);

    useEffect(() => {
        setValues(prev => ({ ...prev, mobile: '', password: '', }))
    }, [values.type])

    return (
        <>
            <Navbar />
            <div className="register">
                <div className="register-container">
                    <div className="register-image-contain">
                        <img src={two} alt="" />
                    </div>
                    <div className="register-border"></div>
                    <div className="register-form">
                        <h2>{t("register")}</h2>
                        <form action="" className="form-c" onSubmit={formik.handleSubmit}>
                            <div style={{width: '70%'}}>
                                <div className="radio-field">
                                    <input
                                        type="radio"
                                        name="type"
                                        id="userLabel"
                                        value={"user"}
                                        checked={formik.values.type === "user"}
                                        onChange={formik.getFieldProps("type").onChange}
                                    />
                                    <label htmlFor="userLabel" className="p-label">{t("pateint")}</label>
                                    <input
                                        type="radio"
                                        name="type"
                                        id='doctorLabel'
                                        value={"doctor"}
                                        checked={formik.values.type === "doctor"}
                                        onChange={formik.getFieldProps("type").onChange}
                                    />
                                    <label htmlFor="doctorLabel" className="p-label">{t("doctor")}</label>
                                </div>
                                <div className="register-input-field">
                                    <TextField
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        size="medium"
                                        placeholder={t('enterMobile')}
                                        name="mobile"
                                        value={formik.values.mobile}
                                        onBlur={handleBlur}
                                        onChange={formik.handleChange}

                                        error={Boolean(touched.mobile && errors.mobile)}
                                        helperText={touched.mobile && errors.mobile}

                                        InputProps={{
                                            sx: { pl: 0 },
                                            startAdornment: (
                                                <InputAdornment>
                                                    <Select
                                                        sx={{ mr: 1, pl: 0 }}
                                                        name='countryCode'
                                                        // error={Boolean(formik.errors?.countryCode)}
                                                        size='medium'
                                                        variant='outlined'
                                                        style={{ width: '100%' }}
                                                        value={formik.values.countryCode}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <MenuItem value="+91">IN - +91</MenuItem>
                                                        <MenuItem value="+966">AR - +966</MenuItem>
                                                    </Select>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                    <div className='register-input-field'>
                                        <TextField
                                            style={{ width: "100%" }}
                                            size="medium"
                                            type="password"
                                            name="password"
                                            className=''
                                            placeholder={t('enterPassword')}
                                            onBlur={handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            inputRef={passwordRef}

                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}

                                            InputProps={{
                                                endAdornment: (
                                                    <div className='' onClick={toggleEyeIcon}>
                                                        {eyeVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <div style={{
                                        borderRadius: '1.5rem',
                                        width: '70%',
                                        height: '3rem'
                                    }}>
                                        <ThemeButton
                                            loading={loaderS}
                                            className='theme-button'
                                            text={t("submit")}
                                            type='submit'
                                        />
                                    </div>

                                    {/* <button className="submit-btn" type="submit">
                                        {" "}
                                        { } <i className="fa-solid fa-chevron-right"></i>{" "}
                                    </button> */}
                                </div>
                            </div>
                            <div>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={open}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={open}>
                                        <Box sx={style}>
                                            <div className="otp-head-para">
                                                <h3>OTP VERIFICATION</h3>
                                                <p>
                                                    {" "}
                                                    <AiOutlineCloseCircle
                                                        fontSize="1.5rem"
                                                        cursor="pointer"
                                                        onClick={handleClose}
                                                    />{" "}
                                                </p>
                                            </div>
                                            <OtpInput
                                                ref={ref}
                                                value={code}
                                                onChange={handleOtpChange}
                                                numInputs={6}
                                                separator={<span style={{ width: "8px" }}></span>}
                                                isInputNum={true}
                                                errorMessage={"Invalid OTP"} // pass error message if applicable
                                                inputTextErrorColor={"black"}
                                                errorMessageTextStyles={{ color: "red" }}
                                                shouldAutoFocus={true}
                                                inputStyle={{
                                                    border: "1px solid black",
                                                    borderRadius: "8px",
                                                    width: "54px",
                                                    height: "54px",
                                                    fontSize: "12px",
                                                    color: "#000",
                                                    fontWeight: "400",
                                                    caretColor: "blue",
                                                    marginTop: "2rem",
                                                }}
                                                focusStyle={{
                                                    border: "1px solid #CFD3DB",
                                                    outline: "none",
                                                }}
                                            />
                                            {code.errorMessage}
                                            <div className="resend-btn-container">
                                                <UnstyledButton
                                                    className="verify-btn"
                                                    onClick={handleOtpVerify}
                                                    disabled={code?.length !== 6}
                                                    loading={loadingV}
                                                    text={!loadingV && 'Verify OTP'}
                                                />
                                                <TimerButton
                                                    initialTimer={initialTImer}
                                                    tempUser={userRes}
                                                />
                                            </div>
                                        </Box>
                                    </Fade>
                                </Modal>
                            </div>
                        </form>
                        <div className="register-para">
                            <p>
                                {t("alreadyMember")} <Link to="/login">{t("login")} </Link>{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
