
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Notifications from '../../components/userNotifications/Notifications'
import '../Dashboard/dashboard.css'
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { LayoutTwo } from '../../layout/Layout'
const UserNotifications = () => {
  const navigate = useNavigate();
  const type = localStorage.getItem('type')

  const [index, setIndex] = useState(0);

  useEffect(() => {
    let tabIndex = localStorage.getItem('index')
    setIndex(parseInt(tabIndex))
  }, [])
   
  const logOutClick=() => {
     if (window.confirm('Are You Sure You Want To Logout?')) {
       localStorage.clear();
       navigate('/login');
     };
    }

  const { t } = useTranslation()

  return (
    <>
      <LayoutTwo>
        <div className="side-back">
          <Breadcrumb head="Notifications" head1="Account" />
          <div className='side-container'>

           
            <div className="side-right">
              {type === 'user' ?  <Notifications /> :
                <Notifications /> }
            </div>
          </div>
        </div>
      </LayoutTwo>
    </>
  )
}

export default UserNotifications