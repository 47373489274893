import Layout from "../Layout";
import { useEffect } from 'react';
import Banner from '../home/banner/Banner'
import { Outlet } from "react-router-dom";
import { getCaller } from "../../services/api";
import SliderCard from "../home/slider/SliderCard";
import ForDoctor from "../home/fordoctor/ForDoctor";
import { useState } from "react";
import Chatpeople from "../home/chatview/Chatpeople";
import Testimonial from "../home/Testimonial/Testimonial";
import OnlineConsult from "../home/onlineconsult/OnlineConsult";
import { getHomepageActiveCats } from "./Calls";


const dummyData = {
    'section-1': {},
    'section-2': {},
    'section-3': {},
    'section-4': {},
    'section-5': {}
}

function Home() {
    const [state, setState] = useState(dummyData);
    const [masterId, setMasterId] = useState(1);
    const [categories, setCategories] = useState([])


    const getHomepage = async () => {
        const response = await getCaller(`getWebsiteContent?page_type=HOMEPAGE`);
        setState(response?.data?.content || dummyData)
    }

    const changeSlider = async (masterId) => {
        try {
            const response = await getHomepageActiveCats(masterId)
            setCategories(response)
        } catch (err) {
            console.error(err);
        }
    }




    useEffect(() => {
        console.log("🚀 ~ file: Home.jsx:44 ~ Home ~ masterId", masterId)
    }, [masterId])


    useEffect(() => {
        try {
            getHomepage();
            changeSlider(1)
        } catch {
            setState(dummyData)
        }
    }, []);
    return (
        <div>
            <Outlet />
            {
                <>
                    <Layout>
                        <Banner
                            Title={state['section-1']?.title || ""}
                            SubTitle={state['section-1']?.subtitle || ""}
                            BackgroundImage={state['section-1']?.backgroundImage || ""}
                            Images={state['section-1']?.images || []}
                            footerData={() => state['section-7']}
                            changeSlider={changeSlider}
                        />
                        <SliderCard
                            Title={state['section-2']?.title || ""}
                            SubTitle={state['section-2']?.subtitle || ""}
                            Categories={categories}
                            masterId={masterId}
                        />
                        <Chatpeople
                            Title={state['section-3']?.title || ""}
                            SubTitle={state['section-3']?.subtitle || ""}
                            Images={state['section-3']?.images || []}
                        />
                        <OnlineConsult
                            Title={state['section-4']?.title || ""}
                            SubTitle={state['section-4']?.subtitle || ""}
                            Images={state['section-4']?.images || []}
                        />
                        <ForDoctor
                            Title={state['section-5']?.title || ""}
                            SubTitle={state['section-5']?.subtitle || ""}
                            BackgroundImage={state['section-5']?.backgroundImage || ""}
                            Images={state['section-5']?.images || []}
                        />
                        <Testimonial
                            Monials={state?.['section-6']}
                        />
                    </Layout>
                </>
            }
        </div>
    );
}

export default Home;
