import "rsuite/dist/rsuite.css";

/* Packges */
import i18next from 'i18next';
import Helmet from 'react-helmet'
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useLayoutEffect, useState } from 'react';


/* Router */
import ProtectedRoute from './routes/ProtectedRoute';


/* Pages */
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import AddFund from './pages/UserDashboard/addFund/AddFund';
import Notifications from './pages/UserNotifications/UserNotifications';
import PaymentFill from './pages/UserDashboard/bookappointment/PaymentFill';
import FillDetails from './pages/UserDashboard/bookappointment/FillDetails';
import UserProfileEdit from './pages/UserDashboard/userprofile/UserProfileEdit';
import BookAppointment from './pages/UserDashboard/bookappointment/BookAppointment';
import CompletePayment from './pages/UserDashboard/bookappointment/CompletePayment';
import UserReviewReport from './pages/common/AppointmentRecordView/UserReviewReport';
import DownloadViewReport from './pages/common/AppointmentRecordView/DownloadViewReport'
import UserViewDetails from './pages/common/AppointmentRecordView/UserUpcomingViewReport';
import UserDownloadReport from './pages/common/AppointmentRecordView/UserDownloadReport';
import EditDoctorProfile from './pages/DoctorDashboard/doctorEditProfile/EditDoctorProfile';
import DoctorReviewReportView from './pages/common/AppointmentRecordView/DoctorReviewReportView';
import DoctorUpcomingReportView from './pages/common/AppointmentRecordView/DoctorUpcomingReportView';


/* Components */
import Home from './layout/home/Home';
import About from './container/about/About';
import Terms from './container/contact/Terms';
import Privacy from './container/contact/Privacy';
import Contact from './container/contact/Contact';
import PopupChat from './components/popupChat/PopupChat';
import VideoChatNew from './components/newVideo/VideoChatNew';
import DicomScreen from './components/dicomViewer/DicomScreen';
import SearchDoctor from './container/searchdoctors/SearchDoctor';
import DicomScreenNew from './components/dicomViewer/DicomScreenNew';
import UserVideoCalling from './components/videoCalling/UserVideoCalling';
import DoctorVideoCalling from './components/videoCalling/DoctorVideoCalling';


/* Contexts */
import GlobalContext from './context/GlobalContext';
import LocaleContext from './LocaleContext'


/* Utilities */
import { generateImageURL } from './utils';
import { getCaller } from './services/api';
import { fix_pages, profiles } from './utils/constants';
import useMasters from './hooks/userMasters';


/* PAGES */
import ForgotPassword from './pages/Forgot';
import Review from './pages/DoctorDashboard/review/Review';
import ClinicPublicProfile from "./pages/ClinicPublicProfile";
import UserWallet from './pages/UserDashboard/userwallet/UserWallet';
import UserAccount from './pages/UserDashboard/useraccount/UserAccount';
import UserAppointment from './pages/UserDashboard/userappointment/UserAppointment';
import AllAppointment from './pages/DoctorDashboard/doctorAppointment/AllAppointment';
import UserMedicalRecord from './pages/UserDashboard/usermedicalrecord/UserMedicalRecord';
import DoctorProfileAccount from './pages/DoctorDashboard/doctoraccount/DoctorProfileAccount';
import DoctorReviewAppointment from "./pages/DoctorDashboard/doctorAppointment/DoctorReviewAppointment";
import DoctorUpcomingAppointment from "./pages/DoctorDashboard/doctorAppointment/DoctorUpcomingAppointment";
import DoctorPastAppointment from "./pages/DoctorDashboard/doctorAppointment/DoctorPastAppointment";


function App() {
    /* ISOLATED STATES */
    const [locale, setLocale] = useState(i18next.language)
    const { _initialize } = useMasters();
    i18next.on('languageChanged', (lng) => setLocale(i18next.language))

    /* CONTEXT */
    const context = React.useContext(GlobalContext);

    const [dynamicRoutes, setDynamicRoutes] = context.dynamicRoutes;
    const [, setLogo] = context.logo;
    const [, setSocials] = context.socials;
    const [, setUserDetails] = context.userDetails;
    const [, setEmail] = context.email;
    const [, setNotiCount] = context.notificationsCount;
    const [, setAddress] = context.address;
    const [, setUserProfile] = context.userProfile;
    const socket = context.socket;


    async function getRouterData() {
        try {
            const response = await getCaller('get-router-details');
            setDynamicRoutes(response?.data || {});
        } catch {
            setDynamicRoutes({});
        }
    }

    const fetchNavbarLogo = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages.logo}`)
            setLogo(generateImageURL(response?.data?.content) || null)
        } catch {
            setLogo(null)
        }
    }

    const fetchSocialLinks = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages.social_link}`)
            setSocials(response?.data?.content || {})
        } catch {
            setSocials({})
        }
    }

    const fetchEmail = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages.email}`)
            setEmail(response?.data?.content || "")
        } catch {
            setEmail("")
        }
    }

    const fetchAddress = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages.address}`)
            setAddress(response?.data?.content || {})
        } catch {
            setAddress({})
        }
    }

    const fetchNotificationsCount = async () => {
        try {
            const response = await getCaller("notifications-unread-count");
            setNotiCount(response?.data || 0);
        } catch {
            setNotiCount(0)
        }
    }

    const fetchUserDetails = async () => {
        try {
            const response = await getCaller('profile/details');
            socket.emit('manualConnection', response?.data);
            setUserProfile(generateImageURL(response?.data?.url))
            setUserDetails(response?.data || {})
        } catch {
            setUserDetails({})
        }
    }

    const fetchAtOnce = () => {
        getRouterData()
        fetchNavbarLogo()
        fetchSocialLinks()
        fetchEmail()
        fetchAddress()
        fetchNotificationsCount()
    }

    useLayoutEffect(() => {
        _initialize();
        fetchAtOnce();
        fetchUserDetails();

    }, [])

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <Helmet htmlAttributes={{
                lang: locale,
                dir: locale === 'en' ? 'ltr' : 'rtl'
            }}>
            </Helmet>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar
                limit={1}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="light"
                className={'text-capitalize'}
            />
            <BrowserRouter>
                <Wrapper>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path={"/searchdoctor"} element={<SearchDoctor />} />


                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password/:token" element={<ForgotPassword />} />

                        {/* nesting dashboard routing */}
                        <Route path="/dashboard" element={<ProtectedRoute>{<Dashboard />}</ProtectedRoute>}>
                            <Route index element={
                                localStorage.getItem('type') === profiles.user
                                    ? <UserAppointment />
                                    : <DoctorProfileAccount />
                            } />

                            <Route path='user/medical' element={<UserMedicalRecord />} />
                            <Route path='user/account' element={<UserAccount />} />
                            <Route path='user/wallet' element={<UserWallet />} />

                            <Route path='doctor/reviews' element={<Review />} />
                            <Route path='doctor/appointments' element={<AllAppointment />}>
                                <Route index element={<DoctorReviewAppointment />} />
                                <Route path='upcoming' element={<DoctorUpcomingAppointment />} />
                                <Route path='past' element={<DoctorPastAppointment />} />
                            </Route>

                        </Route>
                        <Route path='/doctorUpcomingReport/:appointmentId' element={<ProtectedRoute>{<DoctorUpcomingReportView />}</ProtectedRoute>} />
                        <Route path="/downloadVirtualReport" element={<ProtectedRoute>{<DownloadViewReport />}</ProtectedRoute>} />
                        <Route path="/doctorReviewReport" element={<ProtectedRoute>{<DoctorReviewReportView />}</ProtectedRoute>} />
                        <Route path="/userReviewReport" element={<ProtectedRoute>{<UserReviewReport />}</ProtectedRoute>} />
                        <Route path="/editDoctorProfile/:id" element={<ProtectedRoute>{<EditDoctorProfile />}</ProtectedRoute>} />
                        <Route path="/doctorvideo" element={<ProtectedRoute>{<DoctorVideoCalling />}</ProtectedRoute>} />
                        <Route path="/userUpcomingReport/:appointmentId" element={<ProtectedRoute>{<UserViewDetails />}</ProtectedRoute>} />
                        <Route path="/editPatientProfile" element={<ProtectedRoute>{<UserProfileEdit />}</ProtectedRoute>} />
                        <Route path="/uservideocalling" element={<ProtectedRoute>{<UserVideoCalling />}</ProtectedRoute>} />
                        <Route path='/bookappointment/:loginId' element={<ProtectedRoute>{<BookAppointment />}</ProtectedRoute>} />
                        <Route path='/filldetails' element={<ProtectedRoute>{<FillDetails />}</ProtectedRoute>} />
                        <Route path='/paymentfill' element={<ProtectedRoute>{<PaymentFill />}</ProtectedRoute>} />
                        <Route path='/complete' element={<CompletePayment />} />
                        <Route path='/userDownloadReport/:appointmentId' element={<ProtectedRoute>{<UserDownloadReport />}</ProtectedRoute>} />
                        <Route path='/addfunds' element={<ProtectedRoute>{<AddFund />}</ProtectedRoute>} />
                        <Route path='/dicomscreen' element={<ProtectedRoute>{<DicomScreen />}</ProtectedRoute>} />
                        <Route path='/dicomscreennew' element={<ProtectedRoute>{<DicomScreenNew />}</ProtectedRoute>} />
                        <Route path='/notifications' element={<ProtectedRoute>{<Notifications />}</ProtectedRoute>} />

                        <Route path='/newVideo' element={<ProtectedRoute>{<VideoChatNew />}</ProtectedRoute>} />

                        <Route path='/var-clinic-doctor-profile' element={<ProtectedRoute>{<ClinicPublicProfile />}</ProtectedRoute>} />
                        {/* PopUp Chat */}
                        <Route path='/window/popup/:roomId' element={<ProtectedRoute>{<PopupChat />}</ProtectedRoute>} />

                        {/* DB ROUTES */}
                        {
                            dynamicRoutes &&
                            <>
                                <Route path={`/${dynamicRoutes[fix_pages.terms]}`} element={<Terms />} />
                                <Route path={`/${dynamicRoutes[fix_pages.privacy]}`} element={<Privacy />} />
                                <Route path={`/${dynamicRoutes[fix_pages.about]}`} element={<About />} />
                                <Route path={`/${dynamicRoutes[fix_pages.contact]}`} element={<Contact />} />
                            </>
                        }

                        <Route path="*" element={<Home></Home>} />
                    </Routes>
                </Wrapper>
            </BrowserRouter>
        </LocaleContext.Provider>
    );
}

const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export default App;