import React, { useState, useEffect, useRef } from 'react';
import Video from 'twilio-video';
import Part from './Part';
import { useTime } from 'react-timer-hook';
import '../newVideo/videoChat.css'
import useGlobal from '../../hooks/useGlobal';
import { appointmentStatus, profiles } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { getCaller, updateCaller } from '../../services/api';

const Room = ({
    roomName,
    token,
    handleLogout,
    disconnect,
    commonProps,
    triggerModal
}) => {
    const navigate = useNavigate();

    const screenShareRef = useRef();
    const [room, setRoom] = useState(null);
    const { userDetails: [userDetails] } = useGlobal();
    const [participants, setParticipants] = useState([]);


    const whoseOnTheOtherSide = () => {
        if (userDetails?.type === profiles.doctor) {
            return commonProps?.chatData?.user_profile?.name;
        } else {
            return commonProps?.chatData?.doctor_profile?.name
        }
    }

    useEffect(() => {
        if (disconnect === true) {
            room.disconnect();
        }
    }, [disconnect])

    const checkAndTriggerModal = async () => {
        try {
            if (userDetails?.type === profiles.user) {
                const response = await getCaller(`getOneAppointment/${commonProps?.chatData?.appointmentId}`);
                if (response?.data?.status === appointmentStatus.Completed) {
                    triggerModal(true)
                    room.disconnect();
                } else {
                    navigate(`/userUpcomingReport/${commonProps?.chatData?.appointmentId}`)
                }
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    const userDisconnect = async () => {
        await updateCaller(`appointment/left/${commonProps?.chatData?.appointmentId}`)
    }

    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            commonProps.setForceClosed && commonProps.setForceClosed(true)
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
            checkAndTriggerModal()
            // if (commonProps?.callFinished === false) {
            //     navigate('/dashboard');
            // } else {
            // }
        };


        Video.connect(token, {
            name: roomName
        }).then(room => {

            /* Comming from parent element to handle "End Call" button so, App won't crash if user ends call before even connecting in the first place*/
            commonProps.setCallConnected(true)

            /* Setting Room State */
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.on('disconnected', () => {
                commonProps.setForceClosed && commonProps.setForceClosed(true)
                triggerModal(true)
                // if (commonProps?.callFinished === false) {
                //     navigate('/dashboard');
                // } else {
                // }
            })
            room.participants.forEach(participantConnected);
        });

        return () => {
            setRoom(
                currentRoom => {
                    if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                        currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                            trackPublication.track.stop();
                        });
                        currentRoom.disconnect();
                        commonProps.setForceClosed && commonProps.setForceClosed(true)
                        userDisconnect()
                        navigate('/dashboard');
                        // if (commonProps?.callFinished === false) {
                        // } else {
                        //     triggerModal(true)
                        // }
                        return null;
                    } else {
                        return currentRoom;
                    }
                });
        };
    }, [roomName, token]);

    useEffect(() => {
        return () => {
            const currentRoom = room;
            if (currentRoom) {
                const track = [...currentRoom.localParticipant.videoTracks.values()][0]?.track;
                if (track) {
                    track?.stop();
                    track?.detach();
                    currentRoom.disconnect()
                    commonProps.setForceClosed && commonProps.setForceClosed(true)
                    userDisconnect()
                }
            }
        }
    }, [room])

    // const remoteParticipants = participants.map(participant => (
    //   <Part key={participant.sid} participant={participant} handleLogout={handleLogout} />
    // ));

    /* NO need to loop to participants as there would be only one participant*/
    return (
        <>
            <div className="room">
                <div className="live-time">
                    <p>
                        APPOINTMENT IN PROGRESS
                        {/* {hours} : {minutes}:{seconds}:{ampm} Minutes Remaining */}
                    </p>
                </div>
                <video style={{ maxWidth: "100%", maxHeight: '100%' }} ref={screenShareRef} className='screen-share' />

                <div className="local-participant">
                    {
                        room
                            ? <Part
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                                room={room}
                                name='You'
                                screenShareRef={screenShareRef}

                                /* directe handling */
                                isLocal={true}
                            />
                            : ''
                    }
                </div>

                <div className="remote-participants">
                    {
                        Array.isArray(participants)
                        && participants.length > 0
                        && <Part
                            screenShareRef={screenShareRef}
                            key={participants[0].sid}
                            participant={participants[0]}
                            handleLogout={handleLogout}
                            room={room}
                            name={whoseOnTheOtherSide()}
                            isRemote={true}
                        />
                    }
                    {/* <h2>Room: {roomName}</h2>  */}
                </div>
            </div>

        </>
    );
};

export default Room;