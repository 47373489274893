import React, { useState } from "react";
import e from "../../../assets/images/varlogo.svg";
import down from "../../../assets/images/Group 5853.png";
import Navbar from "../../../layout/Navbar/Navbar";
import Footer from "../../../layout/footer/Footer";
import { useLocation } from "react-router-dom";
import { formatTime, showDob } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import "./view.css";
import file from "../../../assets/images/file.png";
import { BiDownload, BiFile } from "react-icons/bi";
import DicomModal from "../../../components/modal/DicomModal";
import { getCaller } from "../../../services/api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pdf from "react-to-pdf";
const ref = React.createRef();
const UserDownloadReport = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const downloadReport = state?.userCompleteData ?? "";
  const downloadFile = state?.userFileReview ?? "";
  const [date, setDate] = useState(null);
  const [slot, setSlot] = useState(null);
  const [fileData, setFileData] = useState();

  const backHandle = (e) => {
    e.preventDefault();
    navigate("/dashboard");
    localStorage.setItem("index", "0");
  };
  // const getFile= async ()=>{
  //   const res = await getCaller(`getOneFile/${downloadReport.data.appointmentId}`);
  //   console.log(res)
  //   setFileData(res)

  // }

  //   const generatePDF = async () => {
  //     // const doc = new jsPDF();
  //     var doc = new jsPDF('p', 'pt', 'A4');
  //     const tableColumn1 = ["Appointment ID", "Appointment Date & Time", "Booking Date", "Appointment Status"];
  //     const tableRows1 = [];
  //     const tableColumn2 = ["Patient ID", "Patient Name", "Gender", "Age"];
  //     const tableRows2 = [];
  //     const tableColumn3 = ["Doctor Name & Designation", "Study Type", "Speciality"];
  //     const tableRows3 = [];
  //     const tableColumn4 = ["Clinical Data"];
  //     const tableRows4 = [];
  //     const tableColumn5 = ["Brief Summary"];
  //     const tableRows5 = [];
  //     const tableColumn6 = ["Recommendation"];
  //     const tableRows6 = [];
  //     const tableColumn7 = ["Problem description"];
  //     const tableRows7 = [];
  //     // const tableColumn8 = ["Attachments"];
  //     // const tableRows8 = [];
  //       const pdfData1 = [
  //         downloadReport?.appointmentId??"n/a",
  //         downloadReport?.slot[0]?.date??"n/a",
  //         downloadReport?.slot[0]?.date??"n/a",
  //         downloadReport?.status??"n/a"
  //       ];
  //       const pdfData2 = [
  //         downloadReport?.appointmentId??"n/a",
  //         downloadReport?.patientName??"n/a",
  //         downloadReport?.gender??"n/a",
  //         downloadReport?.age??"n/a"
  //       ];
  //       const pdfData3 = [
  //         downloadReport?.doctor_profile?.name??"n/a",
  //         downloadFile?.result?.studyType??"n/a",
  //         downloadReport?.serviceName??"n/a",
  //       ];
  //       const pdfData4 = [
  //         downloadFile?.result?.history??'n/a'
  //       ];
  //       const pdfData5 = [
  //         downloadFile?.result?.briefSummary??'n/a'
  //       ];
  //       const pdfData6 = [
  //         downloadFile?.result?.recommendation??'n/a'
  //       ];
  //       const pdfData7 = [
  //         downloadReport?.description??"n/a"
  //       ];
  //       // const pdfData8 = [
  //       // ];

  //       tableRows1.push(pdfData1);
  //       tableRows2.push(pdfData2);
  //       tableRows3.push(pdfData3);
  //       tableRows4.push(pdfData4);
  //       tableRows5.push(pdfData5);
  //       tableRows6.push(pdfData6);
  //       tableRows7.push(pdfData7);
  //       // tableRows8.push(pdfData8);

  //     // doc.text(tableColumn1,tableColumn2,{ startY: 20 },{ endY: 60 }, tableRows1,tableRows2, { startY: 100 });
  //     doc.text(tableColumn1, tableRows1);
  //     doc.text(tableColumn2, tableRows2, {margin: {top: 80}});
  //     doc.text(tableColumn3, tableRows3, {margin: {top: 80}});
  //     doc.text(tableColumn4, tableRows4, {margin: {top: 80}});
  //     doc.text(tableColumn5, tableRows5, {margin: {top: 80}});
  //     doc.text(tableColumn6, tableRows6, {margin: {top: 80}});
  //     doc.text(tableColumn7, tableRows7, {margin: {top: 80}});
  //     // doc.text(tableColumn8, tableRows8, {margin: {top: 80}});
  //     const pdfDocDate = Date().split(" ");
  //     const dateStr = pdfDocDate[0] + pdfDocDate[1] + pdfDocDate[2] + pdfDocDate[3] + pdfDocDate[4];
  //     doc.text("VIRTUAL CLINIC SUMMARY", 14, 15);
  //     doc.save(`AppointmentDetailsReport_${dateStr}.pdf`);
  // }
  const options = {
    orientation: "landscape",
    unit: "in",
    // format: [4,2]
  };

  return (
    <>
      <Navbar />
      <div className="view-container-1">
        <div className="down-view">
          <button onClick={backHandle} className="back-btn">
            {" "}
            <FaAngleLeft /> Back{" "}
          </button>
          <div className="down-view-content" ref={ref}>
            <div className="view-logo">
              <img src={e} alt="" />
            </div>
            <div className="download-view-content">
              <div className=""></div>
              <div className="virtual-1">
                <h3>VIRTUAL CLINIC SUMMARY</h3>
              </div>
              <div className="download-view-content-1">
                <Pdf
                  targetRef={ref}
                  filename="VirtualClinicReport"
                  options={options}
                  x={0.1}
                  y={0.5}
                  scale={0.75}
                >
                  {({ toPdf }) => (
                    <img
                      src={down}
                      alt=""
                      className="down-img-btn"
                      onClick={toPdf}
                    />
                  )}
                </Pdf>
              </div>
            </div>
            <div className="down-details">
              <h4>Appointments Details</h4>
              <div className="down-details-border1">
                <div className="common-card">
                  <div className="common-body">
                    <p> Appointment ID </p>
                    <h4> {downloadReport?.appointmentId ?? "n/a"} </h4>
                  </div>
                  <div className="common-body1">
                    <p> Appointment Date & Time </p>
                    <h4>
                      {`${showDob(downloadReport?.slot[0]?.date)}, ${formatTime(
                        downloadReport?.slot[0]?.time.start
                      )} - ${formatTime(
                        downloadReport?.slot[0]?.time?.end
                      )}`}{" "}
                    </h4>
                  </div>
                  <div className="common-body1">
                    <p> Booking Date </p>
                    <h4> {`${showDob(downloadReport?.slot[0]?.date)}`} </h4>
                  </div>
                </div>
              </div>
              <div className="down-details-border1">
                <h4>Patients Details</h4>
                <div className="common-card">
                  <div className="common-body">
                    <p> Patient ID </p>
                    <h4> {downloadReport?.appointmentId ?? "n/a"} </h4>
                  </div>
                  <div className="common-body1">
                    <p> Patient Name </p>
                    <h4> {downloadReport?.patientName ?? "n/a"} </h4>
                  </div>
                  <div className="common-body1">
                    <p> Gender </p>
                    <h4> {downloadReport?.gender ?? "n/a"} </h4>
                  </div>
                  <div className="common-body1">
                    <p> Age </p>
                    <h4> {downloadReport?.age ?? "n/a"} </h4>
                  </div>
                </div>
              </div>
              <div className="down-details-border2">
                <h4>Doctor Details</h4>
                <div className="common-card">
                  <div className="common-body">
                    <p>Doctor Name & Designation </p>

                    <h4> {downloadReport?.doctor_profile?.name ?? "n/a"} </h4>
                  </div>
                  <div className="common-body1">
                    <p> Study Type </p>
                    <h4> {downloadFile?.result?.studyType ?? "n/a"} </h4>
                  </div>
                  <div className="common-body1">
                    <p> Speciality </p>
                    <h4> {downloadReport?.serviceName ?? "n/a"} </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="download-clinic">
              <h4 className="view-heading">Clinical Data</h4>
              <p> {downloadFile?.result?.history ?? "n/a"} </p>
            </div>
            <div className="download-clinic">
              <h4 className="view-heading">Brief Summary</h4>
              <p> {downloadFile?.result?.briefSummary ?? "n/a"} </p>
            </div>
            <div className="download-clinic">
              <div className="down-rec">
                <h4>Recommendation</h4>
                <p> {downloadFile?.result?.recommendation ?? "n/a"} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDownloadReport;
