import { display } from "@mui/system";
import React from "react";
import {getCaller} from '../../services/api'
class ChatItem extends React.Component {

  constructor(props){
    super(props)
    this.state ={
      data : ""
        }
  }
  async UserList() {
    const res = await getCaller(`videoCallingData/ID000101371
    `);
    if(res.status){
      console.log(res)
      this.setState({data:res.data})

    }else{
      console.log('Api failed')
    }
  }
  componentDidMount(){
    this.UserList()
  }

  render() {
    const { message, email } = this.props;
    const isOwnMessage = message.author == email;

    return (
     <>
      <div style={styles.timestamp(isOwnMessage)}>
      Appointment Started   {new Date(message.dateCreated.toISOString()).toLocaleString()}
    </div>
      <div style={styles.listItem(isOwnMessage)}>
        <div> <img style={styles.author(isOwnMessage)} src={this.state.data.doctorImage} alt=""/> </div>
        <div style={styles.container(isOwnMessage)}>
          {message.body}
        </div>
      </div>
     </>
    );
  }
}

const styles = {
  listItem: (isOwnMessage) => ({
    display:"flex",
  }),
  container: (isOwnMessage) => ({
    maxWidth: "75%",
    borderTopRightRadius: isOwnMessage? "0rem":".5rem" ,
    borderBottomRightRadius:isOwnMessage? ".5rem":".5rem",
    borderBottomLeftRadius:isOwnMessage? ".5rem":".5rem",
    borderTopLeftRadius: isOwnMessage? ".5rem":"0rem" ,
   marginLeft:isOwnMessage?"0rem":"0rem",
  //  marginTop:".5rem",
    border:"1px solid #CBCBCB",
    padding: 16,
    color: "#1A1919",
   width:"100%",
    fontSize: 14,
    backgroundColor: isOwnMessage ? "#FFFFFF" : "#FFFFFF",
  }),
  
  author:(isOwnMessage)=>( { 
    fontSize: 10,
     color: "gray", 
  display:"flex",
  justifyContent:isOwnMessage?"flex-end":"flex-start",
  width:"4rem",
  alignItems:"center",
  height:"4rem",
  borderRadius:"50%",
  margin:"0.5rem 0.5rem 0 0rem"
}),
  timestamp: (isOwnMessage)=>(
  { fontSize: 11,
     color: "black",
      textAlign: "center",
     padding:".5rem 2rem .5rem 2rem",
       backgroundColor:"#F0F0F0",
       borderRadius:"2rem",
     margin:" .5rem 0rem .5rem 4.5rem",
    
       maxWidth: "75%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
      }),
};

export default ChatItem;
