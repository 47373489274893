import Footer from "./footer/Footer";
import FooterHero from "./footer/FooterHero";
import Navbar from './Navbar/Navbar'
function Layout({ children, restrictHeaderFooter = false }) {

    return (
        <>
            {!(restrictHeaderFooter) && <Navbar />}
            {children}
            {
                !(restrictHeaderFooter)
                && <FooterHero footerData={
                    children.length > 0 ?
                        children[0]?.props?.footerData : undefined
                } />
            }
            {!(restrictHeaderFooter) && <Footer />}
        </>
    );
}

export default Layout;


export const LayoutTwo = ({ children }) => {
    return <>
        <Navbar />
        {children}
        <Footer />
    </>
}