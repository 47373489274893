import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WetReportModal from '../../../../pages/DoctorDashboard/doctorAppointment/Modals/WetReport'

const EndCall = ({
    endCall,
    openConfirm,
    setOpenConfirm,
    ...props
}) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleCloseConfirm = () => {
        navigate(`/doctorUpcomingReport/${props?.appointmentId}`, { replace: true });
        setOpenConfirm(false);
        endCall()
    }

    const handleOpenChildModal = () => {
        setOpen(true)
        setOpenConfirm(false)
    }

    return (
        <>
            {/* Confirm dialog box */}
            <Dialog
                open={openConfirm}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Appointment End
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Would you like to mark this conversation as finished ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>No</Button>
                    <Button onClick={handleOpenChildModal} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* common wet report modal */}
            <WetReportModal
                open={open}
                close={() => setOpen(false)}
                fetchPrevious={true} /* !!IMP!! */

                {...props}
            />
        </>
    )
}

export default EndCall