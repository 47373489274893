import React from "react";
import {motion} from "framer-motion";
import "./online.css";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import one from "../../../assets/images/Group2.svg";
import two from "../../../assets/images/Group1.svg";
import three from "../../../assets/images/Group3.svg";
import {useTranslation} from "react-i18next";
import design from '../../../assets/images/design2.png';
import "../../../../src/components/animations/animations.css";
import {generateImageURL} from '../../../utils';
import AOS from "aos";
import useGlobal from "../../../hooks/useGlobal";

const OnlineConsult = props => {
    const {tKey: [tKey]} = useGlobal();
    const {
        Title,
        SubTitle,
        Images
    } = props;

    const {t} = useTranslation();
    AOS.init({
        offset: 100,
        duration: 1300,
        easing: "ease-in-sine",
        delay: 100,
    });

    return (
        <>
            <div className="online">
                <div className="online-content" data-aos="fade-down">
                    <h2>
                        {/* {t('skipTheTravel')} */}
                        {Title[tKey]}
                    </h2>
                    <p>
                        {SubTitle[tKey]}
                    </p>
                </div>
                <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}}
                            transition={{duration: 0.8}}>
                    <div>
                        <Box sx={{flexGrow: 1}}>
                            <Grid
                                container
                                spacing={{xs: 2, md: 2}}
                                columns={{xs: 4, sm: 8, md: 12}}
                                justifyContent="center"
                            >
                                {Images.map((e, i) => {
                                    return (
                                        <Grid item xs={16} sm={4} md={4} key={`online-consult-${i}`}>
                                            <div className="online-img-content">
                                                <div
                                                    animate={{x: 100, scale: 1}}
                                                    initial={{scale: 0}}>
                                                    <img
                                                        //  style={{animation: "pulse 3s cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite"}}
                                                        src={generateImageURL(e?.image)} alt=""/>
                                                    <p className="online-para"> {e?.title[tKey]} </p>
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </div>
                </motion.div>
            </div>
            <div className="online-design">
                <img src={design} alt=""/>
            </div>

        </>
    );
};

export default OnlineConsult;
