// import React, { useState, useEffect } from 'react'
import * as Yup from 'yup';
import '../pages/loginregister.css'
import { useFormik } from 'formik';
import Navbar from '../layout/Navbar/Navbar'
import { useTranslation } from 'react-i18next';
import two from '../assets/images/login_vector.png'
import GlobalContext from '../context/GlobalContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getCaller, loginUser, updateCaller } from '../services/api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { requestForToken, onMessageListener } from '../firebaseNotifications/firebase';
import { ThemeButton } from '../components/common/Button';
import { TextField } from '@mui/material';


const loginSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password is too small').max(20, 'Password is too large').required('Password is required'),
    cPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password does not matched"
        )
    })
});


const ForgotPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [fcmToken, setFcmToken] = useState('');

    /* for handling eye buttons */
    const [eyeVisibilityP, setEyeVisibilityP] = useState(false); /* P is for password */
    const [eyeVisibilityCP, setEyeVisibilityCP] = useState(false); /* CP is for confirm-password */

    const passwordRef = useRef(null);
    const cPasswordRef = useRef(null);

    /* CONTEXT */

    const toggleEyeIconP = () => {
        setEyeVisibilityP(!eyeVisibilityP);
        passwordRef.current.type = eyeVisibilityP ? 'password' : 'text';
    }

    const toggleEyeIconCP = () => {
        setEyeVisibilityCP(!eyeVisibilityCP);
        cPasswordRef.current.type = eyeVisibilityCP ? 'password' : 'text';
    }


    const formik = useFormik({
        initialValues: {
            password: '',
            cPassword: ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            try {
                const payload = {
                    password: values.password,
                    token: token
                }
                const response = await updateCaller('forgot-password-verify', payload)
                if (response?.error === false) {
                    navigate('/login');
                    toast.success(response?.msg);
                    return;
                }
                return toast.info(response?.msg);

            } catch {
                return toast.error('Internal Server Error')
            }
        }
    })
    const { errors, handleChange, values, setFieldValue, touched, handleBlur, setFieldTouched } = formik;


    return (
        <>
            <Navbar></Navbar>
            <div className='register'>
                <div className="register-container">
                    <div className='register-image-contain'>
                        <img src={two} alt="" />
                    </div>
                    <div className="register-form">
                        <h2> Reset Password </h2>
                        <form className='form-c' onSubmit={formik.handleSubmit}>
                            <div style={{ width: "60%" }}>
                                <div className='register-input-field'>
                                    <TextField
                                        style={{ width: "100%" }}
                                        size="medium"
                                        type="password"
                                        name="password"
                                        className=''
                                        placeholder={t('enterPassword')}
                                        onBlur={handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        inputRef={passwordRef}

                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}

                                        InputProps={{
                                            endAdornment: (
                                                <div className='' onClick={toggleEyeIconP}>
                                                    {eyeVisibilityP ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </div>
                                            )
                                        }}
                                    />

                                    <div className="register-input-field">
                                        <TextField
                                            style={{ width: "100%" }}
                                            size="medium"
                                            type="password"
                                            name="cPassword"
                                            className=''
                                            placeholder={t('enterPassword')}
                                            onBlur={handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.cPassword}

                                            inputRef={cPasswordRef}

                                            error={Boolean(touched.cPassword && errors.cPassword)}
                                            helperText={touched.cPassword && errors.cPassword}

                                            InputProps={{
                                                endAdornment: (
                                                    <div className='' onClick={toggleEyeIconCP}>
                                                        {eyeVisibilityCP ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='btn-container'>
                                    <div style={{
                                        borderRadius: '1.5rem',
                                        width: '70%',
                                        height: '3rem'
                                    }}>
                                        <ThemeButton
                                            /* for handling eye buttons */
                                            loading={formik.isSubmitting}
                                            className='theme-button'
                                            text={t("submit")}
                                            type='submit'
                                        />
                                    </div>

                                </div>
                            </div>
                        </form>

                        <div className='register-para'>
                            <p> {t('newToVar')} <Link to="/register" > {t('register')} </Link> </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword