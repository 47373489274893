import React, { useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify';
import { ThemeButton } from '../components/common/Button';
import { getCaller } from '../services/api';

const getFormatedTimer = timer => {
    return timer < 10 ? `0${timer}` : timer
}

const TimerButton = ({
    initialTimer,
    tempUser

}) => {
    const [timer, setTimer] = useState(initialTimer || 0);
    const [loader, setLoader] = useState(false)
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), [initialTimer]);

    useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);

    const handleResend = async function () {
        try {
            setLoader(true)
            const response = await getCaller(`resend?id=${tempUser.data?.loginId}`);
            if (response?.error === false) {
                setLoader(false)
                setTimer(response?.data?.timer)
                toast.info(response?.msg)
            } else {
                setLoader(false)
                setTimer(response?.data?.timer)
            }

        } catch {
            setTimer(0)
            setLoader(false)
        }
    };

    return (
        <ThemeButton
            loading={loader}
            disabled={timer > 0}
            className='theme-button'
            onClick={handleResend}
            text={
                timer > 0
                    ? getFormatedTimer(timer)
                    : 'Resend OTP'
            }
        />

    )
}

export default TimerButton