import React, { useEffect } from "react";
import g from "../../assets/images/google-play-badge-1.png";
import f from "../../assets/images/Image 1.png";
import { useTranslation } from "react-i18next";
import { generateImageURL } from "../../utils";
import useGlobal from "../../hooks/useGlobal";
const FooterHero = (props) => {
    const { tKey: [tKey] } = useGlobal();

    const data = props.footerData && props.footerData();

    const Title = data?.title?.[tKey];
    const SubTitle = data?.subtitle?.[tKey];
    const Image = data?.image;

    const { t } = useTranslation();
    return (
        <>
            <div className="footer-hero">
                <div className="down-img-container">
                    <img
                        // style={{animation: "slidein 3s alternate infinite"}}
                        // style={{animation: "drop .4s linear forwards .8s"}}
                        src={generateImageURL(Image)}
                        alt=""
                        className="downmobile-img"
                    />
                </div>
                <div className="mobile-app">
                    <h2>
                        {Title}
                    </h2>
                    {/* <h2>{t("downloadOurMobileApp")}</h2> */}
                    <p>
                        {SubTitle}
                    </p>
                    {/* <p> {t("accessVideoConsultation")}</p> */}
                </div>
                <div className="hero-form">
                    <p>{t("getTheLink")}</p>
                    <div className="form-content">
                        <input
                            type="number"
                            name=""
                            placeholder={t("enterMobile")}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                            onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            maxLength={10}
                        />
                        <button className="sms-btn">{t("sendSMS")} </button>
                    </div>
                    <div className="hero-btn">
                        <p> OR </p>
                        <img
                            src={f}
                            alt=""
                            // style={{animation: "pulse 4s cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite"}}
                            className="app-img"
                        />
                        <img
                            src={g}
                            alt=""
                        // style={{animation: "pulse 4s cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite", cursor:"pointer"}}
                        // style={{cursor:"pointer"}}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default FooterHero;
