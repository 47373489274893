import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";
import React from "react";
import Hammer from "hammerjs";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneMath from "cornerstone-math";
import Navbar from "../../layout/Navbar/Navbar";
import "../dicomViewer/dicom.css";
import  dimages from  '../../assets/images/download.png';
class DicomViewer extends React.Component {
  
  constructor(props){
    super()
    this.state={
  imageIds:{
    
  }

  
    }

  }
  componentWillMount() {
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      maxWebWorkers: navigator.hardwareConcurrency || 1,
      startWebWorkersOnDemand: true,
      taskConfiguration: {
        decodeTask: {
          initializeCodecsOnStartup: false,
          usePDFJS: false,
          strict: false,
        },
      },
    });
  }
  componentDidMount() {
    this.loadImage();
  }
  // "dicomweb://localhost:3000/dicom_images/0002.DCM",
  dicomImage = null;
  loadImage = (imageIds) => {
    if (!imageIds) {
      imageIds = [
        "dicomweb://s3.amazonaws.com/lury/PTCTStudy/1.3.6.1.4.1.25403.52237031786.3872.20100510032220.12.dcm",
        "dicomweb://s3.amazonaws.com/lury/PTCTStudy/1.3.6.1.4.1.25403.52237031786.3872.20100510032220.12.dcm",
      ];
    }
    const element = this.dicomImage;
    // Listen for changes to the viewport so we can update the text overlays in the corner
    function onImageRendered(e) {
      const viewport = cornerstone.getViewport(e.target);
      document.getElementById(
        "mrbottomleft"
      ).textContent = `WW/WC: ${Math.round(
        viewport.voi.windowWidth
      )}/${Math.round(viewport.voi.windowCenter)}`;
      document.getElementById(
        "mrbottomright"
      ).textContent = `Zoom: ${viewport.scale.toFixed(2)}`;
    }
    element.addEventListener("cornerstoneimagerendered", onImageRendered);
    const config = {
      // invert: true,
      minScale: 0.25,
      maxScale: 20.0,
      preventZoomOutsideImage: true,
    };
   
    cornerstoneTools.zoom.setConfiguration(config);
    // document.getElementById("chkshadow").addEventListener("change", () => {
    //   cornerstoneTools.length.setConfiguration({ shadow: this.checked });
    //   cornerstoneTools.angle.setConfiguration({ shadow: this.checked });
    //   cornerstone.updateImage(element);
    // });

    cornerstone.enable(element);
    cornerstone.loadImage(imageIds[0]).then((image) => {
      cornerstone.displayImage(element, image);
      cornerstoneTools.mouseInput.enable(element);
      cornerstoneTools.mouseWheelInput.enable(element);
      // // Enable all tools we want to use with this element
      cornerstoneTools.wwwc.activate(element, 1); // ww/wc is the default tool for left mouse button
      cornerstoneTools.pan.activate(element, 2); // pan is the default tool for middle mouse button
      cornerstoneTools.zoom.activate(element, 4); // zoom is the default tool for right mouse button
      cornerstoneTools.zoomWheel.activate(element); // zoom is the default tool for middle mouse wheel
      cornerstoneTools.probe.enable(element);
      cornerstoneTools.length.enable(element);
      cornerstoneTools.ellipticalRoi.enable(element);
      cornerstoneTools.rectangleRoi.enable(element);
      cornerstoneTools.angle.enable(element);
      cornerstoneTools.highlight.enable(element);
      // cornerstoneTools.orientationOptions.enable(element);
    });
  };
  enableTool = (toolName, mouseButtonNumber) => {
    this.disableAllTools();
    cornerstoneTools[toolName].activate(this.dicomImage, mouseButtonNumber);
  };
  // helper function used by the tool button handlers to disable the active tool
  // before making a new tool active
  disableAllTools = () => {
    cornerstoneTools.wwwc.disable(this.dicomImage);
    cornerstoneTools.pan.activate(this.dicomImage, 2); // 2 is middle mouse button
    cornerstoneTools.zoom.activate(this.dicomImage, 4); // 4 is right mouse button
    cornerstoneTools.probe.deactivate(this.dicomImage, 1);
    cornerstoneTools.length.deactivate(this.dicomImage, 1);
    cornerstoneTools.ellipticalRoi.deactivate(this.dicomImage, 1);
    cornerstoneTools.rectangleRoi.deactivate(this.dicomImage, 1);
    cornerstoneTools.angle.deactivate(this.dicomImage, 1);
    cornerstoneTools.highlight.deactivate(this.dicomImage, 1);
    cornerstoneTools.freehand.deactivate(this.dicomImage, 1);
    
  };
  reset = () => {
    let element = this.dicomImage;
    let toolStateManager = cornerstoneTools.getElementToolStateManager(element);
    toolStateManager.clear(element);
    cornerstoneTools.external.cornerstone.updateImage(element);
  };
  dicomImageRef = (el) => {
    this.dicomImage = el;
  };
  render() {
    return (
     <>
      <div className="dicom-container">
        <Navbar />

        <div >
          <div>
            <ul className="dicom-tab-btn">
              <button
                onClick={() => {
                  this.reset();
                }}
              >
                Reset
              </button>
              <button
                onClick={() => {
                  this.enableTool("wwwc", 1);
                }}
              >
                WW/WC
              </button>
              <button
                onClick={() => {
                  this.enableTool("pan", 3);
                }}
              >
                Pan
              </button>
              <button
                onClick={() => {
                  this.enableTool("zoom", 5);
                }}
              >
                Zoom
              </button>
              <button
                onClick={() => {
                  this.enableTool("length", 1);
                }}
              >
                Length
              </button>
              <button
                onClick={() => {
                  this.enableTool("probe", 1);
                }}
              >
                Probe
              </button>
              <button
                onClick={() => {
                  this.enableTool("ellipticalRoi", 1);
                }}
              >
                Elliptical ROI
              </button>
              <button
                onClick={() => {
                  this.enableTool("rectangleRoi", 1);
                }}
              >
                Rectangle ROI
              </button>
              <button
                onClick={() => {
                  this.enableTool("angle", 1);
                }}
              >
                Angle
              </button>
              <button
                onClick={() => {
                  this.enableTool("highlight", 1);
                }}
              >
                Highlight
              </button>
              <button
                onClick={() => {
                  this.enableTool("freehand", 1);
                }}
              >
                Freeform ROI
              </button>
              
            </ul>
           
          
          </div>
        
            <div
             
              onContextMenu={() => false}
              className="dicom-image-contain"
              unselectable="on"
              onSelectStart={() => false}
              onMouseDown={() => false}
            >
              <div className="left-dicom-images">
                
                <div className="left-contain-images">
                  <img src={dimages} alt="" />
                </div>
               
              </div>
              <div
                ref={this.dicomImageRef}
               className="mri-img"
              />
              <div
                id="mrbottomright"
               className="zoom"
              >
                Zoom:
              </div>
              <div
                id="mrbottomleft"
                className="zoom-wc"
              >
                WW/WC:
              </div>
            
          </div>
        </div>
      </div>
     </>
    );
  }
}

export default DicomViewer;
