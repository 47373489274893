import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import '../Navbar/nav.css';
import logo from "../../assets/images/varlogo.svg";
import translate from '../../assets/images/translate_black.png'
import chevronDown from '../../assets/images/chevronDown.png'
import bell from '../../assets/images/Group 5900.png'
import { useTranslation } from "react-i18next"
import LocaleContext from "../../LocaleContext";
import i18next from "i18next";
import { motion } from "framer-motion";
import { getCaller } from "../../services/api";
import { Avatar, Badge, Tooltip } from "@mui/material";
import GlobalContext from "../../context/GlobalContext";
import { coreChatWindow, generateImageURL, popupChatURL } from "../../utils";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { transmitters } from "../../utils/socket_transmitters";
import NotificationsIcon from '@mui/icons-material/Notifications';
import useGlobal from "../../hooks/useGlobal";
import { fix_pages } from "../../utils/constants";

const Navbar = () => {
    const navigate = useNavigate();
    const { tKey: [tKey, setTKey], dynamicRoutes: [dynamicRoutes] } = useGlobal();

    const { emitters, listeners } = transmitters;

    const [lang, setLang] = useState('English')
    const [loginOpen, setLoginOpen] = useState(false);
    const [name, setName] = useState(null)
    const type = localStorage.getItem('type')
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext);
    const [toolTip, setToolTip] = useState(false);
    const [open, setOpen] = useState(false);
    const [chatData, setChatData] = useState(false);
    const [popupWindow, setPopupWindow] = useState(null);


    /* CONTEXT */
    const context = React.useContext(GlobalContext);

    /* Context Variables */
    const [logo] = context.logo;
    const socket = context.socket;
    const [notificationsCount] = context.notificationsCount;
    /* Isolated states */
    const [userDetails] = context.userDetails;
    const [userProfile] = context.userProfile;

    const closeMobileMenu = () => setOpen(false);

    const roomURL = id => `/window/popup/${id}`

    const handleTooltip = whatToDo => {
        switch (whatToDo) {
            case 'close':
                setToolTip(false)
                break;
            case 'open':
                setToolTip(true)
                break;
            default:
                break;
        }
    }

    const menuOpen = () => {
        setOpen(open === false ? true : false);
        setLoginOpen(false);
    }

    function changeLocale(l) {
        if (tKey === 'en') {
            setTKey('ar')
        } else {
            setTKey('en')
        }
        if (locale !== l) {
            i18next.changeLanguage(l)
        }
    }
    const closeWindow = () => {
        popupWindow && popupWindow.close()
    }

    const openChatWindow = () => {
        handleTooltip('close');
        let _url = chatData;
        _url += `?email=${userDetails.email}`
        try {
            setPopupWindow(coreChatWindow(_url));
        } catch (err) {
            console.warn('error while opening popup window', err.msg)
        }
    }

    function setNavName() {
        const type = localStorage.getItem('type')
        const name = localStorage.getItem('name')

        if (type === 'user' && !name) {
            setName("Patient")
        }
        else if (type === 'doctor' && !name) {
            setName("Doctor")
        }
        else {
            setName(name)
        }
    }

    const recentQuery = async () => {
        const response = await getCaller('last-pending');
        // console.log("🚀 ~ file: Navbar.jsx ~ line 103 ~ recentQuery ~ response", response)
        if (response?.data !== null || response?.errorMsg === 'You are not authorized') {
            setChatData(roomURL(response?.data?.roomId))
        }
    }



    useEffect(() => {
        setNavName();

        socket.on(listeners['support-chat-call'], data => {
            console.log("🚀 ~ file: Navbar.jsx ~ line 109 ~ useEffect ~ data", data)
            handleTooltip('open')
            setChatData(roomURL(data?.roomId));
        })

        socket.on(listeners['force-close-chat-user'], data => {
            closeWindow()
            setChatData(null);
        })

    }, [popupWindow])

    useEffect(() => {
        recentQuery();
    }, [])
    return (
        <>
            <header className="header">
                <div className="logo-header">
                    <Link to="/">
                        <Avatar
                            src={logo}
                            variant="square"
                            alt="logo"
                            className="logo"
                        />
                    </Link>

                    <div className="dropdown">
                        <button className="dropbtn">
                            <img src={translate} alt="translate" className="translate" /> {
                                lang
                            }
                            <img src={chevronDown} className="chevronDown" alt="translate" />
                        </button>

                        <div className="dropdown-content">
                            <span
                                onClick={() => { changeLocale('en'); setLang("English") }}
                            >
                                English
                            </span>
                            <span
                                onClick={() => { changeLocale('ar'); setLang("Arabic") }}
                            >
                                Arabic
                            </span>
                        </div>
                    </div>
                </div>
                {!type ? (<ul className={`links ${open ? "is-expended" : ""}`}
                >
                    <div className="list-nav">

                        <li onClick={closeMobileMenu}>
                            <NavLink to="/"
                                className={({ isActive }) =>
                                    isActive ? 'activeLink' : 'navlink'}
                            >
                                {t('home')}
                            </NavLink>
                        </li>
                        <li onClick={closeMobileMenu}>
                            <NavLink to="/searchdoctor"
                                className={({ isActive }) =>
                                    isActive ? 'activeLink' : 'navlink'}
                            >
                                {t('searchDoctor')}
                            </NavLink>
                        </li>

                        <li onClick={closeMobileMenu}>
                            {
                                dynamicRoutes[fix_pages.about] &&
                                <NavLink
                                    to={dynamicRoutes[fix_pages.about] || ""}
                                    className={({ isActive }) =>
                                        isActive ? 'activeLink' : 'navlink'}
                                >
                                    {t('aboutUs')}
                                </NavLink>
                            }
                        </li>
                        <li onClick={closeMobileMenu}>
                            {
                                dynamicRoutes[fix_pages.contact] &&
                                <NavLink
                                    to={dynamicRoutes[fix_pages.contact] || ""}
                                    className={({ isActive }) =>
                                        isActive ? 'activeLink' : 'navlink'}
                                >
                                    {t('contactUs')}
                                </NavLink>
                            }
                        </li>
                    </div>
                    <li className="login-list" onClick={closeMobileMenu}>
                        {!type && (<div className="icons">
                            <button type="button" className="login-btn" onClick={() => setLoginOpen(!loginOpen)}>
                                {t('login') + "/" + t('register')}
                            </button>
                        </div>)}
                    </li>
                </ul>) : (<div></div>)}

                {type && (<div className={`book-list-nav ? ${open ? "book-is-expended" : ""}`} >
                    <div className="book-nav-content">
                        {
                            type === 'user' &&
                            // <motion.div >
                            <motion.button onClick={() => navigate('/searchdoctor')} className="login-btn" whileHover={{ scale: 1.1 }}> {t('bookanAppointment')}</motion.button>
                            // </motion.div>
                        }
                        {/* SUPPORT CHAT ICON */}
                        {
                            chatData &&
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltip}
                                open={toolTip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="New Message"
                            >
                                <SupportAgentIcon
                                    onClick={chatData ? openChatWindow : () => { }}
                                    className='customer_support'
                                />
                            </Tooltip>
                        }
                        <div className="bell" onClick={
                            () => navigate('/notifications')
                        }>
                            <Badge color="secondary" badgeContent={notificationsCount}>
                                <NotificationsIcon />
                            </Badge>
                        </div>
                    </div>
                    <div className="book-profile-nav">
                        <p className="book-nav-para">{name}</p>
                        <div onClick={
                            () => navigate('/dashboard')
                        }>
                            <Avatar
                                src={userProfile}
                            />
                            {/* <img src={localStorage.getItem('docs') ? localStorage.getItem('docs') : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" style={{
                                cursor: "pointer", width: '3.5rem', height: '3.5rem',
                                borderRadius: '50%'
                            }} /> */}
                        </div>
                    </div>
                </div>)}

                {
                    open ? (
                        <div className="fas fa-close" id="close-btn" onClick={menuOpen} ></div>
                    ) : (

                        <div className="fas fa-bars" id="menu-btn" onClick={menuOpen}></div>
                    )
                }
                <div className={`popup ${loginOpen ? "active-popup" : ""}`}>
                    <div>
                        <div className="popup-top" onClick={() => navigate('/login')}>
                            <div className="box-one">
                                <h4>Login</h4>
                                <p>Start something new or resume where you left</p>
                            </div>
                            <div className="login-icon">
                                <i className="fa-solid fa-chevron-right"></i>
                            </div>
                        </div>
                        <hr style={{ marginBottom: "1rem" }} />
                    </div>

                    <div className="popup-top" onClick={() => navigate('/register')}>
                        <div className="box-one">
                            <h4> Register </h4>
                            <p>Start something new or resume where you left</p>
                        </div>
                        <div className="login-icon">
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};
export default Navbar;