import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import down from '../../../assets/images/download-4.png';
import eye from '../../../assets/images/eye.png';
import { useNavigate } from 'react-router-dom';
import { showDob, formatTime, generateQuery } from '../../../utils';
import CustomPagination from '../../../components/CustomPagination';
import s from '../../../assets/images/search.png'
import { CSVLink } from 'react-csv';
import close from '../../../assets/images/cross.png'
import { useTranslation } from 'react-i18next';
import { getCaller } from '../../../services/api';
import { appointmentStatus } from '../../../utils/constants';
import DoctorAppointmentTable from './DoctorAppointmentTable';
const DoctorPastAppointment = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);


    // let completeData = props.data.pastData?.filter((el) => el?.patientName?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1);
    // const values = completeData?.map(data => {
    //     let obj = {
    //         "appointmentId": data?.appointmentId,
    //         "appointmentDateTime": data?.slot[0]?.date,
    //         "bookedFor": "Self",
    //         "specialityChosen": data?.serviceName,
    //         "doctorName": data?.doctor_profile?.name,
    //         "status": data?.status,
    //     };
    //     return obj
    // })
    // const headers = [
    //     {
    //         label: "Appointment ID", key: "appointmentId"
    //     },
    //     {
    //         label: "Appointment Date & Time", key: "appointmentDateTime"
    //     },
    //     {
    //         label: "Booked for", key: "bookedFor"
    //     },
    //     {
    //         label: "Specialisation Chosen", key: "specialityChosen"
    //     },
    //     {
    //         label: "Doctor Name", key: "doctorName"
    //     },
    //     {
    //         label: "Status", key: "status"
    //     },
    // ]
    // const csvLink = {
    //     headers: headers,
    //     data: values,
    //     filename: "CompleteAppointments.csv"
    // }

    const fetchPastAppointments = async payload => {
        try {
            let URL = `getAllAppointment?status=${appointmentStatus['Completed']}`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setAppointments(response?.data || {})
        } catch (e) {
            setAppointments({})
        }
    }
    const handleViewPast = (currentAppointment) => {
        return navigate(`/userDownloadReport/${currentAppointment?.appointmentId}`)
    }

    useEffect(() => {
        fetchPastAppointments();
    }, [])

    return (
        <>
            <div className="tabel-tab">
                <DoctorAppointmentTable
                    type={appointmentStatus['Completed']}
                    appointments={appointments}
                    refreshData={fetchPastAppointments}
                    handleViewPast={handleViewPast}
                />
            </div>
        </>
    )
}

export default DoctorPastAppointment