import { Modal, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getCaller } from '../../../../services/api';
import { dateFormatWW, destructureSlot, formatFromToTimeSlot } from '../../../../utils';
import useGlobal from '../../../../hooks/useGlobal';
import DicomModal from '../../../modal/DicomModal';
import { ImageList, ImageListItem } from "@mui/material";
// import file from '../../../assets/images/file.png';

import file from '../../../../assets/images/file.png';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';


// import { dateFormatWW, destructureSlot, formatFromToTimeSlot } from "../../../utils";
import { constants } from '../../../../utils/constants';


const AppointmentView = ({
    openAppointment,
    setOpenAppointment,
    appointmentId

}) => {

    const handleCloseAppointment = () => {
        setOpenAppointment(false);
    };
    const { tKey: [tKey] } = useGlobal();
    const handleOpenImage = path => window.open(path, '_blank')

    const [appointmentView, setAppointmentView] = useState({})
    const [appointmentReport, setAppointmentReport] = useState([])

    const [attachmentsImages, setAttachmentsImages] = useState([]);
    const [attachmentsOthers, setAttachmentsOthers] = useState([])


    const { start, end } = destructureSlot(appointmentView)?.slot;


    const fetchAppointmentDetails = async () => {
        const response = await getCaller(`getOneAppointment/${appointmentId}`)
        console.log("🚀 ~ file: AppointmentView.jsx:25 ~ fetchAppointmentDetails ~ response", response)
        setAppointmentView(response?.data)

    }

    const fetchAttachments = async () => {
        try {
            const response = await getCaller(`getOneFile/${appointmentId}`)

            if (response?.result) {
                setAppointmentReport(response?.result || {})
            }
            let images = [];
            let others = [];
            Array.isArray(response?.data) && response.data.forEach(
                attachment => {
                    const extension = attachment?.fileName?.split('.').pop();
                    extension.IMAGES.includes(extension) ? images.push(attachment) : others.push(attachment)
                }
            )
            setAttachmentsImages(images)
            setAttachmentsOthers(others)

        }
        catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fetchAppointmentDetails()
        fetchAttachments()
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAppointment}
                onClose={handleCloseAppointment}
            >
                <StyledBox>
                    <Grid item xs={12} md={12} className="side-right-content">
                        <div className='side-right-head'>
                            <h2 style={{
                                textTransform: 'capitalize'
                            }}> Appointment View</h2>
                        </div>
                        <div className="view-app-detail">
                            <h4>Appointment Details</h4>
                            <div className="common-card">
                                <div className="common-body">
                                    <p> Appointment ID </p>
                                    <h4> {appointmentView?.appointmentId ?? "N/a"} </h4>
                                </div>
                                <div className="common-body1">
                                    <p> Appointment Date & Time </p>
                                    <h4>
                                        {dateFormatWW(appointmentView?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                                        {/* {_showDate(DoctorupReport)} - {_formatTime(DoctorupReport)} */}
                                    </h4>
                                </div>
                                <div className="common-body1">
                                    <p> Booking Date </p>
                                    <h4> {`${Array.isArray(appointmentView?.slot) && dateFormatWW(appointmentView?.createdAt)}`}   </h4>
                                </div>
                            </div>
                            <div className="view-patient-deatail">
                                <h4>Patient Details</h4>
                                <div className="view-doc-detail">
                                    <div className="doc-content">
                                        <div className="common-card">
                                            <div className="common-body">
                                                <p> Patient ID </p>
                                                <h4>{appointmentView?.user_profile?.loginId ?? "n/a"} </h4>
                                            </div>
                                            <div className="common-body1">
                                                <p> Patient Name </p>
                                                <h4> {appointmentView?.patientName ?? "n/a"} </h4>
                                            </div>
                                            <div className="common-body1">
                                                <p> Gender </p>
                                                <h4>{appointmentView?.gender ?? "n/a"} </h4>
                                            </div>
                                            <div className="common-body1">
                                                <p> Age </p>
                                                <h4> {appointmentView?.age ?? "n/a"} </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="view-patient-deatail">
                                <h4>Doctor Details</h4>
                                <div className="common-card">
                                    <div className="common-body">
                                        <p>Doctor Name & Designation </p>
                                        <h4 className='text-capitalize'> {appointmentView?.doctor_profile?.name}, {appointmentView?.service?.name?.[tKey]} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Study Type </p>
                                        <h4 className='text-capitalize'> {appointmentReport?.studyType ?? "n/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Speciality </p>
                                        <h4 className='text-capitalize'> {appointmentView?.serviceName?.[tKey] ?? "n/a"} </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="download-clinic">
                                <h4 className="view-heading">Clinical Data</h4>
                                <p> {appointmentReport?.history ?? "n/a"} </p>
                            </div>
                            <div className="download-clinic">
                                <h4 className="view-heading">Brief Summary</h4>
                                <p> {appointmentReport?.briefSummary ?? "n/a"} </p>
                            </div>
                            <div className="download-clinic">
                                <div className="down-rec">
                                    <h4>Recommendation</h4>
                                    <p> {appointmentReport?.recommendation ?? "n/a"} </p>
                                </div>
                            </div>
                            <div className="view-patient-deatail">
                                <h4>Other details</h4>
                                <div className="view-doc-detail">
                                    <div
                                        className="down-details-border2"
                                        style={{ width: '50%' }}
                                    >
                                        <div
                                            className="common-card"
                                            style={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <div className="common-body">
                                                <p>Specialisation Chosen</p>
                                                <h4 className='text-capitalize'>
                                                    {`${(appointmentView?.service?.service_master?.name?.[tKey])}` ?? "n/a"}
                                                </h4>
                                            </div>
                                            <div
                                                className="common-body1"
                                                style={{
                                                    marginTop: '1rem',
                                                    margin: 'unset'
                                                }}
                                            >
                                                <p>Problem Description</p>
                                                <h4 className='text-capitalize'>
                                                    {appointmentView?.description ?? "n/a"}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="view-attach">
                                        <div style={{ marginLeft: "1rem" }} >
                                            <p>Attachments</p>
                                            {/* for iterating non images files */}
                                            {
                                                attachmentsOthers.map(
                                                    attachment => {
                                                        return (
                                                            <div className="attach-btn">
                                                                <button
                                                                    className='pdf-btn'
                                                                    onClick={() => handleOpenImage(attachment?.filePath)}
                                                                >
                                                                    <img src={file} alt="" className="d-file" />
                                                                    {attachment?.fileName}
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                )
                                            }

                                            <ImageList
                                                sx={{
                                                    width: 400,
                                                    height: 400,
                                                }}
                                                cols={3}
                                                rowHeight={150}
                                            >
                                                {/* for iterating image files */}
                                                {
                                                    attachmentsImages.map(
                                                        (attachment, index) => {
                                                            return (
                                                                <ImageListItem
                                                                    key={index}
                                                                    sx={{
                                                                        position: 'relative',
                                                                        borderRadius: '10px',
                                                                        mx: 1,
                                                                    }}
                                                                    onClick={() => handleOpenImage(attachment?.filePath)}
                                                                >
                                                                    <PhotoCameraIcon
                                                                        sx={{
                                                                            color: '#2F77B6',
                                                                            position: 'absolute',
                                                                            top: 'calc(50% - 10px)',
                                                                            left: 'calc(50% - 10px)',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={attachment?.filePath}
                                                                        loading="lazy"
                                                                    />
                                                                </ImageListItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </ImageList>
                                        </div>

                                        {/* <div className="viewdicom"> */}
                                            {/* <DicomModal /> */}
                                            {/* <button className="print-btn"> <img src={print} alt="" className="d-file" />  Print Images </button> */}
                                        {/* </div> */}
                                        <div className="viewdicom">
                                                <DicomModal study_path={appointmentReport?.study_path} />
                                                {/* <button className="print-btn"> <img src={print} alt="" className="d-file" />  Print Images </button> */}
                                            </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Grid>
                </StyledBox>
            </Modal>
        </div>
    )
}

const StyledBox = styled(Box)(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: "100%",
        maxHeight: 600,
        overflow: "hidden",
        borderRadius: "1rem",
        bgcolor: 'white',
        display: "block",
        backgroundColor: 'white',
        overflowY: "auto",
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    })
)
export default AppointmentView
