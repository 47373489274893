// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
import './test.css';
import testimg from '../../../assets/images/Group -9.png';
import AOS from "aos";
import { generateImageURL } from '../../../utils';
import useGlobal from '../../../hooks/useGlobal';

const Testimonial = ({
    Monials
}) => {
    const { tKey: [tKey] } = useGlobal();

    AOS.init({
        offset: 100,
        duration: 1300,
        easing: "ease-in-sine",
        delay: 100,
    });

    return (
        <div className='testimonial_container'>
            <h2 data-aos="fade-down">{Monials?.title?.[tKey]}</h2>
            <div className="testcard" data-aos="fade-right">

                {
                    Array.isArray(Monials?.content)
                    && Monials.content.length > 0
                    && Monials.content.map(
                        (monial, index) => {
                            return (
                                <figure className="testimonial_card" key={`testimonials-${index}`}>
                                    <blockquote className='block-1'>{monial?.description?.[tKey]}</blockquote>
                                    <div className="arrow-1">
                                    </div>
                                    <div className="author">
                                        <img
                                            src={monial?.image ? generateImageURL(monial?.image) : testimg}
                                            // src={testimg}
                                            alt="sq-sample3"
                                        />
                                        <div >
                                            <h5>{monial?.title?.[tKey]} <br /> </h5> <span> {monial?.subTitle?.[tKey]} </span>
                                        </div>
                                    </div>
                                </figure>

                            )
                        }
                    )
                }

            </div>

        </div>
    )
}

export default Testimonial