import React from 'react'

import Navbar from '../../layout/Navbar/Navbar'
import ChatContainer from './chatContainer';
import verifiedimg from '../../assets/images/Group 5276.png'
import starimg from '../../assets/images/Group 5279.png'
import { useLocation } from 'react-router-dom';
import { getCaller } from '../../services/api';
const getTwilioCredentials = async () => {
  
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/video/token`, {
    method: "POST",
    body: JSON.stringify({
      identity: 'Tester',
      room: "8888",
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
  });
  const data = await res.json();

  return data;
}


class UserVideoCalling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: null
    }
  }
  componentDidMount = async () => {
    const data = await getTwilioCredentials();
    this.setState({ credentials: data });
  }
 
  render() {
    return (
      <>
        <Navbar></Navbar>
       <div className='video-main-container'>
        <div className="video-doctor-details-head">
          <div className=""> 
          <div className="verified-img-container">
          <img src={verifiedimg} alt="" /> 
          <img src={starimg} alt="" />
            </div> 
            <div className="">
              <h3 className='video-heading'>Dr. Saad Azim <span className='span-head'> Interventional Radiology, Body Imaging </span> </h3>
            </div>
             </div>
             <div className="video-btn-container">
              <button type='button' className='view-btn-video'  > View Appointment  </button>
              <button type='button' className='view-btn-video'> View Doctor Profile </button>
              <button type='button' className='end-video-btn'> End Call </button>
             </div>
        </div>
       <div className='flex'>
          <div className='video-container'>
            Calling
          </div>
          <div className='chat-container'>
            {this.state.credentials !== null && <ChatContainer credentials={this.state.credentials}></ChatContainer>}
          </div>
        </div>
       </div>
      </>
    )
  }
}




export default UserVideoCalling