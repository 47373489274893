import React, { useEffect, useState } from "react";
import user from "../../../assets/images/usermeet.png";
import e from "../../../assets/images/varlogo.svg";
import cross from "../../../assets/images/x-circle-1.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ReshecduleModal from "../../../components/modal/ReshecduleModal";
import { LayoutTwo } from "../../../layout/Layout";
import { useTranslation } from "react-i18next";
import { FaAngleLeft } from "react-icons/fa";
import "../AppointmentRecordView/view.css";
import { dateFormatWW, dateFormatWW_TIME, destructureSlot, formatFromToTimeSlot, formatTime, showDob } from "../../../utils";
import { getCaller, updateCaller, postCaller } from "../../../services/api";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { ImageList, ImageListItem } from "@mui/material";
import DicomModal from "../../../components/modal/DicomModal";
import { appointmentStatus, constants, extensions } from "../../../utils/constants";
import file from '../../../assets/images/file.png';
import useGlobal from "../../../hooks/useGlobal";
import Pdf from "react-to-pdf";
import down from "../../../assets/images/Group 5853.png"
const ref = React.createRef();


const DoctorUpcomingReportView = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appointmentId } = useParams();
    const { tKey: [tKey] } = useGlobal();

    const handleOpenImage = path => window.open(path, '_blank')


    const [DoctorupReport, setDoctorReport] = useState({}) //state?.doctorUpData ?? "";
    const [appointmentReport, setAppointmentReport] = useState([]);

    const [attachmentsImages, setAttachmentsImages] = useState([]);
    const [attachmentsOthers, setAttachmentsOthers] = useState([])

    const { start, end } = destructureSlot(DoctorupReport)?.slot;


    console.log(DoctorupReport);
    const backHandle = (e) => {
        e.preventDefault();
        navigate("/dashboard");
        localStorage.setItem("index", "1");
    };

    const options = {
        orientation: "landscape",
        unit: "in"
    };

    const handleVideoDoctor = async (event) => {
        if(DoctorupReport?.status === appointmentStatus['Completed']){
            return alert('Appointment has already completed')
        }
        const payload = {
            identity: "doctor",
            room: DoctorupReport.roomId,
        }

        const dataRes = await postCaller('video/token', payload)
//        return console.log("DATARESPONSE>>>>>>>>>" ,dataRes)
        navigate("/newVideo", {
            state: {
                data: dataRes?.data,
                commonData: DoctorupReport,
            },
        });
    };

    const fetchAppointmentDetails = async () => {
        const _response = await getCaller(`getOneAppointment/${appointmentId}`)
        console.log("🚀 ~ file: UserUpcomingViewReport.jsx ~ line 143 ~ fetchAppointmentDetails ~ _response", _response)
        setDoctorReport(_response?.data)
    }

    const fetchAttachments = async () => {
        try {
            const response = await getCaller(`getOneFile/${appointmentId}`)

            /* if found any report result in appointment */
            if (response?.result) {
                setAppointmentReport(response?.result || {})
            }
            let images = [];
            let others = [];
            Array.isArray(response?.data) && response.data.forEach(
                attachment => {
                    const extension = attachment?.fileName?.split('.').pop();
                    extensions.IMAGES.includes(extension) ? images.push(attachment) : others.push(attachment)
                }
            )
            setAttachmentsImages(images)
            setAttachmentsOthers(others)
        }
        catch (error) {
            console.error(error);
        }
    }

    const _showDate = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            console.log('slot?.date', slot?.date)
            if (slot) {
                return dateFormatWW(slot?.date)
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const _formatTime = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return `${formatTime(slot?.time?.start)} - ${formatTime(slot?.time?.end)}`
            } else {
                return ""
            }
        } else {
            return ""
        }
    }


    useEffect(() => {
        fetchAppointmentDetails()
        fetchAttachments()
    }, [])

    return (
        <>
            <LayoutTwo>
                <div className="view-container-1">
                    {/* <div className="overlay"> </div> */}

                    <div className="view">
                        <button onClick={backHandle} className="back-btn">
                            {" "}
                            <FaAngleLeft /> Back{" "}
                        </button>
                        <div className="view-meet">
                            <div className="view-meet-content">
                                <div className="view-user-meet">
                                    <img src={user} alt="" />
                                    <div className="user-meet-content">
                                        {" "}
                                        <h4> Attend the Patient </h4>
                                        <p>
                                            Click on the joining info link at the time of your
                                            appointment and meet the doctor
                                        </p>
                                    </div>
                                </div>
                                <div className="meet-btn">
                                    <button onClick={handleVideoDoctor}>
                                        {t("clickToMeet")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="down-view-content" ref={ref}>
                            <div className="view-logo">
                                <img src={e} alt="" />
                            </div>
                            <div className="download-view-content">
                                <div className=""></div>
                                <div className="virtual-1">
                                    <h3>WET REPORT</h3>
                                </div>
                                <div className="download-view-content-1">
                                    <Pdf
                                        targetRef={ref}
                                        filename="VirtualClinicReport"
                                        options={options}
                                        x={0.1}
                                        y={0.5}
                                        scale={0.75}
                                    >
                                        {({ toPdf }) => (
                                            <img
                                                src={down}
                                                alt=""
                                                className="down-img-btn"
                                                onClick={toPdf}
                                            />
                                        )}
                                    </Pdf>
                                </div>
                            </div>

                            {/* appointment Details */}
                            <div className="view-app-detail">
                                <h3>Appointment Details</h3>
                                <div className="common-card">
                                    <div className="common-body">
                                        <p> Appointment ID </p>
                                        <h4> {DoctorupReport?.appointmentId ?? "N/a"} </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Appointment Date & Time </p>
                                        <h4>
                                            {dateFormatWW(DoctorupReport?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                                            {/* {_showDate(DoctorupReport)} - {_formatTime(DoctorupReport)} */}
                                        </h4>
                                    </div>
                                    <div className="common-body1">
                                        <p> Booking Date </p>
                                        <h4> {dateFormatWW_TIME(DoctorupReport?.createdAt)}   </h4>
                                    </div>
                                    {/* <div className="common-body1">
                                    <p> Appointment Status </p>
                                    <h4 style={{ color: "#43AFE9" }}>
                                        {" "}
                                        {DoctorupReport?.status?.toUpperCase() ?? "N/a"}{" "}
                                    </h4>
                                </div> */}
                                </div>
                                <div className="view-patient-deatail">
                                    <h3>Patient Details</h3>
                                    <div className="view-doc-detail">
                                        <div className="doc-content">
                                            <div className="common-card">
                                                <div className="common-body">
                                                    <p> Patient ID </p>
                                                    <h4>{DoctorupReport?.user_profile?.loginId ?? "n/a"} </h4>
                                                </div>
                                                <div className="common-body1">
                                                    <p> Patient Name </p>
                                                    <h4> {DoctorupReport?.patientName ?? "n/a"} </h4>
                                                </div>
                                                <div className="common-body1">
                                                    <p> Gender </p>
                                                    <h4>{DoctorupReport?.gender ?? "n/a"} </h4>
                                                </div>
                                                <div className="common-body1">
                                                    <p> Age </p>
                                                    <h4> {DoctorupReport?.age ?? "n/a"} </h4>
                                                </div>
                                            </div>
                                            {/* <div style={{ marginTop: "1rem" }}>
                                            <p>Specialisation Chosen</p>
                                            <span> {DoctorupReport?.serviceName ?? "n/a"} </span>
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>
                                            <p>Problem description</p>
                                            <span>{DoctorupReport?.description}</span>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="view-patient-deatail">
                                    <h3>Doctor Details</h3>
                                    <div className="common-card">
                                        <div className="common-body">
                                            <p>Doctor Name & Designation </p>
                                            <h4 className='text-capitalize'> {DoctorupReport?.doctor_profile?.name}, {DoctorupReport?.service?.name?.[tKey]} </h4>
                                        </div>
                                        <div className="common-body1">
                                            <p> Study Type </p>
                                            <h4 className='text-capitalize'> {appointmentReport?.studyType ?? "n/a"} </h4>
                                        </div>
                                        <div className="common-body1">
                                            <p> Speciality </p>
                                            <h4 className='text-capitalize'> {DoctorupReport?.serviceName?.[tKey] ?? "n/a"} </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="download-clinic">
                                    <h4 className="view-heading">Clinical Data</h4>
                                    <p> {appointmentReport?.history ?? "n/a"} </p>
                                </div>
                                <div className="download-clinic">
                                    <h4 className="view-heading">Brief Summary</h4>
                                    <p> {appointmentReport?.briefSummary ?? "n/a"} </p>
                                </div>
                                <div className="download-clinic">
                                    <div className="down-rec">
                                        <h4>Recommendation</h4>
                                        <p> {appointmentReport?.recommendation ?? "n/a"} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-patient-deatail">
                            <h4>Other details</h4>
                            <div className="view-doc-detail">
                                <div
                                    className="down-details-border2"
                                    style={{ width: '50%' }}
                                >
                                    <div
                                        className="common-card"
                                        style={{
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <div className="common-body">
                                            <p>Specialisation Chosen</p>
                                            <h4 className='text-capitalize'>
                                                {`${(DoctorupReport?.service?.service_master?.name?.[tKey])}` ?? "n/a"}
                                            </h4>
                                        </div>
                                        <div
                                            className="common-body1"
                                            style={{
                                                marginTop: '1rem',
                                                margin: 'unset'
                                            }}
                                        >
                                            <p>Problem Description</p>
                                            <h4 className='text-capitalize'>
                                                {DoctorupReport?.description ?? "n/a"}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="view-attach">
                                    <div style={{ marginLeft: "1rem" }} >
                                        <p>Attachments</p>
                                        {/* for iterating non images files */}
                                        {
                                            attachmentsOthers.map(
                                                attachment => {
                                                    return (
                                                        <div className="attach-btn">
                                                            <button
                                                                className='pdf-btn'
                                                                onClick={() => handleOpenImage(attachment?.filePath)}
                                                            >
                                                                <img src={file} alt="" className="d-file" />
                                                                {attachment?.fileName}
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }

                                        <ImageList
                                            sx={{
                                                width: 400,
                                                height: 400,
                                            }}
                                            cols={3}
                                            rowHeight={150}
                                        >
                                            {/* for iterating image files */}
                                            {
                                                attachmentsImages.map(
                                                    (attachment, index) => {
                                                        return (
                                                            <ImageListItem
                                                                key={index}
                                                                sx={{
                                                                    position: 'relative',
                                                                    borderRadius: '10px',
                                                                    mx: 1,
                                                                }}
                                                                onClick={() => handleOpenImage(attachment?.filePath)}
                                                            >
                                                                <PhotoCameraIcon
                                                                    sx={{
                                                                        color: '#2F77B6',
                                                                        position: 'absolute',
                                                                        top: 'calc(50% - 10px)',
                                                                        left: 'calc(50% - 10px)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                />
                                                                <img
                                                                    src={attachment?.filePath}
                                                                    loading="lazy"
                                                                />
                                                            </ImageListItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </ImageList>
                                    </div>

                                    <div className="viewdicom">
                                        <DicomModal />
                                        {/* <button className="print-btn"> <img src={print} alt="" className="d-file" />  Print Images </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutTwo>
        </>
    );
};

export default DoctorUpcomingReportView;
