// import React, { useState, useEffect } from 'react'
import * as Yup from 'yup';
import '../pages/loginregister.css'
import { useFormik } from 'formik';
import Navbar from '../layout/Navbar/Navbar'
import { useTranslation } from 'react-i18next';
import two from '../assets/images/login_vector.png'
import GlobalContext from '../context/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getCaller, loginUser, postCaller } from '../services/api';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { requestForToken, onMessageListener } from '../firebaseNotifications/firebase';
import useGlobal from '../hooks/useGlobal';
import { generateImageURL } from '../utils';
import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { ThemeButton } from '../components/common/Button';

/* ICONS */
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const loginSchema = Yup.object().shape({
    mobile: Yup.string().min(9, 'Incorrect mobile number').required('Mobile number is required'),
    password: Yup.string().min(6, 'Password is too small').max(20, 'Password is too large').required('Password is required'),
    countryCode: Yup.string().required('Country Code is required')
});

const initialValues = {
    mobile: '',
    password: '',
    fcmToken: '',
    countryCode: '+91'
}

const Login = () => {
    const { t } = useTranslation();
    const { userDetails, userProfile, socket } = useGlobal();

    const [, setUSD] = userDetails;
    const [, setUProf] = userProfile;

    const navigate = useNavigate();
    const [forgotLoader /* will use it later for disabling forgot password text */, setForgotLoader] = useState(false);
    const [eyeVisibility, setEyeVisibility] = useState(false);
    const passwordRef = useRef(null);



    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            let res = await loginUser(values);
            if (res.Token) {
                localStorage.setItem('index', "0")
                localStorage.setItem('type', res.type)
                localStorage.setItem('token', res.Token)
                try {
                    const response = await getCaller('profile/details');
                    socket.emit('manualConnection', response?.data);
                    setUProf(generateImageURL(response?.data?.url))
                    setUSD(response?.data || {})
                } catch {
                    setUSD({})
                }
                return navigate('/');
            }

            toast.info(res.errorMsg, {
                position: "top-right",
                containerId: 'info',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
    })

    const { errors, handleChange, values, setFieldValue, touched, handleBlur, setFieldTouched } = formik;


    const redirectIfValid = async () => {
        requestForToken().then(token => setFieldValue('fcmToken', token)).catch(error => console.error("🚀 ~ file: Login.jsx:99 ~ redirectIfValid ~ error", error)) /* handling FCM token */

        if (localStorage.getItem('token')) {
            const response = await getCaller('profile/details');
            if (
                (response?.result === 'invalid Token')
                || (response?.errorMsg === 'You are not authorized')
            ) {
                return;
            }
            navigate('/');
        }
    }


    const toggleEyeIcon = () => {
        setEyeVisibility(!eyeVisibility);
        passwordRef.current.type = eyeVisibility ? 'password' : 'text';
    }

    const handleForgotPassword = async () => {
        if (formik.errors.mobile) {
            setFieldTouched('mobile', true)
            return;
        }
        try {
            setForgotLoader(true);
            const response = await postCaller('forgot-password', { mobile: values.mobile, countryCode: values.countryCode })
            if (response?.error === false) {
                // window.open(response?.data?.url, "_blank");
                formik.resetForm()
                return toast.success(response?.msg);
            }
            return toast.info(response?.msg);
        }
        catch (error) {
            console.error("🚀 ~ file: Login.jsx:76 ~ handleForgotPassword ~ error", error)
        }
        finally { setForgotLoader(false) }

    }

    console.log('ERRORS>>>>>>>>>>>>>>>>', errors)
    console.log('VALUES>>>>>>>>>>>>>>>>', values)
    console.log('TOUCHED>>>>>>>>>>>>>>>>', touched)

    useEffect(() => {
        /* requestForToken(); */
        redirectIfValid();
    }, [])

    return (
        <>
            <Navbar></Navbar>
            <div className='register'>
                <div className="register-container">
                    <div className='register-image-contain'>
                        <img src={two} alt="" />
                    </div>
                    <div className="register-form">
                        <h2> {t('login')} </h2>
                        <form className='form-c' onSubmit={formik.handleSubmit}>
                            <div style={{ width: '70%' }}>
                                <div className='register-input-field'>
                                    <TextField
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        size="medium"
                                        placeholder={t('enterMobile')}
                                        name="mobile"
                                        value={formik.values.mobile}
                                        onBlur={handleBlur}
                                        onChange={formik.handleChange}

                                        error={Boolean(touched.mobile && errors.mobile)}
                                        helperText={touched.mobile && errors.mobile}

                                        InputProps={{
                                            sx: { pl: 0 },
                                            startAdornment: (
                                                <InputAdornment>
                                                    <Select
                                                        sx={{ mr: 1, pl: 0 }}
                                                        name='countryCode'
                                                        // error={Boolean(formik.errors?.countryCode)}
                                                        size='medium'
                                                        variant='outlined'
                                                        style={{ width: '100%' }}
                                                        value={formik.values.countryCode}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <MenuItem value="+91">IN - +91</MenuItem>
                                                        <MenuItem value="+966">AR - +966</MenuItem>
                                                    </Select>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {/* 
                                    <input type="text" name="mobile" placeholder={t('enterMobile')} onChange={formik.handleChange}
                                        value={formik.values.mobile}
                                    /> */}
                                    {/* <div className='error'>{formik.errors.mobile ? formik.errors.mobile : null}</div> */}
                                    <div className='register-input-field'>
                                        <TextField
                                            style={{ width: "100%" }}
                                            size="medium"
                                            type="password"
                                            name="password"
                                            className=''
                                            placeholder={t('enterPassword')}
                                            onBlur={handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            inputRef={passwordRef}

                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}

                                            InputProps={{
                                                endAdornment: (
                                                    <div className='' onClick={toggleEyeIcon}>
                                                        {eyeVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                    {/* <div className='error'>{formik.errors.password ? formik.errors.password : null}</div> */}
                                </div>
                                <div className='btn-container'>
                                    {/* <button className='submit-btn' type="submit" >{t('submit')} <i className="fa-solid fa-chevron-right"></i> </button> */}
                                    <div style={{
                                        borderRadius: '1.5rem',
                                        width: '70%',
                                        height: '3rem'
                                    }}>
                                        <ThemeButton
                                            type={'submit'}
                                            text={t('submit')}
                                        />
                                    </div>
                                </div>
                            </div>

                        </form>
                        <div
                            className='forgot-password'
                            onClick={handleForgotPassword}
                        // disabled
                        >
                            <div className='not-selectable pointer'>
                                Forgot Password ?
                            </div>
                        </div>

                        <div className='register-para'>
                            <p> {t('newToVar')} <Link to="/register" > {t('register')} </Link> </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login