import moment from "moment";
import { appointmentStatus, constants } from "./constants";

const API_BASE_URL = process.env.REACT_APP_BASE_URL

export function showDob(date) {
    if (date) {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let data = date.split("-")
        data = new Date(data[0], data[1], data[2]);
        date = `${data.getDate()} ${month[data.getMonth() - 1]} ${data.getFullYear()}`
        return date;
    } else {
        return;
    }
}

export function formatTime(time) {
    if (!time) return time;
    return moment(time, "hh:mm:ss").format('HH:mm A')
}

export function getDiscountedAmount(amount, discount) {
    let discountedAmount = 0;
    if (!discount) {
        return discountedAmount = amount
    } else {
        discountedAmount = parseFloat(amount) - ((parseFloat(amount) * parseFloat(discount)) / 100);
        console.log(discountedAmount)
        return discountedAmount
    }
}



export function parseTime(s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
}

function convertHours(_mins) {
    var hour = Math.floor(_mins / 60);
    var mins = _mins % 60;
    var converted = pad(hour, 2) + ':' + pad(mins, 2);
    return converted;
}

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

export function calculate_time_slot(start_time, end_time, interval = "30") {
    var i, formatted_time;
    var time_slots = [];
    for (i = start_time; i <= end_time; i = i + interval) {
        formatted_time = convertHours(i);
        time_slots.push(formatted_time);
    }
    return time_slots;
}

export function convertTime12To24(time12h) {
    console.log(time12h)
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
}

/* Common function to use for removing special characters from a string using replace*/
const removeSplCharsForNumbers = (str) => {
    return str.replace(/[^0-9.]/gi, '');
}

/* Custom made getOnlyNumber function which takes string and returns String */
export const getOnlyNumber = (oldVal, _val, maxVal = 1000000, fixedValue = 2) => {
    let val = _val;

    if (isNaN(val)) {
        return oldVal
    }
    if (val > maxVal) {
        return oldVal;
    }

    let parsedString = removeSplCharsForNumbers(val);

    /* If parsed string is in float */
    if (parsedString.includes('.')) {
        /* splitting parsedString with and slicing last index with fixed value */
        let splited = parsedString.split('.');
        if (splited.length === 2) {
            splited[splited.length - 1] = splited[splited.length - 1].slice(0, fixedValue);
        }

        /* then joining back to parsedString */
        parsedString = splited.join('.')
    }

    return parsedString;
}


export const coreChatWindow = _url => {
    return window.open(
        _url,
        'popUpWindow',
        'height=500,width=400,left=100,top=100,resizable=0,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no'
    )
}

export const calculateAgeFromDate = date => {
    return moment().diff(moment(date, constants.DATABASE_DATE_FORMAT), 'years');
}

export const checkAvailability = (formate) => {
    console.log('formate :>> ', formate);
    const availFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/bmp", "image/svg", "image/svg+xml", "application/pdf"];
    if (!availFileTypes.includes(formate)) {
        return false;
    }
    return true;
}

export const destructureSlot = appointment => {
    const dummy = {
        "date": "",
        "slot": {
            "end": "",
            "start": "",
        }
    }
    if (!appointment) return undefined;

    if (Array.isArray(appointment.slot)) {
        const [slot] = appointment.slot;
        return slot?.slotDetails || dummy;
    } else {
        return dummy;
    }
}


export const getFormData = object => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export const split_by_new_line = str => {
    try {
        /* some validations */
        if (!str) return [];
        const _str = str.toString();

        // if (_str.trim() === '') return str;

        return _str.split('\n').filter(i => i.trim() !== '') /* filter is for empty strings */
    } catch {
        return [];
    }
}

export const generateQuery = (URL, options) => {
    let keys = Object.keys(options);
    keys.length > 0 && keys.forEach(e => {
        if (options[e]) {
            URL += `&${e}=${options[e]}`
        }
    })
    return URL;
}

export const appointmentStatusColor = status => {
    switch (status) {
        case appointmentStatus['inReview']:
            return '#F28519';
        case appointmentStatus['Upcoming']:
            return '#3A9CD8';
        case appointmentStatus['Completed']:
            return '#16CB0E';
        default:
            return '#000000';
    }
}

export const restrictDICOMExtentions = ext => {
    const availFileTypes = ["png", "jpg", "jpeg", "gif", "bmp", "svg", "svg+xml", "pdf", "dll", "exe", "rtf"];
    if (!availFileTypes.includes(ext)) {
        return false;
    }
    return true;
}

export const extractFileExtension = name => name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const popupChatURL = roomId => (`/window/popup/${roomId}`);
export const dateFormat = date => moment(date).format(constants.USER_DATE_FORMAT);
export const generateImageURL = key => (API_BASE_URL + '/common/awsObject?key=' + key);
export const dateFormatWW = date => moment(date).format(constants.USER_DATE_FORMAT_WW); /* WW is for With Words */
export const dateFormatWW_TIME = date => moment(date).format(constants.USER_DATE_FORMAT_WW_TIME); /* WW_TIME is for With Words and TIME  */
export const formatTimeSlot = (time, prepand = true) => moment(time, 'HH:mm:ss').format(`HH:mm ${prepand ? 'A' : ''}`);
export const formatFromToTimeSlot = (start, end, seperator = 'to', prepand = true) => `${formatTimeSlot(start, prepand)} ${seperator} ${formatTimeSlot(end, prepand)}`;
