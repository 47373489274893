import { styled } from "@mui/material/styles";
import React, { useRef, useState, useEffect } from 'react'
import { appointmentStatusColor, dateFormatWW, destructureSlot, formatFromToTimeSlot } from '../../../utils';
import { appointmentStatus, constants } from '../../../utils/constants';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import eye from "../../../assets/images/eye.png";
import check from "../../../assets/images/check-circle.png";
import cross from "../../../assets/images/x-circle-1.png";
import reshc from "../../../assets/images/Group 5664.png";
import CustomPagination from '../../../components/CustomPagination';
import useMasters from "../../../hooks/userMasters";
import AppointmentFilterModal from "../../UserDashboard/userappointment/AppointmentFilter";
import useGlobal from "../../../hooks/useGlobal";


const DoctorAppointmentTable = ({
    type,
    appointments,
    refreshData,

    /* for handing inreview appointment */
    handleViewReview,
    handleViewUpcoming,
    handleViewPast,
    /* for handling accept appointment and open wet report */
    handleAccept,
    handleReject
}) => {
    const { appointment } = useMasters();
    const { tKey: [tKey] } = useGlobal();

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [modal, setModal] = useState(false);

    const searchTextFieldRef = useRef(null);
    const maximo = appointments?.count / 5;

    const handleModalOpen = () => setModal(true)
    const handleModalClose = () => setModal(false)


    const formateTimeSlotAndDate = e => {
        if (Array.isArray(e.slot)) {
            const [_slot] = e.slot;
            const { start, end } = _slot.time;
            return `${dateFormatWW(_slot.date)}, ${formatFromToTimeSlot(start, end, '-')}`;
        } else {
            return constants.DOTS
        }
    }

    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        const payload = {
            ...query,
            searchText: value,
            page: 1
        }
        setQuery(payload)
        refreshData(payload)
    }

    const nextData = async page => {
        const payload = {
            ...query,
            page
        }
        refreshData(payload)
    }

    const DecideActions = (currentAppointment) => {
        switch (type) {
            case appointmentStatus['inReview']:
                return (
                    <>
                        <img
                            src={eye}
                            alt="View"
                            onClick={() => handleViewReview(currentAppointment)}
                        />
                        <img
                            src={check}
                            alt="Accept"
                            onClick={() => handleAccept(currentAppointment)}
                        />
                        <img
                            src={cross}
                            alt="Reject"
                            onClick={() => handleReject(currentAppointment)}
                        />
                        {/* <img
                            src={reshc}
                            alt="Reschedule"
                        /> */}
                    </>
                )

            case appointmentStatus['Upcoming']:
                return (
                    <>
                        <img
                            src={eye}
                            alt="View"
                            onClick={() => handleViewUpcoming(currentAppointment)}
                        />
                        {/* <img
                            src={reshc}
                            alt="Reschedule"
                        /> */}
                    </>
                )

            case appointmentStatus['Completed']:
                return (
                    <>
                        <img
                            src={eye}
                            alt="View"
                            onClick={() => handleViewPast(currentAppointment)}
                        />
                    </>
                )
        }
    }


    useEffect(() => {
        nextData(page)
    }, [page])

    return (
        <>
            {/* Filter Modal */}
            <AppointmentFilterModal
                open={modal}
                handleClose={handleModalClose}
                refreshData={refreshData}
                setQuery={setQuery}
            />

            <div className="filter-field">
                <p> {appointments?.count} items </p>
                <div className="filter-search">
                    <div className="user-past-card">
                        <input
                            type="search"
                            id="search-box"
                            placeholder="Search "
                            ref={searchTextFieldRef}
                        />
                        <label
                            htmlFor="search-box"
                            className="fas fa-search"
                            onClick={handleTextFieldButton}
                        ></label>
                    </div>
                    <div className="filter-button">
                        <button onClick={handleModalOpen}> Filter </button>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell> Appointment ID </StyledTableCell>
                            <StyledTableCell> Appointment Date & Time </StyledTableCell>
                            <StyledTableCell> Specialisation Chosen </StyledTableCell>
                            <StyledTableCell> Patient Name </StyledTableCell>
                            <StyledTableCell> Gender, Age </StyledTableCell>
                            <StyledTableCell> Status </StyledTableCell>
                            <StyledTableCell> Action </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            appointments?.rows?.length > 0
                                ? appointments?.rows?.map(
                                    (e, i) => {
                                        const { start, end } = destructureSlot(e)?.slot;
                                        return (
                                            <>
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row">
                                                        {e?.appointmentId ?? constants.DOTS}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {dateFormatWW(e?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {" "}
                                                        {e?.service?.service_master?.name?.[tKey] ?? constants.DOTS}{" "}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {" "}
                                                        {e?.patientName ?? constants.DOTS}{" "}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {" "}
                                                        {e?.gender ?? constants.DOTS} , {e?.age ?? constants.DOTS}{" "}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        style={{
                                                            color: appointmentStatusColor(e?.status),
                                                            fontWeight: "bold",
                                                            textTransform: 'uppercase'
                                                        }}
                                                    >
                                                        {/* {appointmentStatus[e?.status]?.UI} */}
                                                        {appointment[e?.status] && appointment[e?.status].UI}
                                                    </StyledTableCell>
                                                    <StyledTableCell className="table-edit-one">
                                                        {/* <DecideActions /> */}
                                                        {DecideActions(e)}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        )
                                    }
                                ) : (
                                    <>
                                        <StyledTableCell>
                                            No {type} appointments found
                                        </StyledTableCell>
                                    </>
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomPagination page={page} setPage={setPage} maximo={maximo} />
        </>
    )
}

const StyledTableRow = styled(TableRow)(
    ({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    })
);

const StyledTableCell = styled(TableCell)(
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#D5F1FF",
            color: "#707070",
            border: 0,
            fontSize: 12,
            fontWeight: 600,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            border: 0,
            fontWeight: 600,
            color: "#1A1919",
            textTransform: "capitalize",
            letterSpacing: 'unset',
        },
    }
);

export default DoctorAppointmentTable

/* 

async () => {
                                                                const res = await getCaller(
                                                                    `getOneFile/${e.appointmentId}`
                                                                );
                                                                console.log(res);
                                                                navigate("/doctorReviewReport", {
                                                                    state: {
                                                                        doctorFileReview: res,
                                                                        doctorReviewData: e,
                                                                    },
                                                                });
                                                            }
*/