import React, { useState, useRef, useEffect } from "react";
import {useLocation} from 'react-router-dom';
import Navbar from "../../layout/Navbar/Navbar";
import { postCaller } from '../../services/api';
import { getDiscountedAmount } from '../../utils';
import { s3constant } from './../../utils/constants';


function DicomViewer({route}) {
    // const aaa=`${process.env.REACT_APP_VIEWER_URL}`;

    // console.log("tttt",aaa);
    const [currHeight, setCurrHeight] = useState(617);
    const {state} = useLocation();
    const [baseUrlPath, setBaseUrlPath] = useState(`${process.env.REACT_APP_VIEWER_URL}`);
    console.log(baseUrlPath);

    const [studyPath,setStudyPath]=useState(baseUrlPath+'viewer/dicomjson?url='+s3constant.S3URL+'ohif/'+state.studypath+'/study.json');
// const baseurl=window.location.origin;


    useEffect(() => {
       setCurrHeight(document.documentElement.clientHeight);
    }, [])

    return(<>
     <div className="dicom-container">
       <Navbar />
       
       {/* <iframe src="http://localhost:3000/viewer/dicomjson?url=http://localhost:3000/LIDC-IDRI-0001.json" style={{'width':'100%', 'height':`${currHeight}px`,' border':'0', 'border-radius': '0px', 'overflow':'hidden',paddingTop:'40px'}}/> */}


        {/* <iframe src="http://localhost:3000/viewer/dicomjson?url=http://localhost:3001/study.json" style={{'width':'100%', 'height':`${currHeight}px`,' border':'0', 'borderRadius': '0px', 'overflow':'hidden',paddingTop:'40px'}} />  */}
        <iframe src={studyPath}   style={{'width':'100%', 'height':`${currHeight}px`,' border':'0', 'border-radius': '0px', 'overflow':'hidden',paddingTop:'40px'}}/> 

</div>

    {/* <Footer/> */}
    </>)
}
export default DicomViewer;
