import React from 'react';
import GlobalContext from './GlobalContext';
import socketio from "socket.io-client";
import { URLS } from '../utils/constants';

const socket = socketio.connect(URLS.API_BASE_URL);

const MainState = (props) => {

    /* .IMPORTANT. */
    /* Don't Add states unless you want to use in whole react. */
    const dynamicRoutes = React.useState({});
    const logo = React.useState(null);
    const socials = React.useState({});
    const email = React.useState("");
    const address = React.useState({});
    const notificationsCount = React.useState(0);
    const userDetails = React.useState({});
    const userProfile = React.useState(null);
    const tKey = React.useState('en'); /* translation key */


    return (
        <GlobalContext.Provider value={{
            /* Main user details */
            userDetails: userDetails,

            /* MOST IMPORTANT Dynamic Routes */
            dynamicRoutes: dynamicRoutes,

            /* App logo */
            logo: logo,

            /* Socials */
            socials: socials,

            /* Email */
            email,

            /* Address */
            address,

            /* For handling notificaitons count from any side */
            notificationsCount,

            /* Socket */
            socket: socket,

            /* useProfile */
            userProfile,

            /* translation key */
            tKey
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default MainState
