import React, { useState, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { generateQuery } from "../../../utils";
import { useTranslation } from "react-i18next";
import { getCaller } from "../../../services/api";
import { appointmentStatus } from "../../../utils/constants";
import DoctorAppointmentTable from "./DoctorAppointmentTable";

const DoctorUpcomingAppointment = (props) => {
    const navigate = useNavigate();

    const [appointments, setAppointments] = useState([]);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const fetchUpcomingAppointments = async payload => {
        try {
            let URL = `getAllAppointment?status=${appointmentStatus['Upcoming']}`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setAppointments(response?.data || {})
        } catch (e) {
            setAppointments({})
        }
    }

    const handleViewUpcoming = currentAppointment => {
        navigate(`/doctorUpcomingReport/${currentAppointment?.appointmentId}`)
    }

    useEffect(() => {
        fetchUpcomingAppointments();
    }, [])

    return (
        <>
            <div className="tabel-tab">
                <DoctorAppointmentTable
                    type={appointmentStatus['Upcoming']}
                    appointments={appointments}
                    refreshData={fetchUpcomingAppointments}
                    handleViewUpcoming={handleViewUpcoming}
                />
            </div>
        </>
    );
};

export default DoctorUpcomingAppointment;
