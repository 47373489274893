import React, { useContext, useEffect, useState } from 'react'
import { Modal, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getCaller } from '../../../../services/api';
import { showDob, generateImageURL } from '../../../../utils'
import GlobalContext from '../../../../context/GlobalContext';
import { useTranslation } from 'react-i18next';



function ProfileView({
    openDialog,
    setOpenDialog,
    patientId

}) {
    const handleClose = () => {
        setOpenDialog(false);
    };

    const context = useContext(GlobalContext);
    const [, setUserProfile] = context.userProfile;
    const { t } = useTranslation();

    const [profileView, setProfileView] = useState({});

    const fetchProfileDetails = async () => {
        const response = await getCaller(`public/profile/patient/${patientId}`)
        console.log("🚀 ~ file: ProfileView.jsx:23 ~ fetchProfileDetails ~ response", response)
        setProfileView(response?.data)
    }

    useEffect(() => {
        fetchProfileDetails()
    }, [])

    function capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    return (
        <>
            <Modal
                open={openDialog}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <StyledBox>
                    <Box sx={{ flexGrow: 1, marginTop: 5, paddingBottom: 10 }}>
                        <Grid container>
                            <Grid item xs={14} md={14} className="side-right-content">
                                <div className='side-right-head'>
                                    <h2 style={{
                                        textTransform: 'capitalize'
                                    }}> Patient Profile View</h2>
                                </div>
                                <Grid item xs={12} md={12}>
                                    <div className="patitent-view-profile-modal">
                                        <div className="profile-img">
                                            <img
                                                src={generateImageURL(profileView?.profile?.url ?? "Patitent Profile")}
                                                alt=""
                                                width="175"
                                                style={{ borderRadius: "8px" }}
                                            />

                                            <br />
                                        </div>
                                        <div className="profile-content">
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>{profileView?.profile?.name ?? "Doctor"} </h3>{" "}
                                            
                                            </div>
                                            <h4> { } </h4>
                                            <Box sx={{ flexGrow: 1, marginTop: 2 }}>
                                                <Grid
                                                    container
                                                    spacing={{ xs: 2, md: 3 }}
                                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                                >
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> {t("mobileNumber")} </p>
                                                        <h5>{profileView?.profile?.mobile ?? "Not available"} </h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> {t("emailId")} </p>
                                                        <h5>{profileView?.profile?.email ?? "Not available"} </h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> {t("gender")} </p>
                                                        <h5>{capitalize(profileView?.profile?.gender) ?? "Not available"}</h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> {t("location")} </p>
                                                        <h5> {profileView?.profile?.location ?? "Not available"} </h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> {t("dob")} </p>
                                                        <h5> {`${showDob(profileView?.profile?.dob)}` ?? "Not available"} </h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> {t("age")} </p>
                                                        <h5> {profileView?.profile?.age ?? "Not available"} </h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> About </p>
                                                        <h5>{profileView?.profile?.description ?? "Not available"}</h5>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <p> Health Condition </p>
                                                        <h5>{profileView?.profile?.condition ?? "Not available"}</h5>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Box>

                </StyledBox>
            </Modal>
        </>

    )

}

const StyledBox = styled(Box)(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: "100%",
        maxHeight: 600,
        overflow: "hidden",
        borderRadius: "1rem",
        bgcolor: 'white',
        display: "block",
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    })
)
export default ProfileView