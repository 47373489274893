import '../modal/modal.css'
import '../newVideo/videoChat.css'

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/* Images */
import verifiedimg from '../../assets/images/Group 5276.png'
import { FaAngleLeft } from 'react-icons/fa'
import starimg from '../../assets/images/Group 5279.png'


import Room from './Room';
import NewChat from './NewChat';
import { dateFormatWW } from '../../utils';
import useGlobal from '../../hooks/useGlobal';
import Navbar from '../../layout/Navbar/Navbar';
import { profiles } from '../../utils/constants';

/* Utils */
import { getCaller, postCaller } from '../../services/api';

/* Modals */
import { DoctorEndCallM, DoctorPublicProfileM } from './Modals/Doctor';
import { PatientEndCallM, PatientPublicProfileM } from './Modals/Patient';
import AppointmentView from './Modals/AppointmentView/AppointmentView';


const VideoChatNew = () => {
    const { state } = useLocation()
    const navigate = useNavigate();
    const { userDetails: [userDetails] } = useGlobal();


    /* router states */
    const videoData = state?.data;
    const chatData = state?.commonData;

    const [LAD, setLAD] = useState({}) /* LAD - Last appointment date */

    /* this will active particular sides closes */
    const [forceClosed, setForceClosed] = useState(false);

    /* passing this state at child level for  */
    const [disconnect, setDisconnect] = React.useState(false);

    /* disabling buttons till call gets connected, so user can not cut call even before joining */
    const [callConnected, setCallConnected] = useState(false);

    /* Modals states */
    const [openConfirm, setOpenConfirm] = useState(false);
    const [open, setOpen] = React.useState(false); /* for opening end call modal for patient side */
    const [profileModal, setProfileModal] = useState(false); /* patient profile modal */
    const [doctorViewModal, setDoctorViewModal] = useState(false); /* doctor profile modal */
    const [appointmentModal, setAppointmentModal] = useState(false); /* appointment modal */


    const backHandle = () => window.history.back()
    const handleOpenAppointment = () => setAppointmentModal(true)


    const handleClose = (event, reason) => {
        if (forceClosed) {
            if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
                return;
            }
        }
        setOpen(false)
    };

    const handleOpenProfile = () => {
        if (userDetails?.type === profiles.doctor) {
            setProfileModal(true)
        } else {
            setDoctorViewModal(true)
        }
    }

    const showLastAppointmentDate = async () => {
        const _response = await getCaller(`recent-appointment/${chatData?.doctorId}`)

        if (Array.isArray(_response?.data?.slot)) {
            const [slot] = _response?.data?.slot;
            setLAD(dateFormatWW(slot?.date));
        } else {
            setLAD("")
        }
    }

    const disconnectCall = () => {
        setOpen(true)
        setDisconnect(true);
        setForceClosed(true);
    };

    const handleEndcallDecisions = () => {
        if (userDetails?.type === profiles.doctor) {
            /* opening doctor confirm dialoge box */
            setOpenConfirm(true)

        } else {
            /* navigating patient back to upcoming appointment page */

            if ( /* ask before ending call from patient side */
                window.confirm('Would you like cut the call ?')
            ) {
                navigate(`/userUpcomingReport/${chatData?.appointmentId}`)
            }
        }
    }

    const decideProfileView = () => {
        if (
            userDetails?.type === profiles.doctor
            /* have to show opposite profile modal */
        ) {
            return <PatientPublicProfileM
                openDialog={profileModal}
                setOpenDialog={setProfileModal}
                patientId={chatData?.loginId}
            />
        }
        else {
            return <DoctorPublicProfileM
                openDialog={doctorViewModal}
                setOpenDialog={setDoctorViewModal}
                doctorId={chatData?.doctorId}
            />
        }
    }

    const decideEndCallModal = () => {
        if (userDetails?.type === profiles.user) {
            return <PatientEndCallM
                open={open}
                handleClose={handleClose}
                appointmentData={chatData}
                endCall={disconnectCall}
                isForceClosed={forceClosed}
            />
        } else {
            return <DoctorEndCallM
                endCall={disconnectCall}
                isForceClosed={forceClosed}
                appointmentId={chatData?.appointmentId}
                openConfirm={openConfirm}
                setOpenConfirm={setOpenConfirm}
            />
        }
    }

    const decideOtherSide = () => {
        if (userDetails?.type === profiles.user) {
            return (
                <div className="">
                    <div className="verified-img-container">
                        <img src={verifiedimg} alt="" />
                        <img src={starimg} alt="" />
                    </div>
                    <div className="chat-data-head">
                        <h3 className='video-heading'> {chatData?.doctor_profile?.name}  <span className='span-head'> {chatData?.doctor_profile?.subService?.name} </span> </h3>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="chat-data-head">
                        <h3 className='video-heading'> {chatData?.user_profile?.name ?? "N/A"} </h3>
                        <p className='doctor-video-para'> {chatData?.user_profile?.gender},{chatData?.user_profile?.age} | Last Appointment On: 12 Feb 2022 </p>
                    </div>
                </div>
            )
        }
    }

    /* effects */

    useEffect(() => {
        if (forceClosed === true) {
            if (userDetails?.type === profiles.doctor) {
                setOpenConfirm(true)
            } else {
                setOpen(true)
            }
        }
    }, [forceClosed])

    useEffect(() => {
        showLastAppointmentDate()
    }, [])

    return (
        <>
            {/* Top most components */}
            <Navbar />

            {/* Modals */}
            {decideProfileView()}

            {/* appointment view modal */}
            <AppointmentView
                openAppointment={appointmentModal}
                setOpenAppointment={setAppointmentModal}
                appointmentId={chatData?.appointmentId}
            />

            {/* deciding EndCall button functionallity modal */}
            {decideEndCallModal()}

            {/* Main page */}
            <div className="video-wrapper">
                <button onClick={backHandle} className="back-btn"> <FaAngleLeft /> Back </button>
                <div className='video-main-container'>
                    <div className="video-doctor-details-head">
                        {decideOtherSide()}

                        <div className="video-btn-container">
                            <button
                                type='button'
                                className='view-btn-video'
                                onClick={handleOpenAppointment}
                            >
                                View Appointment
                            </button>

                            <button
                                type='button'
                                className='view-btn-video'
                                onClick={handleOpenProfile}
                            >
                                {
                                    userDetails?.type === profiles.doctor
                                        ? 'View Patient Profile'
                                        : 'View Doctor Profile'
                                }
                            </button>


                            <button
                                type='button'
                                className='end-video-btn'
                                onClick={handleEndcallDecisions}
                                disabled={callConnected === true ? false : true}
                            >
                                End Call
                            </button>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='video-container'>
                            <Room
                                triggerModal={userDetails?.type === profiles.user ? setOpen : setOpenConfirm}
                                roomName={videoData?.roomName}
                                token={videoData.token}
                                disconnect={disconnect}
                                commonProps={{
                                    setCallConnected: setCallConnected,
                                    chatData: chatData,
                                    setForceClosed: setForceClosed,
                                }}
                            />
                        </div>
                        <div className='chat-container'>
                            <NewChat chatData={chatData} userType={state.type} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default VideoChatNew;