import React, { useEffect, useLayoutEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import "../searchdoctors/search.css";
import v from "../../assets/images/Group 5333.png";
import veriuser from "../../assets/images/Group 5276.png";
import phone from "../../assets/images/Group 5219.png";
import cal from "../../assets/images/calendar.svg";
import Layout from "../../layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import rating from "../../assets/images/rating.png";
import s from "../../assets/images/search.png";
import close from "../../assets/images/cross.png";
import { useTranslation } from "react-i18next";
import { formatTimeSlot, generateImageURL, generateQuery, getDiscountedAmount } from "../../utils";
import CustomPagination from "../../components/CustomPagination";
import { getCaller } from "../../services/api";
import { Avatar } from "@mui/material";
import useGlobal from "../../hooks/useGlobal";

const SearchDoctor = () => {
    const { tKey: [tKey] } = useGlobal();
    const navigate = useNavigate();

    const location = useLocation()

    const [inputValue, setInputValue] = useState("");
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState({})
    const maximo = list?.count / 4;
    const { t } = useTranslation();

    const type = localStorage.getItem("type");


    const getDoctorsList = async (payload = {}) => {
        let URL = `allDoctorList?limit=4&lang=${tKey}`;
        URL = generateQuery(URL, payload);

        const response = await getCaller(URL);
        if (response?.data?.rows) {
            setList(response.data)
        }
    };

    const searchDoctor = event => {
        const { value } = event.target;
        if (event.key === 'Enter') {
            setPage(1)
            getDoctorsList({ page: 1, searchText: value })
        }
    }


    const handleSearchDoctorInput = element => setInputValue(element.target.value)

    const initialHandler = () => {
        /* Check if comming from category */
        const state = location.state;
        if (state?.fromCategory === true) {
            setInputValue(state?.catName)
            getDoctorsList({ page: 1, serviceId: state?.serviceId })
        } else if (
            state?.fromSearch === true
        ) {
            setInputValue(state.value)
            getDoctorsList({ page: 1, searchText: state.value })
        }
        else {
            getDoctorsList()
        }
    }

    useEffect(() => {
        list.length > 0 && getDoctorsList({ page });
    }, [page])


    useEffect(() => {
        console.log('calledOnlyOneTime')
        initialHandler()
    }, [])


    return (
        <Layout>
            <div className="search-container">
                <Breadcrumb head="Search Doctors" />
                <h2>{t("searchDoctor")}</h2>
                <div className="searchcard">
                    <input
                        type="text"
                        id="search-box"
                        placeholder={t("searchSpecialitye")}
                        onChange={handleSearchDoctorInput}
                        onKeyDown={searchDoctor}
                        value={inputValue}

                    />
                    {inputValue ? (
                        <img
                            src={close}
                            alt=""
                            className="search-icon"
                            onClick={(event) => {
                                setInputValue("")
                                getDoctorsList({ page: 1 })

                            }}
                        />
                    ) : (
                        <img src={s} alt="" className="search-icon" />
                    )}
                </div>

                <div className="doctorcard">
                    {list && (list?.rows?.length > 0) ? list?.rows?.map((item, index) => {
                        return (
                            <div className="doctor-content" key={`doctor-listing-${index}`}>
                                <div className="doctor-search-img-1">
                                    {/* <img
                                                src={ }
                                                alt=""
                                                className="doctor-img"
                                            /> */}
                                    <Avatar
                                        className="doctor-img"

                                        src={generateImageURL(item?.url)}
                                        alt={item?.name}
                                    />
                                </div>
                                <div className="icon-body">
                                    <div className="icon-user-container">
                                        <div
                                            className="icon-left"
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <img
                                                src={rating}
                                                alt=""
                                                className="star"
                                                style={{
                                                    width: "70px",
                                                    height: "25px",
                                                }}
                                            />
                                            <p
                                                style={{
                                                    position: "absolute",
                                                    bottom: 6,
                                                    left: 30,
                                                    fontWeight: "600",
                                                    fontSize: "0.87rem",
                                                }}
                                            >
                                                {item?.reviews}
                                            </p>
                                        </div>
                                        <div className="icon-right" >
                                            <img style={{ cursor: 'default' }} src={veriuser} alt="" />{" "}
                                        </div>
                                    </div>

                                    <div className="">
                                        <h4> {item?.name?.toUpperCase() || "N/A"} </h4>
                                        <p className="childpara">
                                            {" "}
                                            {item?.subService?.name?.[tKey]} |{" "}
                                            {
                                                Array.isArray(item?.availability)
                                                    && (item?.availability.length > 0)
                                                    ? `${formatTimeSlot(item?.availability[0].start)} - ${formatTimeSlot(item?.availability[0].end)}` : 'Not available today'
                                            }
                                        </p>
                                        <div className="first-sar">
                                            <p>
                                                SAR{" "}
                                                {getDiscountedAmount(
                                                    Array.isArray(item?.service) && item?.service[0]?.fees,
                                                    item?.offer?.["coupons.discount"]
                                                )}
                                            </p>
                                            {item?.offer?.["coupons.discount"] && (
                                                <del className="del">SAR {Array.isArray(item?.service) && item?.service[0]?.fees}</del>
                                            )}
                                            {item?.offer?.["coupons.discount"] && (
                                                <button>
                                                    {item?.offer?.["coupons.discount"]}% Discount
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <div className="button-container">
                                        {type === "doctor" ? (
                                            <div>
                                                <button
                                                    type="button"
                                                    disabled
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    {" "}
                                                    Book Now{" "}
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <button
                                                    type="button"
                                                    className="book"
                                                    onClick={() =>
                                                        navigate(`/bookappointment/${item.loginId}`, {
                                                            state: {
                                                                data: item,
                                                            },
                                                        })
                                                    }
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    {" "}
                                                    <img
                                                        src={cal}
                                                        alt=""
                                                        style={{
                                                            marginRight: "0.5rem",
                                                        }}
                                                    />{" "}
                                                    Book Now{" "}
                                                </button>
                                            </div>
                                        )}

                                        {/* <img src={phone} alt="" className="phone" />
                                                <img src={v} alt="" className="video" /> */}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                        : <>
                            <div
                                style={{
                                    textAlign: "center",
                                    padding: "0.5rem 1rem",
                                }}
                            >No Doctors found</div>
                        </>}
                </div>
                <CustomPagination page={page} setPage={setPage} maximo={maximo} />
            </div>
        </Layout>
    );
};

export default SearchDoctor;
