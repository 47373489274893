import React, { useState } from 'react';
import { motion } from "framer-motion"
import '../banner/banner.css';
import s from '../../../assets/images/search.png'
import find from '../../../assets/images/finddoctor.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import design from '../../../assets/images/design.png';
import close from '../../../assets/images/cross.png';
import "../../../../src/components/animations/animations.css";
import { generateImageURL } from '../../../utils';
import useGlobal from '../../../hooks/useGlobal';
import BasicTabs from './ServiceTabs';

const Banner = props => {
    const { tKey: [tKey] } = useGlobal();

    const {
        /* ALL CONTENT IS DYNAMIC, COMMING FROM PARENT FROM API REQUEST */
        Title,
        SubTitle,
        BackgroundImage,
        Images,
        /* ALL CONTENT IS DYNAMIC, COMMING FROM PARENT FROM API REQUEST */
        changeSlider
    } = props;


    const { t, i18n } = useTranslation();
    const navigate = useNavigate()


    // const handleChange = (event) => {
    //     console.log(event.target.value)

    // }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            navigate('/searchdoctor', {
                state: {
                    fromSearch: true,
                    value: e.target.value
                }
            });
        }
    }


    // const [data, setData] = useState("");
    // const [rotate, setRotate] = useState("");
    // const handleChange = (e) => {
    //     setData(e.target.value)
    // }
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (data === '')
    //         navigate('/', { data })
    //     else {
    //         localStorage.setItem('search', data)
    //         navigate('/searchdoctor', { data })
    //     }
    // }
    return (
        <>
            <div className='banner-container'
            // style={{animation: "up-down 2s ease-in-out infinite alternate-reverse both"}}
            >
                <div className="banner-img">
                    <img src={generateImageURL(BackgroundImage)} alt="" />
                </div>
                <div className="banner">
                    <div className='banner-content'>
                        <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.9 }}>
                            {/* <h2 className='get'>{t('getTheConsultation')}</h2> */}
                            <h2 className='get'>
                                {/* DYNAMIC CONTENT COMING FROM API */}
                                {Title[tKey]}
                                {/* DYNAMIC CONTENT COMING FROM API */}
                            </h2>
                            <p className='para'>
                                {/* DYNAMIC CONTENT COMING FROM API */}
                                {SubTitle[tKey]}
                                {/* DYNAMIC CONTENT COMING FROM API */}
                            </p>
                        </motion.div>
                        <BasicTabs changeSlider={changeSlider} />
                        <motion.div whileHover={{ scale: 1.2 }}>

                            <input
                                className="searchcard"
                                type="text"
                                id="search-box"
                                placeholder={t('searchSpecialitye')}
                                onKeyDown={_handleKeyDown}
                            />


                            {/* <form className="searchcard" onSubmit={(event) => handleSubmit(event)}>
                                <input type="text" id="search-box" placeholder={t('searchSpecialitye')}
                                    value={data}
                                    onChange={e => handleChange(e)}
                                />
                                {
                                    data
                                        ? <img src={close} alt="" className='search-icon' onClick={(event) => setData('')}
                                            width={10} height={22}
                                        /> : <img src={s} alt="" className='search-icon' />
                                }
                            </form> */}
                        </motion.div>

                        <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}>
                            <p className='or'>{t('orChooseFrombelow')}  </p>
                        </motion.div>
                    </div>

                </div>
                <div className='banner-card-container'>
                    {
                        Images.map((image, index) => {
                            return (
                                <motion.div key={`main-banner-${index}`} whileHover={{ scale: 1.2 }}>
                                    <div className="banner-book-card" onClick={() => navigate('/searchdoctor')}>
                                        <div className='banner-book-img'>
                                            <img src={generateImageURL(image.image)} alt="" />
                                            <p>{image.title[tKey]}</p>
                                        </div>
                                    </div>
                                </motion.div>
                            )
                        })
                    }
                </div>

            </div>

            <div className='design-circle'>
                <img src={design} alt="" />
            </div>
        </>
    )
}

export default Banner;