import { getCaller } from "../../services/api"

export const fetchAboutContentRQ = async () => {
    const response = await getCaller('getWebsiteContent?page_type=ABOUT');
    return response ? response : false;
}

export const fetchTeamsRQ = async () => {
    const response = await getCaller('public/teams');
    return (response?.error === false) ? response : false;
}