import React, { useEffect, useState, useRef } from 'react'
import { showDob, formatTime, generateQuery, dateFormat, formatFromToTimeSlot, dateFormatWW, destructureSlot } from "../../../utils";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import './userappointment.css'
import eye from "../../../assets/images/eye.png";
import down from "../../../assets/images/download-4.png";
import Paper from "@mui/material/Paper";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CustomPagination from '../../../components/CustomPagination';
import s from '../../../assets/images/search.png'
import close from '../../../assets/images/cross.png'
import { CSVLink } from 'react-csv';
import { getCaller } from '../../../services/api';
import { appointmentStatus, constants, toast_messages } from '../../../utils/constants';
import { toast } from 'react-toastify';
import AppointmentFilterModal from './AppointmentFilter';
import useGlobal from '../../../hooks/useGlobal';


const PastAppointment = (props) => {
    const { t } = useTranslation();
    const { tKey: [tKey] } = useGlobal();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const searchTextFieldRef = useRef(null);
    const [appointments, setAppointments] = useState({});


    const maximo = appointments?.count / 5;
    const [modal, setModal] = useState(false);


    const handleModalClose = () => setModal(false);
    const handleModalOpen = () => setModal(true);


    // const values = pastData?.map(data => {
    //     let obj = {
    //         "appointmentId": data?.appointmentId,
    //         "appointmentDateTime": data?.slot[0]?.date,
    //         "bookedFor": "Self",
    //         "specialityChosen": data?.serviceName,
    //         "doctorName": data?.doctor_profile?.name,
    //         "status": data?.status,
    //     };
    //     return obj
    // });

    // const csvLink = {
    //     headers: headers,
    //     data: values,
    //     filename: "CompleteAppointments.csv"
    // }

    const fetchPastAppointments = async payload => {
        try {
            let URL = `getAllAppointment?status=${appointmentStatus.Completed}`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setAppointments(response?.data || {})
        } catch (e) {
            setAppointments({})
        }
    }

    const formateTimeSlotAndDate = e => {
        if (Array.isArray(e.slot)) {
            const [_slot] = e.slot;
            const { start, end } = _slot.time;
            return `${dateFormatWW(_slot.date)}, ${formatFromToTimeSlot(start, end, '-')}`;
        } else {
            return constants.DOTS
        }
    }


    const handleViewAppointment = (currentAppointment) => {
        navigate(`/userDownloadReport/${currentAppointment?.appointmentId}`)
    }


    const handleTextFieldButton = () => {
        const { current: { value } } = searchTextFieldRef;
        const payload = {
            ...query,
            searchText: value,
            page: 1
        }
        setQuery(payload)
        fetchPastAppointments(payload)
    }


    const nextData = page => {
        const payload = {
            ...query,
            page
        }
        fetchPastAppointments(payload)
    }


    useEffect(() => {
        nextData(page)
    }, [page])

    useEffect(() => {
        fetchPastAppointments({})
    }, [])

    return (
        <>
            <AppointmentFilterModal
                open={modal}
                handleClose={handleModalClose}
                refreshData={fetchPastAppointments}
                setQuery={setQuery}
            />

            <div className="user-past-content">
                <div className="user-past">
                    <h4> Past Appointments </h4>
                </div>
                <div className="filter-field">
                    <p> {appointments?.count ?? constants.DOTS} items </p>
                    <div className="filter-search">
                        <div className="user-past-card">
                            <input
                                type="input"
                                id="search-box"
                                placeholder="Search "
                                ref={searchTextFieldRef}
                            />
                            <label
                                htmlFor="search-box"
                                className="fas fa-search"
                                onClick={handleTextFieldButton}
                            ></label>
                        </div>
                        <div className="filter-button">
                            <button
                                onClick={handleModalOpen}
                            > Filter </button>
                        </div>
                    </div>
                </div>

                <div className="user-table-past">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell> Appointment ID </StyledTableCell>
                                    <StyledTableCell> Appointment Date & Time </StyledTableCell>
                                    <StyledTableCell> Booked for </StyledTableCell>
                                    <StyledTableCell> Specialisation Chosen </StyledTableCell>
                                    <StyledTableCell> Doctor Name </StyledTableCell>
                                    <StyledTableCell> Status </StyledTableCell>
                                    <StyledTableCell> Action </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {/* <div>
                                {
                                    pastData?.length === 0 && <div style={
                                        {
                                            display: 'flex',
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: "1rem"
                                        }
                                    }> No Data Found </div>
                                }
                            </div> */}
                            <TableBody>
                                {
                                    appointments?.rows?.length > 0
                                        ? appointments?.rows?.map(
                                            (e, i) => {
                                                const { start, end } = destructureSlot(e)?.slot || {};

                                                return (
                                                    <>
                                                        <StyledTableRow>
                                                            <StyledTableCell component="th" scope="row"> {e?.appointmentId ?? "n/a"} </StyledTableCell>
                                                            <StyledTableCell>
                                                                {
                                                                    dateFormatWW(e?.slotDate)
                                                                }
                                                                {' '}
                                                                {
                                                                    formatFromToTimeSlot(start, end, '-')
                                                                }
                                                            </StyledTableCell>
                                                            <StyledTableCell> {e?.bookingFor ?? constants.DOTS} </StyledTableCell>
                                                            <StyledTableCell> {e?.service?.name?.[tKey] ?? "n/a"} </StyledTableCell>
                                                            <StyledTableCell> {e?.doctor_profile?.name ?? "n/a"} </StyledTableCell>
                                                            <StyledTableCell style={{ color: "#16CB0E", fontWeight: "bold" }} > {e?.status.toUpperCase() ?? "n/a"} </StyledTableCell>
                                                            <StyledTableCell className="table-edit">
                                                                <img src={eye} alt="" onClick={() => handleViewAppointment(e)} />
                                                                {/* <CSVLink to="#" {...csvLink}>
                                                                    <img src={down} alt="" />
                                                                </CSVLink> */}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </>
                                                );
                                            }) : <>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    padding: '5rem 1rem',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                No Past  Appointment
                                            </div>
                                        </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <CustomPagination
                    page={page} setPage={setPage} maximo={maximo}
                />
            </div>

        </>
    )
}

const StyledTableRow = styled(TableRow)(
    ({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    })
);

const StyledTableCell = styled(TableCell)(
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#D5F1FF",
            color: "#707070",
            border: 0,
            fontSize: 13,
            fontWeight: 600,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            border: 0,
            fontWeight: 600,
            color: "#1A1919",
            textTransform: "capitalize",
            letterSpacing: 'unset',
        },
    }
);



export default PastAppointment