
import { Avatar } from '@mui/material';
import useGlobal from '../../../hooks/useGlobal';
import { generateImageURL } from '../../../utils';
import TeamCard from './TeamCard';

const Teams = ({ list }) => {

    return (
        <>
            <div className="ourteam">
                {
                    Array.isArray(list) && list.map((e, i) => {
                        return (
                            <TeamCard data={e} />
                        )
                    })
                }
            </div>
        </>
    )
}


export default Teams;