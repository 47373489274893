import React from 'react'
import '../slider/slider.css'
import one from '../../../assets/images/Group 5890.png'
import two from '../../../assets/images/Group 5891.png'
import three from '../../../assets/images/Group 5892.png'
import { useTranslation } from 'react-i18next'
import design from '../../../assets/images/design.png';
import "../../../../src/components/animations/animations.css";
import { motion } from 'framer-motion';
import { generateImageURL } from '../../../utils';
import useGlobal from '../../../hooks/useGlobal'

const Chatpeople = props => {
    const { tKey: [tKey] } = useGlobal();
    const {
        Title,
        SubTitle,
        Images
    } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className='design1'>
                <img src={design} alt="" />
            </div>
            <div className="chatpeople">
                <div className="chat-content">
                    <h2>
                        {Title[tKey]}
                        {/* {t('interactViaChat')} */}
                    </h2>
                    <p>
                        {SubTitle[tKey]}
                        {/* {t('privateOnlineConsultations')} */}
                    </p>
                </div>
                <div className="label">
                    {Images.map((item, i) => {
                        return (
                            <div className="img" key={i}>
                                <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.8 }} >
                                    <img
                                        //  style={{animation: "up-down 2s ease-in-out infinite alternate-reverse both"}}
                                        // style={{animation: "mymove 5s infinite"}}
                                        src={generateImageURL(item.image)} alt="" />
                                </motion.div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default Chatpeople