import React from 'react'
import * as Chat from "twilio-chat";
import ChatItem from './chatItem';
import chatSend from '../../assets/images/chat-send.png'
import pdfbtn from '../../assets/images/chat-btn.png'
const getTwilioCredentials = async () => {
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/chat/token`, {
    method: "POST",
    body: JSON.stringify({
      identity: 'Tester',
      room: "8888",
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
  });
  const data = await res.json();
  return data;
}

class ChatContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      identity:this.props.credentials.identity,
      messages: [],
      loading: false,
      channel: null,
    };

    this.scrollDiv = React.createRef();
  }

  componentDidMount = async () => {
    const client = await new Chat.Client(this.props.credentials.token);
    client.on("tokenAboutToExpire", async () => {
      const data = await getTwilioCredentials();
      client.updateToken(data.token);
    });

    client.on("tokenExpired", async () => {
      const data = await getTwilioCredentials();
      client.updateToken(data.token);
    });

    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      const messages = await channel.getMessages();
      this.setState({ messages: messages.items || [] },this.scrollToBottom);
    });

    try {
      const channel = await client.getChannelByUniqueName('dummy_room');
      this.joinChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: 'dummy_room',
          friendlyName: 'Dummy Room',
        });
        this.joinChannel(channel);
      } catch (err) {
        console.error(err);
        throw new Error("Unable to create channel, please reload this page");
      }
    }
  }
  render = () => {
    return (
      <div className='flex chat-window'>
        <div className='message-list' ref={this.scrollDiv}>{this.state.messages.map((e, i) => <ChatItem key={i} message={e} email={this.state.identity}></ChatItem>)}</div>
        <div className='chat-btn-container'>
          <input
            required
            placeholder="Type here…"
            variant="outlined"
            value={this.state.text}
            disabled={!this.state.channel}
            onChange={(event) =>
              this.setState({ text: event.target.value })
            } />
          <button
          className='chat-send-btn'
            onClick={this.sendMessage}
            disabled={!this.state.channel}>
            <img src={chatSend} alt="" />
          </button>
           <button className='chat-send-btn' > <img src={pdfbtn} alt="" /> </button>
        </div>
      </div>
    )
  }
  joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
    this.setState({
      channel: channel,
      loading: false
    },this.scrollToBottom);
    channel.on("messageAdded", this.handleMessageAdded);
  };


  handleMessageAdded = (message) => {
    const { messages } = this.state;
    this.setState({
      messages: [...messages, message],
    },
      this.scrollToBottom
    );
  };
  sendMessage = () => {
    const { text, channel } = this.state;
    if (text) {
      this.setState({ loading: true });
      channel.sendMessage(String(text).trim());
      this.setState({ text: "", loading: false });
    }
  };

  scrollToBottom = () => {
    const scrollHeight = this.scrollDiv.current.scrollHeight;
    const height = this.scrollDiv.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

}

export default ChatContainer;