
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutTwo } from '../../../layout/Layout';
import './addfund.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCaller, postCaller } from '../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";
import { confirmAlert } from "react-confirm-alert";

const addFundSchema = Yup.object().shape({
  amount: Yup.number().min(1, "Amount is too less").max(500000, "Amount is too high").required('Amount is required'),
})

const AddFund = () => {

  const navigate = useNavigate();
  const [type, setType] = useState('Card');

  if (typeof window !== "undefined") {
    injectStyle();
  }
  const handleChange = e => {
    setType(e.target.value);
  }
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: addFundSchema,
    onSubmit: async (values) => {

      const trackIdResponse = await getCaller('trackId');
      console.log(trackIdResponse);
      const wallet_orderId = trackIdResponse.order_id

      const jsonBody = {
        order_id: wallet_orderId,
        // order_id: "76483",
        amount: values.amount,
        currency: "SAR",
        email: "dhruv@cloudstok.com"
      }

      if (trackIdResponse) 
      {
        const response = await postCaller('addfund/pg', jsonBody);

        if (response.status == "1") {
          console.log(response);
          console.log("Status:", response.status);
          let redirect = response.paymentUrl;
          console.log(redirect);
          if (confirmAlert({
            overlayClassName: "overlay-custom-class-name",
            customUI: ({ onClose }) => {
              return (
                <div className='over'>
                  <div className="over-head">
                    <h5>Are you sure you want to add SAR ${values.amount}.00 to Wallet using Debit/Credit Card? </h5>
                    <p> You are getting redirected to payment gateway </p>
                    <div className="over-btn-head">
                      <button className='yes-btn' onClick={() => window.location.href = response.paymentUrl}> Yes </button>
                      <button
                        className='cancel-btn-over'
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          }));
        }
        else {
          toast.error("Wallet Amount Not Added")
        }
      }
    }
  })

  function cancel() {
    formik.resetForm({ values: '' })
    navigate('/dashboard');
    // console.log(formik.values);
  }

  return (
    <LayoutTwo>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} >

        <div className="addfund-container">
          
          <div className="addfund">
            <div className='add-fund-border'>
              <p> Add Funds </p>
            </div>
            <div className="add-fund-card">
              <div className="">
                <div className="add-enter-input">
                  <div className="">
                    <label htmlFor="amount"> Enter Amount </label> <br />
                    <input type="text"
                      onChange={formik.handleChange}
                      value={formik.values.amount !== null && formik.values.amount}
                      name="amount"
                      placeholder='Enter Amount' className='add-input' />
                    <div>
                      {formik.touched.amount && formik.errors.amount && (
                        <span className="error">{formik.errors.amount}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="add-payment">
                  <div className="">
                    <label htmlFor=""> Choose Payment Method </label>
                    <div className="add-card-content">
                      <div className='add-fund-radio'>
                        <input
                          type="radio"
                          name="" id=""
                          checked={type === "Card"}
                          onChange={handleChange}
                        />
                        <label htmlFor=""> Card </label>
                      </div>
                      <div className='add-fund-radio'>
                        <input
                          type="radio"
                          name="" id=""
                          checked={type === "NetBanking"}
                          onChange={handleChange}
                        />
                        <label htmlFor=""> Net Banking </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-number-container">
                <p style={{ color: "#707070", fontSize: "0.875rem", textAlign: "center", marginTop: "20px" }}>
                  Amount will be debited from your Card
                </p>
                {/* <div className="card-number-input">
                  <div className="card-number">
                    <label htmlFor=""> Card Number* </label> <br />
                    <input type="text" name="" id="" placeholder='Enter' className='card-input' />
                  </div>
                  <div className="expiry">
                    <label htmlFor=""> Expiry Date </label> <br />
                    <input type="text" name="" id="" placeholder='Enter' className='expiry-input' />
                  </div>
                </div>
                <div className="card-p-input">
                  <div className="card-number">
                    <label htmlFor=""> Card Holder Name* </label> <br />
                    <input type="text" name="" id="" placeholder='Enter' className='card-input' />
                  </div>
                  <div className="expiry">
                    <label htmlFor=""> CVV Number </label> <br />
                    <input type="text" name="" id="" placeholder='Enter' className='expiry-input' />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="add-fund-btn-content">
              <div className="add-fund-btn">
                <button className='resh-cancel' onClick={() => cancel()}> Cancel </button>
                <button className='resh-submit'
                  // onClick={handleCardPayment}
                  type='submit'
                > Make Payment </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </LayoutTwo>
  )
}

export default AddFund