import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import Navbar from "../../layout/Navbar/Navbar";
// import { postCaller } from '../../services/api';
import { coreChatWindow, dateFormatWW, dateFormatWW_TIME, generateQuery, getDiscountedAmount } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getCaller } from '../../services/api';
import CustomPagination from '../../components/CustomPagination';

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import ChatIcon from '@mui/icons-material/Chat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GlobalContext from "../../context/GlobalContext";
import { constants } from "../../utils/constants";

function Notifications({ route }) {
    const { t } = useTranslation();
    const context = useContext(GlobalContext);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');

    const [list, setList] = useState({});

    const navigate = useNavigate();

    const maximo = list?.count / 5;


    const getUserNotifications = async (payload = {}) => {
        try {
            let URL = `notifications-listing?`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            setList(response?.data || {})
        } catch (e) {
            console.error("🚀 ~ file: Notifications.jsx:46 ~ fetchWalletRecords ~ e", e)
        }
    }

    const nextData = page => {
        const payload = {
            ...query,
            page
        }
        getUserNotifications(payload)
    }

    useEffect(() => {
        nextData(page)
    }, [page])


    useEffect(() => {
        getUserNotifications();
    }, [])
    return (
        <>
            <div className='user-wallet-container'>
                <div className="user-wallet">
                    <div className="user-wallet-content">
                        <div className="user-wallet-content1">
                            <p className="text-capitalize">{t('notifications')}</p>
                            {/* <h4> SAR {walletBalance}.00 <small> As on 23 Jan 2022 </small> </h4> */}
                        </div>
                    </div>
                </div>
                <div className="user-past-content">
                    <div className="user-table-past">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ minWidth: '5rem' }}>S No. </StyledTableCell>
                                        <StyledTableCell>Description </StyledTableCell>
                                        <StyledTableCell>Date & Time</StyledTableCell>
                                        {/* <StyledTableCell>Action </StyledTableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Array.isArray(list?.rows)
                                        && list.rows.map((e, i) => {
                                            return (
                                                <StyledTableRow >
                                                    <StyledTableCell component="th" scope="row"> {i + 1} </StyledTableCell>
                                                    <StyledTableCell> {e?.body ?? "n/a"} </StyledTableCell>
                                                    <StyledTableCell sx={{ minWidth: '15rem' }}> {dateFormatWW_TIME(e?.createdAt)} </StyledTableCell>
                                                    {/* <StyledTableCell>
                                                        {
                                                            e?.targetUrl ? <VisibilityIcon /> : constants.DOTS
                                                        }
                                                    </StyledTableCell> */}
                                                </StyledTableRow>
                                            )
                                        })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <CustomPagination
                        page={page} setPage={setPage} maximo={maximo}
                    />
                </div>
            </div>
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D5F1FF",
        color: "black",
        border: 0,
        verticalAlign: "top",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        height: '50px',
        verticalAlign: "top",
        userSelect: "none",
        color: "#1A1919",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
        //   border: 0,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default Notifications;
