import React, { useState, useEffect } from "react";
import "./userappointment.css";
import { getCaller } from "../../../services/api";
import UpcomingAppointment from "./UpcomingAppointment";
import InreviewAppointments from "./InreviewAppointments";
import PastAppointment from "./PastAppointment";

const UserAppointment = (props) => {
    const [data, setData] = useState();
    const [upcomingAppointments, setUpcomingAppointments] = useState();
    const [inReviewAppointments, setinReviewAppointments] = useState([]);
    const [pastAppointments, setPastAppointment] = useState();


    // useEffect(() => {
    //     getBook();
    // }, []);

    // async function getBook() {
    //     let res = await getCaller("getAllAppointment");

    //     setUpcomingAppointments(
    //         res.data.filter((el) => el?.status.toLowerCase() === "upcoming")
    //     );
    //     setinReviewAppointments(
    //         res.data.filter((el) => el?.status.toLowerCase() === "inreview")
    //     );
    //     setPastAppointment(
    //         res.data.filter((el) => el?.status.toLowerCase() === "completed")
    //     );
    // }
    // let aptData = {
    //     upcomingApt: upcomingAppointments,
    //     reviewApt: inReviewAppointments,
    //     pastApt: pastAppointments
    // }
    return (
        <>
            <div className="user-appointment">
                {/* upcoming card  */}
                <UpcomingAppointment data={{}} />
                {/* Review card */}
                <InreviewAppointments data={{}} />
            </div>

            {/* past appointment  */}
            <PastAppointment data={{}} />
        </>
    );
};

export default UserAppointment;