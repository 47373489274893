import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import star from "../../../assets/images/Group 5279.png";
import "./doctoraccount.css";
import { formatTime, generateImageURL, showDob } from "../../../utils";
import "../../Dashboard/dashboard.css";
import veriuser from "../../../assets/images/Group 5276.png";
import { getCaller } from "../../../services/api";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../context/GlobalContext";
import useGlobal from "../../../hooks/useGlobal";
const DoctorProfileAccount = () => {

    const { tKey: [tKey] } = useGlobal();
    
    const context = useContext(GlobalContext);
    const [, setUserProfile] = context.userProfile;
    const [data, setData] = useState("");
    const [profileImg, setProfileImg] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    const navigate = useNavigate();

    async function getData() {
        const resp = await getCaller("showDoctorProfile");
        setData(resp.data);
        setProfileImage(generateImageURL(resp?.data?.url))
        setUserProfile(generateImageURL(resp?.data?.url))
    }

    const { t } = useTranslation();
    const handleProfileUpload = async (event) => {
        setProfileImg(event.target.files[0]);
        let formData = new FormData();
        console.log(event.target.files);
        formData.append("docs", event.target.files[0]);

        // formData.append("avatar", event.target.files[0]);
        console.log(formData.has("avatar"));

        console.log(formData.avatar);
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/uploadFile`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            }
        );
        console.log(formData);
        console.log(response);
        getData();
    };
    const totalData = [
        {
            head: "Total Patients",
            para: data?.allPatients ?? "NA",
        },
        {
            head: "Calls",
            para: data?.allCalls ?? "NA",
        },
        {
            head: "Video Calls",
            para: data?.allCalls ?? "NA",
        },
        {
            head: "Appointments",
            para: data?.appointments ?? "NA",
        },
    ];

    function capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    useEffect(() => {
        getData();
        }, []);
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Grid item xs={12} md={12} className="side-right-content">
                        <div className="side-right-head">
                            <h2>{t("statistics")}</h2>
                        </div>

                        <Grid item xs={12} md={12}>
                            <div className="totalcard">
                                {totalData.map((e, i) => {
                                    return (
                                        <>
                                            <div className="totalcard-content">
                                                <div className="total-head">
                                                    <p> {e.head} </p>
                                                    <h4> {e.para} </h4>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: 5, paddingBottom: 10 }}>
                <Grid container>
                    <Grid item xs={12} md={12} className="side-right-content">
                        <div className="side-right-head">
                            <h2> Profile </h2>
                            <div
                                onClick={() => navigate(`/editDoctorProfile/${data.loginId}`)}
                                className="edit"
                            >
                                {t("editProfile")}
                            </div>
                        </div>

                        <Grid item xs={12} md={12}>
                            <div className="profile">
                                <div className="profile-img">
                                    <img
                                        src={profileImage}
                                        alt=""
                                        width="175"
                                        style={{ borderRadius: "8px" }}
                                    />
                                    <img src={star} alt="" className="star-img" />
                                    <br />
                                    <input
                                        // type="file"
                                        id="uploadImage"
                                        hidden
                                        // onChange={(event) => {
                                        //     handleProfileUpload(event);
                                        // }}
                                        onClick={() => navigate(`/editDoctorProfile/${data.loginId}`)}
                                        className="edit"
                                        accept="image/*"
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer",
                                            padding: "0.5rem 0rem",
                                        }}
                                        htmlFor="uploadImage"
                                    >
                                        {t("changePhoto")}
                                    </label>
                                </div>
                                <div className="profile-content">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <h3>{data?.name ?? "Doctor"} </h3>{" "}
                                        <img src={veriuser} alt="" style={{ marginLeft: "1rem" }} />
                                    </div>
                                    <h4>{data?.subService?.service_master?.services?.[tKey]?? "subServiceId"} {data?.subService?.sub_services?.[tKey]}</h4>
                                    <Box sx={{ flexGrow: 1, marginTop: 2 }}>
                                        <Grid
                                            container
                                            spacing={{ xs: 2, md: 3 }}
                                            columns={{ xs: 4, sm: 8, md: 12 }}
                                        >
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("mobileNumber")} </p>
                                                <h5>{data?.mobile ?? "Not available"} </h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("emailId")} </p>
                                                <h5>{data?.email ?? "Not available"} </h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("gender")} </p>
                                                <h5>{capitalize(data?.gender) ?? "Not available"}</h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("location")} </p>
                                                <h5> {data?.location ?? "Not available"} </h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("dob")} </p>
                                                <h5> {`${showDob(data?.dob)}` ?? "Not available"} </h5>
                                            </Grid>

                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("age")} </p>
                                                <h5> {data?.age ?? "Not available"} </h5>
                                            </Grid>

                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> Years Of Experience </p>
                                                <h5> {data?.experience ?? "Not available"} </h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> {t("appointmentFees")} </p>
                                                <h5> {data?.fees ?? "Not available"} </h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> License </p>
                                                <h5> Not available </h5>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <p> Review </p>
                                                <h5> {Number(data?.review).toPrecision(2) ?? "Not available"} </h5>
                                            </Grid>
                                            <div className="profile-about">
                                                <p> About </p>
                                                <h5>{data?.description ?? "Not available"}</h5>
                                            </div>
                                        </Grid>
                                    </Box>

                                    <div className="profile-time">
                                        <div>
                                            <p> {t("timingSlots")} </p>
                                            <div className="timing-schedule">
                                                {data?.schedule &&
                                                    data?.schedule?.map((el, i) => (
                                                        <>
                                                            <div className="timing-schedule-body">
                                                                <div className="day-form">
                                                                    {capitalize(el?.day) ?? "n/a"}
                                                                </div>
                                                                <div className="time-form">
                                                                    {" "}
                                                                    {el?.start} to {el?.end}{" "}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="saudi-edit">
                                            <p> {t("saudiCLicence")} </p>
                                            {data?.license_no ? (
                                                <h5>{data?.license_no}</h5>
                                            ) : (
                                                <div>Not available</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DoctorProfileAccount;
