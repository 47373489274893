import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import '../../DoctorDashboard/doctoraccount/doctoraccount.css'
import '../../Dashboard/dashboard.css'
import { useTranslation } from 'react-i18next';
import { getCaller } from '../../../services/api';
import { generateImageURL, showDob } from '../../../utils';
import GlobalContext from '../../../context/GlobalContext';
const UserAccount = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { userProfile: [, setUserProfile] } = useContext(GlobalContext);
    const type = localStorage.getItem('type')
    const [user, setUser] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [profileImg, setProfileImg] = useState(null);

    async function getUserData() {
        const resp = await getCaller('showPatientProfile');
        console.log('resp :>> ', resp);
        setUser(resp.data)

        setProfileImage(generateImageURL(resp?.data?.url || ""))
        setUserProfile(generateImageURL(resp?.data?.url))
        // setUserDetails(_prev => ({ ..._prev, url: resp?.data?.url }))
        console.log(resp.data)
    }
    useEffect(() => {
        getUserData();
    }, [])
    if (user && user.name) {
        localStorage.setItem('name', user?.name)

    }
    const handleProfileUpload = async (event) => {
        setProfileImg(event.target.files[0]);
        let formData = new FormData();
        formData.append("docs", event.target.files[0]);
        console.log(event.target.files)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/uploadFile`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData
            }

        );
        debugger;
        console.log(formData)
        getUserData();
    }
    const editData = [
        {
            title: `${t('mobileNumber')}`,
            desc: user?.mobile ?? "Not available",
        },
        {
            title: `${t('emailId')}`,
            desc: user?.email ?? "Not available",
        },
        {
            title: `${t('gender')}`,
            desc: user?.gender ?? "Not available",
        },
        {
            title: `${t('location')}`,
            desc: user?.location ?? "Not available",
        },
        {
            title: `${t('dob')}`,
            desc: showDob(user?.dob) ?? "Not available",
        },
        {
            title: `${t('appointmentsTaken')}`,
            desc: user?.appointmentTaken ?? "Not available",
        },
    ]
    if (!user) {
        return <div style={{
            height: '50vh', background: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            color: 'lightslategray'
        }}><h5>Data not available </h5></div>
    }

    const redirectToEditProfile = () => navigate(`/editPatientProfile`);
    return (
        <>
            <Box>
                <Grid item xs={12} md={12} className="side-right-content">
                    <div className='side-right-head'>
                        <h2 style={{
                            textTransform: 'capitalize'
                        }}> {t('profile')} </h2>
                        <div className="edit" onClick={redirectToEditProfile}> Edit Profile </div>
                    </div>
                    <Grid item xs={12} md={12} >
                        <div className='profile'>
                            <div className='profile-img'>
                                <img src={profileImage} alt="" width="175"
                                    style={{ borderRadius: '8px' }}
                                />
                                <br />
                                {/* <input type="file" id="uploadImage" hidden onChange={(event) => { handleProfileUpload(event) }} accept="image/*" /> */}
                                <label
                                    style={{
                                        cursor: 'pointer',
                                        padding: '0.5rem 0rem'
                                    }}
                                    htmlFor="uploadImage"
                                    onClick={redirectToEditProfile}
                                >{t('changePhoto')}</label>

                            </div>
                            <div className='profile-content'>
                                <div><h3> {user?.name ?? "Patient"} </h3></div>

                                <Box sx={{ flexGrow: 1, marginTop: 2 }}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {
                                            editData.map((e, i) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} sm={4} md={4} key={i} >
                                                            <p> {e.title} </p>
                                                            <h5> {e.desc} </h5>
                                                        </Grid>
                                                    </>
                                                )

                                            })
                                        }
                                    </Grid>
                                </Box>
                                <div className="profile-about" style={{
                                    paddingRight: '4rem',
                                    marginBottom: '2rem'
                                }}>
                                    <p>{t('about')} </p>
                                    <h5>
                                        {user?.about ?? "Not Availabel"}
                                    </h5>
                                </div>

                                <div className="user-health">
                                    <div>
                                        <p> {t('healthCondition')} </p>
                                        <h5> {user?.condition ?? "Not available"} </h5>
                                    </div>
                                    <div className="user-family">
                                        <p>   {t('familyMembers')}</p>
                                        {user.members ? user.members.map((el, i) => <h5 style={{
                                            textTransform: 'capitalize'
                                        }}> {`${i + 1}. ${el?.name} ${el?.gender}, ${el?.age}`} </h5>) : "Not available"}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default UserAccount