import React from 'react'
import { VideoChat } from './Video'
import vuser from '../../assets/images/Image 73.png'
import a from '../../assets/images/Group 5632.png'
import { useTranslation } from 'react-i18next';

const DoctorVideoCalling = () => {
  
  const { t } = useTranslation();

  return (
    <>
      <VideoChat userHead="Samir Ahmed"
        userPara="Male,S 36  | Last Appointment On: 12 Feb 2022"
        doctorimg={vuser}
        bimage={a}
        btnpara={t('viewPatientProfile')}
      />
    </>
  )
}

export default DoctorVideoCalling