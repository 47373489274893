import '../contact/terms.css';
import { useEffect } from 'react'
import React, { useState } from 'react'
import Layout from '../../layout/Layout'
import useGlobal from '../../hooks/useGlobal';
import { getCaller } from '../../services/api';
import { split_by_new_line } from '../../utils';
import { fix_pages } from '../../utils/constants'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';


const Privacy = () => {
    const { tKey: [tKey] } = useGlobal();

    const [state, setState] = useState({});
    const getPolicy = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages['privacy']}`);
            if (response?.error === false && response?.data) {
                setState(response?.data || {})
            }
        } catch { }
    }

    useEffect(() => {
        getPolicy()
    }, [])

    return (
        <Layout>
            <Breadcrumb head="Privacy Policy" />
            <h2 className='privacy-heading'>{state?.page_name && state?.page_name[tKey]}</h2>
            {
                Array.isArray(state?.content) && state.content.map(
                    item => {
                        return (
                            <div className="privacy-contact">
                                <div>{item?.title && item.title[tKey]}</div>
                                <div className="privacy-para-content">
                                    {
                                        item?.content
                                        && item.content[tKey]
                                        && split_by_new_line(item.content?.[tKey]).map(
                                            (item) => (
                                                <p>{item}</p>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                )
            }
        </Layout>
    )
}

export default Privacy;