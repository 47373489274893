import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import '../contact/contact.css'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EmailImage from "../../assets/images/email.svg";
import call from "../../assets/images/call.svg";
import user from "../../assets/images/usercircle.svg";
import Layout from "../../layout/Layout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCaller, postCaller, postQuery } from "../../services/api";
import { getOnlyNumber } from "../../utils";
import { ThemeButton } from "../../components/common/Button";
import { toast } from "react-toastify";
import { responseMessages } from "../../utils/responseMessages";
import useGlobal from "../../hooks/useGlobal";
import { fix_pages } from "../../utils/constants";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";


import {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { useNavigate } from "react-router-dom";


const API_KEY = 'AIzaSyAJg4PGbl1XfwJFKo-vQWlUVtH1Zzg3FEQ'

/* FORMIK */
const selectQuery = ["Select Query", "Refund", "Reschedule", " Problem with Appointment"];
const contactSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is Required"),
    mobile: Yup.string()
        .min(10, "Incorrect mobile number")
        .max(10, "Invalid Mobile Number")
        .required("mobile number is required"),
    message: Yup.string()
        .max(100)
        .required("please enter a reasone"),
});
/* FORMIK */


const Contact = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { tKey: [tKey] } = useGlobal();

    const { email: [email], userDetails: [userDetails] } = useGlobal();

    const [data, setData] = useState({});

    const [coordinates, setcCoordinates] = useState([])


    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            mobile: "",
            type: selectQuery[0],
            message: "",
        },
        validationSchema: contactSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const res = await postCaller('createQuery', values);
                if (res?.status === true) {
                    toast.success(res?.message)
                } else {
                    toast.success(res?.message || responseMessages.UNKNOWN_ERROR)
                }
            }
            catch (error) {
                console.error("🚀 ~ file: Contact.jsx:60 ~ onSubmit: ~ error", error)
            }
            finally {
                // resetForm();
                setSubmitting(false)
            }
        },
    });

    const handleMobileNumber = ({ target: { value } }) => {
        formik.setFieldValue(
            'mobile',
            getOnlyNumber(formik.values.mobile, value, 9999999999)
        )
    }

    const cData = [
        {
            img: EmailImage,
            title: `${t("emailId")}`,
            // desc: email,
            desc: <a href="mailto: var-clinin@gmail.com">var-clinin@gmail.com</a>
        },
        {
            img: call,
            title: `${t("callUs")}`,
            // desc: "022 4968 4040",
            desc: <a href="tel:+022 4968 4040">022 4968 4040</a>
        },
        {
            img: user,
            title: "Join Our Team",
            // desc: email,
            // desc: <a href="mailto: var-clinin@gmail.com">Join Our Team</a>
            desc: <p onClick={() => navigate('/register')} >Join Our Team</p>
        },
    ];

    const fetchContactUsMaster = async () => {
        try {
            const response = await getCaller(`getWebsiteContent?page_type=${fix_pages.contact}`)
            if (response?.error === false && response?.data) {
                setData(response?.data)
            }
        } catch (error) {
            console.error("🚀 ~ file: Contact.jsx:123 ~ fetchContactUsMaster ~ error", error)
        }
    }

    useEffect(() => {
        if (userDetails) {
            formik.setFieldValue('name', userDetails?.name)
            formik.setFieldValue('mobile', userDetails?.mobile)
            formik.setFieldValue('email', userDetails?.email)
        }
    }, [userDetails])


    const fetchLatLongs = async add => {
        console.log("🚀 ~ file: Contact.jsx:155 ~ fetchLatLongs ~ add", add)

        if (!add) {
            return false
        }
        try {
            const results = await getGeocode({ address: add });
            const { lat, lng } = getLatLng(results[0]);
            console.log(`Address >>> ${add}`, { lat, lng })
            setcCoordinates(
                prev => [...prev, { lat, lng }]
            )
        } catch (error) {
            console.log("🚀 ~ file: Contact.jsx:165 ~ fetchLatLongs ~ error", error)
            return false
        }
    }



    useEffect(() => {
        if (
            Array.isArray(data?.content)
        ) {
            data.content.map(
                d => {
                    fetchLatLongs(d?.address)
                }
            )
        }
    }, [data])


    useEffect(() => {
        console.log('coordinates>>>>>>>', coordinates)
    }, [coordinates])

    useEffect(() => {
        fetchContactUsMaster()
    }, []);
    return (
        <Layout>
            <div className="contact">
                <Breadcrumb head="Contact" />
                <div className="contact-container">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            {cData.map(
                                (e, i) => {
                                    return (
                                        <Grid item xs={12} sm={4} md={4} key={`cData_${i}`}>
                                            <div className="email-container">
                                                <img src={e.img} alt="" />
                                                <div className="email-head">
                                                    <h5 style={{ fontSize: '100%' }}> {e.title} </h5>
                                                    {e.desc}
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Box>
                    <div className="contact-form">
                        <div className="get-in"> {t("getTouch")}</div>
                        <form action="" className="contact-form-container" onSubmit={formik.handleSubmit}>
                            <div className="contact-input-container">
                                <div className="contact-input-body">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="Enter email"
                                        className="input-one"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />

                                    <span className="error">
                                        {formik.errors.email && formik.errors.email}
                                    </span>
                                </div>
                                <div className="contact-input-body">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder={t("enterName")}
                                        className="input-two"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />

                                    <span className="error">
                                        {
                                            formik.touched.name
                                            && formik.errors.name
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="contact-input-container">
                                <div className="contact-input-body">
                                    <input
                                        type="text"
                                        name="mobile"
                                        id="mobile"
                                        placeholder={t("enterMobile")}
                                        className="input-one"
                                        onChange={handleMobileNumber}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.mobile}
                                    />

                                    {formik.touched.mobile && formik.errors.mobile && (
                                        <span className="error">{formik.errors.mobile}</span>
                                    )}
                                </div>
                                <div className="contact-input-body">
                                    <select
                                        name="type"
                                        id="type"
                                        className="contact-select-field"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.type}
                                    >
                                        {selectQuery.map((el, index) => {
                                            return (
                                                <option value={el} key={`query_drop_down_${index}`}>
                                                    {el}
                                                </option>
                                            );
                                        })}


                                    </select>

                                    {formik.touched.type && formik.errors.type && (
                                        <span className="error">{formik.errors.type}</span>
                                    )}
                                </div>
                            </div>
                            <div className="contact-text-area">
                                <textarea
                                    className="textarea-only-y"
                                    name="message"
                                    id="message"
                                    placeholder={t("typeMessage")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}
                                ></textarea>
                            </div>
                            {formik.touched.message && formik.errors.message && (
                                <span className="error" style={{ marginLeft: "0.5rem" }}>{formik.errors.message}</span>
                            )}
                            <div>
                                <ThemeButton
                                    loading={formik.isSubmitting}
                                    type={'submit'}
                                    className={'submit-btn'}
                                    text={t("submit")}
                                    sx={{
                                        width: '50%',
                                        margin: '1.5rem auto'
                                    }}
                                />
                                {/* <button type="submit" className="submit-btn">
                                    {" "}
                                    {t("submit")} <i className="fa-solid fa-chevron-right"></i>{" "}
                                </button> */}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="contact-map">
                    <div className="contact-add">
                        {
                            Array.isArray(data?.content)
                            && data.content.map(
                                (d, index) => {
                                    return (
                                        <div key={`dynamic_address_${index}`} className="add">
                                            <h4> {d?.title?.[tKey]} </h4>
                                            <p>{d?.address}</p>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    <div style={{ height: "100%", width: "100%" }}>
                        {
                            Array.isArray(coordinates)
                            && coordinates.length > 0
                            && <Map
                                googleMapURL={`http://maps.googleapis.com/maps/api/js?key=${API_KEY}`}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `590px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                center={{
                                    lat: coordinates[0].lat,
                                    lng: coordinates[0].lng
                                }}
                                points={coordinates}
                            />
                        }
                    </div>
                </div>
            </div >
        </Layout >
    );
};

const Map = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            zoom={13}
            options={{

            }}
            center={props.center}
        // onClick={e => props.onMapClick(e)}
        >
            {
                props.points.map(
                    (coordinate, index) => <Marker
                        key={`google_map_marker_point_${index}`}
                        position={{ lat: coordinate.lat, lng: coordinate.lng }}
                    />
                )
            }

        </GoogleMap>
    ))
);

export default Contact;
