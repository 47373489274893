import React from 'react';
// import React, { useTransition,useState,useRef } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './fordoctor.css'
import { useTranslation } from 'react-i18next';
import { generateImageURL } from '../../../utils';
import AOS from "aos";
import useGlobal from '../../../hooks/useGlobal';
import { Avatar, styled } from '@mui/material';
// import Box from '@mui/material/Box';


const StyledAvator = styled(Avatar)(
    () => ({
        width: 55,
        height: 55,
        margin: 5,
    })
)

const ForDoctor = props => {
    const { tKey: [tKey] } = useGlobal();
    const {
        Title,
        SubTitle,
        BackgroundImage,
        Images
    } = props;

    const { t } = useTranslation();

    AOS.init({
        offset: 100,
        duration: 1300,
        easing: "ease-in-sine",
        delay: 100,
    });

    return (
        <>
            <div className="fordoctors">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 4, sm: 8, md: 12 }}
                        justifyItems={"center"}
                    >

                        <Grid item xs={12} sm={4} md={4}>
                            <div className="for-d-img">
                                <img
                                    style={{ animation: "transitionn 5s infinite" }}
                                    // style={{animation: "hello-goodbye 1.8s infinite"}} 
                                    src={generateImageURL(BackgroundImage)}
                                    alt=""
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={8}>
                            <div className="header-item">
                                <h2>
                                    {/* {t('forDoctors')} */}
                                    {Title[tKey]}
                                </h2>
                                <p>
                                    {SubTitle[tKey]}
                                </p>
                            </div>
                            <div>
                                {
                                    Images.map((e, i) => {
                                        return (
                                            <div className="doctors-right" data-aos="fade-left" key={i}>
                                                <StyledAvator
                                                    src={generateImageURL(e.image)}
                                                />
                                                {/* <Avatar
                                                    src={generateImageURL(e.image)}
                                                /> */}
                                                <p>
                                                    {e?.title[tKey]}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Box>

            </div>








        </>
    )
}

export default ForDoctor