import './userappointment.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { getCaller, updateCaller } from '../../../services/api';
import { appointmentStatus } from '../../../utils/constants';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { showDob, formatTime, formatFromToTimeSlot, dateFormatWW, destructureSlot, generateQuery } from "../../../utils";
import Appointment from '../../../components/common/Cards/AppointmentPatient';



const INITIAL_RESPONSE = {
    rows: [],
    count: 0,
}

const InreviewAppointments = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const [sliderRef, setSliderRef] = useState(null)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [data, setData] = useState(INITIAL_RESPONSE);
    const [page, setPage] = useState(1)
    const __max = Math.ceil(data?.count / 5);

    const handleSliderChange = (index) => {
        setCurrentSlide(index)
        if (index === data?.rows?.length - 1 && page !== __max) {
            const payload = {
                page: page + 1,
            }
            setPage(page => page + 1)
            fetchReviewAppointments(payload)
        }
    }

    const sliderSettings = {
        // ...
        afterChange: handleSliderChange,
    };
    const fetchReviewAppointments = async (payload) => {
        try {
            let URL = `getAllAppointment?status=${appointmentStatus['inReview']}`;
            URL = generateQuery(URL, payload);
            const response = await getCaller(URL);
            console.log("🚀 ~ file: InreviewAppointments.jsx:57 ~ fetchReviewAppointments ~ response", response)
            const temp = data;
            temp.rows = [...temp?.rows, ...response?.data?.rows]
            temp.count = response?.data?.count;
            setData({ ...temp })
        } catch (e) {
            setData(INITIAL_RESPONSE)
        }
    }

    const handleViewDetails = (currentAppointment) => {
        navigate('/userReviewReport', {
            state: currentAppointment
        })
    }


    useEffect(() => {
        console.log('mounted');
        fetchReviewAppointments({});
        return () => {
            console.log('un-mounted');
            setData(INITIAL_RESPONSE)
        }
    }, [])

    useEffect(() => {
        console.log("🚀 ~ file: InreviewAppointments.jsx:85 ~ useEffect ~ ̥", data)
    }, [data])
    return (
        <>
            <div className="user-app-content-2">
                <div className="user-upcoming">
                    <h5>
                        {t("inReview")} <span> {data?.count} Items </span>{" "}
                    </h5>
                    <div>
                        <div className="appointment-arrow">
                            <ArrowBackIosNewIcon
                                sx={{
                                    mr: '1rem',
                                    cursor: 'pointer',
                                    color: currentSlide === 0 ? 'grey' : '#3a9cd8'
                                }}
                                onClick={!(currentSlide === 0) && sliderRef?.slickPrev}
                            />
                            {/* {currentSlide + 1} */}
                            <ArrowForwardIosIcon
                                sx={{
                                    cursor: 'pointer',
                                    color: currentSlide === data?.rows?.length - 1 ? 'grey' : '#3a9cd8'
                                }}
                                onClick={!(currentSlide === data?.rows?.length - 1) && sliderRef?.slickNext}
                            />
                        </div>
                    </div>
                </div>

                <Slider ref={setSliderRef} {...sliderSettings}>
                    {
                        data?.rows?.length > 0
                            ? data?.rows?.map(
                                (el) => {
                                    return (
                                        <Appointment
                                            type={appointmentStatus['inReview']}
                                            key={el?.appointmentId}
                                            appointment={el}
                                            handleViewDetails={handleViewDetails}
                                            handleCancelAppointment={() => console.log('cancel')}
                                        />
                                    )
                                }
                            ) : <>
                                <div
                                    style={{
                                        width: '100%',
                                        padding: '5rem 1rem',
                                        textAlign: 'center'
                                    }}

                                >
                                    It appears you don't have any appointment to be reviewed
                                </div>
                            </>
                    }
                </Slider>
            </div>

        </>
    )
}

export default InreviewAppointments