import './view.css'
import { toast } from 'react-toastify';
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { LayoutTwo } from '../../../layout/Layout'
import { ImageList, ImageListItem } from '@mui/material';
import { getCaller, updateCaller } from '../../../services/api';
import { appointmentStatusColor, dateFormatWW, dateFormatWW_TIME, formatTime } from '../../../utils'
import useGlobal from '../../../hooks/useGlobal';


/* icons */
import file from '../../../assets/images/file.png';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { appointmentStatus, constants, extensions, toast_messages } from '../../../utils/constants';

/* hooks */
import { useEffect, useState } from 'react';
import useMasters from '../../../hooks/userMasters';

/* modals */
import DicomModal from '../../../components/modal/DicomModal';
import ReshecduleModal from '../../../components/modal/ReshecduleModal'
import WetReportModal from '../../DoctorDashboard/doctorAppointment/Modals/WetReport';
import { formatFromToTimeSlot } from '../../../utils';
import { destructureSlot } from '../../../utils';


const DoctorReviewReportView = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { appointment } = useMasters();
    const { tKey: [tKey] } = useGlobal();

    const currentAppointment = state;
    const { start, end } = destructureSlot(currentAppointment)?.slot;


    const [open, setOpen] = useState(false);
    const [attachmentsImages, setAttachmentsImages] = useState([]);
    const [attachmentsOthers, setAttachmentsOthers] = useState([]);


    const handleOpenImage = path => window.open(path, '_blank')
    const backHandle = () => navigate('/dashboard/doctor/appointments')
    const handleModalClose = () => setOpen(false)
    const handleModalOpen = () => setOpen(true)


    const rejectHandle = async (id) => {
        if (window.confirm('Are you sure you want to reject this appointment ?')) {
            try {
                const response = await updateCaller(`updateAppointment/${currentAppointment?.appointmentId}?data=${appointmentStatus['reject']}`)
                if (response?.status === true) {
                    toast.success(response?.message)
                    setTimeout(() => {
                        return navigate('/dashboard/doctor/appointments')
                    }, 1500);
                } else {
                    return toast.info(response?.message || toast_messages.common.UNKNOWN)
                }
            } catch (err) {
                console.error(err);
                toast.info(toast_messages.common.UNKNOWN)
            }
        }
    }

    const _showDate = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return dateFormatWW(slot?.date)
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const _formatTime = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return `${formatTime(slot?.time?.start)} - ${formatTime(slot?.time?.end)}`
            } else {
                return ""
            }
        } else {
            return ""
        }
    }


    const fetchAttachments = async () => {
        try {
            const response = await getCaller(`getOneFile/${currentAppointment?.appointmentId}`)
            let images = [];
            let others = [];
            Array.isArray(response?.data) && response.data.forEach(
                attachment => {
                    const extension = attachment?.fileName?.split('.').pop();
                    extensions.IMAGES.includes(extension) ? images.push(attachment) : others.push(attachment)
                }
            )
            setAttachmentsImages(images)
            setAttachmentsOthers(others)
        }
        catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        fetchAttachments();
    }, [])

    return (
        <>
            <WetReportModal
                appointmentId={currentAppointment?.appointmentId}
                open={open}
                close={handleModalClose}
            />
            <LayoutTwo>
                <div className="view-container-1">

                    <div className="view">
                        <button onClick={backHandle} className="back-btn"> <FaAngleLeft /> Back </button>
                        <div className="view-app-detail">
                            <h3>Appointment Details</h3>
                            <div className='common-card'>
                                <div className='common-body'>
                                    <p> Appointment ID  </p>
                                    <h4>{currentAppointment?.appointmentId ?? constants.DOTS} </h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Appointment Date & Time </p>
                                    <h4>
                                        {dateFormatWW(currentAppointment?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                                    </h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Booking Date </p>
                                    <h4>{dateFormatWW_TIME(currentAppointment?.createdAt)}</h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Appointment Status </p>
                                    <h4
                                        className='text-uppercase'
                                        style={{
                                            color: appointmentStatusColor(currentAppointment?.status)
                                        }}
                                    >
                                        {
                                            appointment[currentAppointment?.status] && appointment[currentAppointment?.status].UI
                                        }
                                    </h4>
                                </div>
                            </div>
                            <div className="view-patient-deatail">
                                <h3>Patient Details</h3>
                                <div className="view-doc-detail">
                                    <div className="doc-content">
                                        <div className='common-card'>
                                            <div className='common-body'>
                                                <p> Patient ID  </p>
                                                <h4>{currentAppointment?.user_profile?.patientId ?? constants.DOTS} </h4>
                                            </div>
                                            <div className='common-body1'>
                                                <p> Patient Name </p>
                                                <h4 className='text-capitalize'> {currentAppointment?.patientName ?? constants.DOTS} </h4>
                                            </div>
                                            <div className='common-body1'>
                                                <p> Gender </p>
                                                <h4 className='text-capitalize'>{currentAppointment?.gender ?? constants.DOTS}   </h4>
                                            </div>
                                            <div className='common-body1'>
                                                <p> Age </p>
                                                <h4>{currentAppointment?.age ?? constants.DOTS} </h4>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: "1rem" }}>
                                            <p>Specialisation Chosen</p>
                                            <span className='text-capitalize'>
                                                {`${(currentAppointment?.service?.service_master?.name)?.[tKey]}` ?? constants.DOTS}
                                            </span>
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>
                                            <p>Problem description</p>
                                            <span>
                                                {currentAppointment?.description ?? constants.DOTS}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="view-attach">

                                        <div style={{ marginLeft: "1rem" }} >
                                            <p>Attachments</p>

                                            <div className="viewdicom">
                                                <DicomModal study_path={currentAppointment?.study_path} />
                                                {/* <button className="print-btn"> <img src={print} alt="" className="d-file" />  Print Images </button> */}
                                            </div>

                                            {/* for iterating non images files */}
                                            {
                                                attachmentsOthers.map(
                                                    attachment => {
                                                        return (
                                                            <div className="attach-btn">
                                                                <button
                                                                    className='pdf-btn'
                                                                    onClick={() => handleOpenImage(attachment?.filePath)}
                                                                >
                                                                    <img src={file} alt="" className="d-file" />
                                                                    {attachment?.fileName}
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                )
                                            }

                                            <ImageList
                                                sx={{
                                                    width: 400,
                                                    height: 250,
                                                }}
                                                cols={3}
                                                rowHeight={150}
                                            >
                                                {/* for iterating image files */}
                                                {
                                                    attachmentsImages.map(
                                                        (attachment, index) => {
                                                            return (
                                                                <ImageListItem
                                                                    key={index}
                                                                    sx={{
                                                                        position: 'relative',
                                                                        borderRadius: '10px',
                                                                        mx: 1,
                                                                    }}
                                                                    onClick={() => handleOpenImage(attachment?.filePath)}
                                                                >
                                                                    <PhotoCameraIcon
                                                                        sx={{
                                                                            color: '#2F77B6',
                                                                            position: 'absolute',
                                                                            top: 'calc(50% - 10px)',
                                                                            left: 'calc(50% - 10px)',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={attachment?.filePath}
                                                                        loading="lazy"
                                                                    />
                                                                </ImageListItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </ImageList>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cancel-btn">
                                {/* Reject button */}
                                <button
                                    className="reject-btn"
                                    onClick={() => rejectHandle(currentAppointment.appointmentId)}
                                >
                                    <i className="fa-regular fa-circle-xmark"></i>
                                    Reject
                                </button>
                                {/* Accept button */}
                                <div className='wet-content-btn'>
                                    {/* <ReshecduleModal /> */} {/* REMOVED FOR THE TIME BEING ONLY FOR DOCTOR SIDE */}
                                    <button
                                        onClick={handleModalOpen}
                                        className="accept-btn"
                                    >
                                        Accept & Write Wet Report
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutTwo>
        </>
    )
}

export default DoctorReviewReportView