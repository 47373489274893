import { BiFile } from 'react-icons/bi'
import { MdRefresh } from 'react-icons/md'
import '../AppointmentRecordView/view.css'
import { FaAngleLeft } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { LayoutTwo } from '../../../layout/Layout';
import file from '../../../assets/images/file.png';
import user from '../../../assets/images/usermeet.png';
import RescheduledModal from '../Modal/RescheduleModal';
import { dateFormatWW, destructureSlot, formatFromToTimeSlot, formatTime } from '../../../utils';
import DicomModal from '../../../components/modal/DicomModal';
import { getCaller, updateCaller, postCaller } from '../../../services/api';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { ImageList, ImageListItem } from '@mui/material'
import { appointmentStatus, constants, extensions } from '../../../utils/constants'
import useGlobal from '../../../hooks/useGlobal'


const UserUpcomingViewReport = () => {
    const navigate = useNavigate();
    const { appointmentId } = useParams();
    const { tKey: [tKey] } = useGlobal();

    const [open, setOpen] = React.useState(false);
    const [upcomingReport, setUpcomingReport] = useState({})
    const { start, end } = destructureSlot(upcomingReport)?.slot; /* state?.upcomingData */
    const [attachmentsImages, setAttachmentsImages] = useState([]);
    const [attachmentsOthers, setAttachmentsOthers] = useState([])
console.log("upcomingReport",upcomingReport)


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenImage = path => window.open(path, '_blank')
    const backHandle = () => navigate('/dashboard');


    const handleVideoUser = async () => {
        if (upcomingReport?.status === appointmentStatus['Completed']) {
            return alert('Appointment has already completed')
        }

        const payload = {
            identity: 'patient',
            room: upcomingReport.roomId
        }
        const response = await postCaller('video/token', payload)
        navigate('/newVideo', {
            state: {
                data: response?.data,
                commonData: upcomingReport,
                type: 'user'
            }
        })
    }


    const cancelHandle = async (id) => {
        if (window.confirm('Are you sure you want to cancel')) {
            const resp = await updateCaller(`updateappointment/${id}?data=Cancelled`)
            if (resp.message) {
                toast.success("Appointment Cancelled By User")
                setTimeout(() => {
                    navigate('/dashboard')
                    localStorage.setItem('index', "0")
                }, 3000);
            } else {
                toast.error("Appointment Cancelled By User")
            }
        }
    }


    const fetchAppointmentDetails = async () => {
        const _response = await getCaller(`getOneAppointment/${appointmentId}`)
        setUpcomingReport(_response?.data)
    }

    const fetchAttachments = async () => {
        try {
            const response = await getCaller(`getOneFile/${appointmentId}`)
            let images = [];
            let others = [];
            Array.isArray(response?.data) && response.data.forEach(
                attachment => {
                    const extension = attachment?.fileName?.split('.').pop();
                    extensions.IMAGES.includes(extension) ? images.push(attachment) : others.push(attachment)
                }
            )
            setAttachmentsImages(images)
            setAttachmentsOthers(others)
        }
        catch (error) {
            console.error(error);
        }
    }


    const _showDate = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return dateFormatWW(slot?.date)
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const _formatTime = data => {
        if (Array.isArray(data?.slot)) {
            const [slot] = data?.slot;
            if (slot) {
                return `${formatTime(slot?.time?.start)} - ${formatTime(slot?.time?.end)}`
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    useEffect(() => {
        fetchAppointmentDetails()
        fetchAttachments()
    }, [])

    return (
        <>
            <LayoutTwo>
                <div className="view">

                    <button onClick={backHandle} className="back-btn"> <FaAngleLeft /> Back </button>
                    <div className="view-meet">
                        <div className="view-meet-content">
                            <div className="view-user-meet">
                                <img src={user} alt="" />
                                <div className='user-meet-content'>
                                    <h4> Meet the Doctor </h4>
                                    <p>
                                        Click on the joining info link at the time of your appointment and meet the doctor
                                    </p>
                                </div>
                            </div>

                            <div className="meet-btn">
                                <button onClick={handleVideoUser} >Click Here To Meet</button>
                            </div>
                        </div>
                    </div>

                    <div className="view-app-detail">
                        <h3>Appointment Details</h3>
                        <div className='common-card'>
                            <div className='common-body'>
                                <p> Appointment ID </p>
                                <h4>{upcomingReport?.appointmentId ?? "n/a"} </h4>
                            </div>
                            <div className='common-body1'>
                                <p> Appointment Date & Time </p>
                                <h4>
                                    {dateFormatWW(upcomingReport?.slotDate)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS}
                                    {/* {dateFormatWW(upcomingReport)} - {formatFromToTimeSlot(start, end, 'to', false) || constants.DOTS} */}
                                </h4>
                            </div>
                            <div className='common-body1'>
                                <p> Booking Date </p>
                                <h4> {`${Array.isArray(upcomingReport?.slot) && dateFormatWW(upcomingReport?.createdAt)}`}   </h4>
                            </div>
                            <div className='common-body1'>
                                <p> Appointment Status </p>
                                <h4 style={{ color: "#43AFE9" }}>  {upcomingReport?.status?.toUpperCase() ?? "n/a"} </h4>
                            </div>
                        </div>

                        <div className="view-patient-deatail">
                            <h3>Doctor Details</h3>
                            <div className='common-card'>
                                <div className='common-body'>
                                    <p>Name</p>
                                    <h4>{upcomingReport?.doctor_profile?.name ?? "n/a"} </h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Years of Experience </p>
                                    <h4> {upcomingReport?.doctor_profile?.experience ?? "n/a"} </h4>
                                </div>
                                <div className='common-body1'>
                                    <p> Specialisation </p>
                                    <h4 className='text-capitalize'>
                                        {`${(upcomingReport?.service?.service_master?.name?.[tKey])}, ${(upcomingReport?.service?.name?.[tKey])}` ?? constants.DOTS ?? "n/a"}
                                    </h4>
                                </div>

                            </div>
                        </div>


                        <div className="view-patient-deatail">
                            <h3>Patient Details</h3>
                            <div className="view-doc-detail">
                                <div className="doc-content">
                                    <div className='common-card'>
                                        <div className='common-body'>
                                            <p> Patient ID  </p>
                                            <h4>{upcomingReport?.appointmentId ?? "n/a"} </h4>
                                        </div>
                                        <div className='common-body1'>
                                            <p> Patient Name </p>
                                            <h4> {upcomingReport?.patientName ?? "n/a"} </h4>
                                        </div>
                                        <div className='common-body1'>
                                            <p> Gender </p>
                                            <h4>{upcomingReport?.gender ?? "n/a"}   </h4>
                                        </div>
                                        <div className='common-body1'>
                                            <p> Age </p>
                                            <h4>{upcomingReport?.age ?? "n/a"} </h4>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "1rem" }}>
                                        <p>Specialisation Chosen</p>
                                        <span className='text-capitalize'> {`${(upcomingReport?.service?.service_master?.name?.[tKey])}` ?? constants.DOTS ?? "n/a"} </span>
                                    </div>
                                    <div style={{ marginTop: "1rem" }}>
                                        <p>Problem description</p>
                                        <span>
                                            {upcomingReport?.description}
                                        </span>
                                    </div>
                                </div>
                                <div className="view-attach">
                                    <div style={{ marginLeft: "1rem" }} >
                                        <p>Attachments</p>
                                        {/* for iterating non images files */}
                                        {
                                            attachmentsOthers.map(
                                                attachment => {
                                                    return (
                                                        <div className="attach-btn">
                                                            <button
                                                                className='pdf-btn'
                                                                onClick={() => handleOpenImage(attachment?.filePath)}
                                                            >
                                                                <img src={file} alt="" className="d-file" />
                                                                {attachment?.fileName}
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }

                                        <ImageList
                                            sx={{
                                                width: 400,
                                                height: 400,
                                            }}
                                            cols={3}
                                            rowHeight={150}
                                        >
                                            {/* for iterating image files */}
                                            {
                                                attachmentsImages.map(
                                                    (attachment, index) => {
                                                        return (
                                                            <ImageListItem
                                                                key={index}
                                                                sx={{
                                                                    position: 'relative',
                                                                    borderRadius: '10px',
                                                                    mx: 1,
                                                                }}
                                                                onClick={() => handleOpenImage(attachment?.filePath)}
                                                            >
                                                                <PhotoCameraIcon
                                                                    sx={{
                                                                        color: '#2F77B6',
                                                                        position: 'absolute',
                                                                        top: 'calc(50% - 10px)',
                                                                        left: 'calc(50% - 10px)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                />
                                                                <img
                                                                    src={attachment?.filePath}
                                                                    loading="lazy"
                                                                />
                                                            </ImageListItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </ImageList>
                                    </div>

                                    <div className="viewdicom">
                                        <DicomModal />
                                        {/* <button className="print-btn"> <img src={print} alt="" className="d-file" />  Print Images </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cancel-btn">
                            <button className="c-btn" onClick={() => cancelHandle(upcomingReport.appointmentId)} > <i className="fa-regular fa-circle-xmark"></i> Cancel Appointment</button>
                            <div className="">
                                <button onClick={() => handleOpen()} className='r-btn-1' > <MdRefresh style={{ fontSize: "1.5rem", marginRight: "0.5rem" }} />  Reschedule </button>
                            </div>
                            <RescheduledModal
                                open={open}
                                handleClose={handleClose}
                                currentAppointment={upcomingReport}
                            />
                        </div>
                    </div>

                </div>
            </LayoutTwo>
        </>
    )
}

export default UserUpcomingViewReport