
// import React, { useTransition } from 'react';
import React from 'react';
import '../about/about.css';
import Teams from './Teams/Teams';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';
import Layout from '../../layout/Layout';
import { useTranslation } from 'react-i18next';
import { generateImageURL } from '../../utils';
import design from '../../assets/images/design.png';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { fetchAboutContentRQ, fetchTeams, fetchTeamsRQ } from './Call';
import useGlobal from '../../hooks/useGlobal';

const About = () => {
    const { tKey: [tKey] } = useGlobal();
    const { t } = useTranslation()

    const [aboutData, setAboutData] = React.useState(null);
    const [teams, setTeams] = React.useState([]);



    const fetchTeams = async () => {
        const response = await fetchTeamsRQ();
        if (response?.data) {
            setTeams(response.data.rows || []);
        }
    }

    const fetchAboutContent = async () => {
        const response = await fetchAboutContentRQ();
        setAboutData(response?.data || {})
    }


    React.useEffect(() => {
        fetchTeams();
        fetchAboutContent();
    }, [])


    return (
        <Layout>
            <div className='about'>
                <Breadcrumb head="About Us" />
                <div className='about-container'>
                    <h2 className='h-about'>
                        {aboutData?.page_name && aboutData?.page_name[tKey]}
                    </h2>
                    <p className='h-para'>
                        {aboutData?.content?.main_heading && aboutData?.content?.main_heading[tKey]}
                    </p>
                    <div className="about-content">
                        <div className="abt-img">
                            <img
                                src={generateImageURL(aboutData?.content?.['section-1']?.background_image)}
                                alt=""
                            />
                        </div>
                        <div className="abt-img-content">
                            {/* <h2> {t('virtuallyA')} </h2> */}
                            <h2> {aboutData?.content?.['section-1']?.title?.[tKey]} </h2>
                            {/* <p>{t('teleradiology') + " " + t('telehealth')}</p> */}
                            <p>{aboutData?.content?.['section-1']?.subtitle?.[tKey]}</p>
                        </div>
                    </div>
                </div>
                <div className="design-about">
                    <img src={design} alt="" />
                </div>
                <div className='ourteam-container'>
                    <h2> Our Team </h2>
                    <Teams
                        list={teams}
                    />
                </div>
                <div className="about-clinic">
                    <div className="why">
                        <h2>{aboutData?.content?.['section-2']?.title?.[tKey]}</h2>
                        <p>
                            {aboutData?.content?.['section-2']?.subtitle?.[tKey]}
                        </p>
                    </div>
                    <div className="about-clinic-right">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 16 }}>
                                {
                                    Array.isArray(aboutData?.content?.['section-2']?.content) && aboutData?.content?.['section-2']?.content.map((e, i) => {
                                        return (
                                            <>
                                                <Grid item xs={2} sm={4} md={4} key={i} >
                                                    <div className='clinic-img'>
                                                        <Avatar
                                                            variant='square'
                                                            alt={e?.title?.[tKey]}
                                                            src={generateImageURL(e.image)}
                                                        />
                                                        <p className='cl-para'> {e?.title?.[tKey]} </p>
                                                    </div>
                                                </Grid>
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About