import React, { useEffect, useRef, useState } from 'react'


import './bookappointment.css';
import './cardp.css';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Layout from '../../../layout/Layout';
import { getCaller, postCaller } from '../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast_messages } from '../../../utils/constants';
import { Checkbox } from '@mui/material';
import { generateQuery } from '../../../utils';
const PaymentFill = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();

    const appointmentId = state?.data;


    /* DOM refences */
    const remainingBalanceRef = useRef(null);


    const [data, setData] = useState(null)
    const [type, setType] = useState('Card');
    const [activeStep, setActiveStep] = useState(2);
    const [couponCode, setCouponCode] = useState('');
    const [activeIndex, setActiveIndex] = useState(1);
    const [walletBalance, setWalletBalance] = useState("");
    const [remainingBalance, setRemainingBalance] = useState(null);


    const checkActive = (index, className) => activeIndex === index ? className : "";


    async function getWallet() {
        const res = await getCaller('showWalletBalance')
        console.log(res);
        setWalletBalance(res.walletBalance)
    }

    if (typeof window !== "undefined") {
        injectStyle();
    }

    const handleChange = e => {
        setType(e.target.value);
    }

    const calculateAndSetBalance = () => {
        const balance = Number(walletBalance) - Number(data?.finalFees);
        console.log("🚀 ~ file: PaymentFill.jsx:56 ~ calculateAndSetBalance ~ balance", balance < 0)

        balance < 0 && setRemainingBalance(Math.abs(balance) ?? 0)
    }

    const handleClick = (index) => {
        if (index === 3) { /* '3' is for wallet payment */
            calculateAndSetBalance()
        }
        setActiveIndex(index)
    };


    const handlePaymentRedirect = async () => {
        try {
            console.log('couponCode', couponCode)

            const jsonBody = {
                order_id: data.orderId,
                cardAmount: data.finalFees,
                walletAmount: 0,
                email: "dhruv@cloudstok.com",
            }
            if (couponCode.trim() !== '') {
                jsonBody['couponCode'] = couponCode;
            }


            const response = await postCaller('pg/request', jsonBody);
            if (response?.error === false) {
                window.location.href = response?.data?.redirectURL
            } else {
                return toast.info(response?.msg)
            }
        } catch (err) {
            console.log('err :>> ', err);
        }
    }

    const handleCardPayment = () => {
        if (confirmAlert({
            overlayClassName: "overlay-custom-class-name",
            customUI: ({ onClose }) => {
                return (
                    <div className='over'>
                        <div className="over-head">
                            <h5>Are you sure you want to pay SAR ${data?.finalFees}.00 using Debit/Credit Card? </h5>
                            <p> You are getting redirected to payment gateway </p>
                            <div className="over-btn-head">
                                <button className='yes-btn' onClick={handlePaymentRedirect}> Yes </button>
                                <button className='cancel-btn-over' onClick={onClose}> Cancel </button>
                            </div>
                        </div>
                    </div>
                );
            }
        })) { }
    }

    const handlePartialPayment = async () => {
        try {
            const jsonBody = {
                order_id: data.orderId,
                cardAmount: remainingBalance,
                walletAmount: Number(walletBalance),
                email: "dhruv@cloudstok.com",
            }
            if (couponCode.trim() !== '') {
                jsonBody['couponCode'] = couponCode;
            }

            const response = await postCaller('pg/request', jsonBody);
            if (response?.error === false) {
                window.location.href = response?.data?.redirectURL
            } else {
                return toast.info(response?.msg)
            }
        } catch (err) {
            console.log('err :>> ', err);
        }
    }

    const handleWalletPayment = async () => {
        if (type === null) {
            alert('Please select one payment type.');
            return;
        }

        if (remainingBalanceRef.current?.checked === true) {
            /* if block gets executed means there is remaining balance */
            return handlePartialPayment();
        }

        const jsonBody = {
            cardAmount: 0,
            walletAmount: data.finalFees,
            order_id: data.appointmentId,
            email: "dhruv@cloudstok.com",
        }

        if (couponCode.trim() !== '') {
            jsonBody['couponCode'] = couponCode;
        }

        if (walletBalance < data.finalFees) {
            return toast.warn("Your wallet has insuffient balance for transaction.")
        }

        else {
            let confirmAction = window.confirm(`Are you sure you want to pay SAR ${data.finalFees}.00 from you wallet?`);
            if (confirmAction) {
                const response = await postCaller('pg/request', jsonBody);
                if (response?.error === false) {
                    toast.info(response?.msg);
                    setTimeout(() => {
                        let _query = "";
                        const query = generateQuery(_query, response?.data);
                        navigate(`/complete?${query}`)
                    }, 2500);
                } else {
                    toast.error(response?.msg || toast_messages.common.UNKNOWN)
                    return;
                }
            }
        }
    }

    function cancel() {
        // formik.resetForm({ values: '' })
        navigate('/dashboard');
    }

    function handleCoupon(e) {
        const { value } = e.target;
        if (value?.trim && value.trim() !== '') {
            setCouponCode(value)
        }
    }

    const applyCouponCode = () => {
        fetchTransactionCharges({ appointmentId, couponCode })
        if (applyCouponCode) {
            toast.success('Coupon has been applied')
            console.log("🚀 ~ file: PaymentFill.jsx ~ line 156 ~ applyCouponCode ~ couponCode", couponCode)
        }
    }

    const fetchTransactionCharges = async (__payload) => {
        const {
            appointmentId,
            couponCode
        } = __payload;

        let URL = `appointment/charges/${appointmentId}`;

        if (couponCode) {
            URL += `?couponCode=${couponCode}`;
        }

        const response = await getCaller(URL);
        if (response.error === false) {
            if (typeof response?.data.discountFees === 'string') {
                toast.info(response?.data?.discountFees || "Some error occurred")
            }
            setData(response?.data);
        } else {
            setData([])
        }
    }

    const discountOrToast = data => typeof data === 'number' ? true : false;
   

    useEffect(() => {
        if (state === null) {
            navigate('/')
        }
        else {
            getWallet();
            fetchTransactionCharges({ appointmentId });
        }
    }, []);

    return (
        <>
            <Layout>
                <div className='step-c'>

                    <div className="stepper">
                        <div className="stepper-content">
                            <Stepper activeStep={activeStep} >
                                <Step>
                                    <StepLabel>Appointment</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel> Fill Details </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel> Payment </StepLabel>
                                </Step>

                            </Stepper>
                        </div>
                        <div className='step-border' ></div>
                        <div className="third-step-content">
                            <p className='your-sum'>Your Summary</p>
                            <div className="third-step">
                                <div className="third-step-para">
                                    <p>Doctor Fees</p>
                                    <p>Service Tax</p>
                                    <p>Other Tax</p>
                                    {
                                        discountOrToast(data?.discountFees) && <p>Discount Applied</p>
                                    }

                                    <p className='t-amount'>Amount Payable</p>
                                </div>
                                <div className="third-step-para">
                                    <p>SAR {data?.baseFees ? data?.baseFees : "0"}</p>
                                    <p>SAR {data?.serviceTax ? data?.serviceTax : "0"}</p>
                                    <p>SAR {data?.otherTax ? data?.otherTax : "0"}</p>
                                    {
                                        discountOrToast(data?.discountFees) && <p>SAR {data?.discountFees}</p>
                                    }

                                    <p className='t-amount'>SAR {data?.finalFees ? data?.finalFees.toFixed(2) : "0"}</p>
                                </div>
                            </div>
                            <div className="second-choose-p">
                                <p className='choose-para'>{t('choosePmethod')} </p>

                                <div className='second-payment-content'>
                                    <div className={`second-p-card ${checkActive(1, "tab-active")}`}
                                    >
                                        <input type="radio"
                                            onChange={handleChange}
                                            checked={type === "Card"}
                                            value='Card'
                                            // disabled
                                            className={`second-p-card ${checkActive(1, "tab-active")}`}
                                            onClick={() => handleClick(1)}
                                        />
                                        <p className='radio-para'> Card / Net Banking</p>
                                    </div>

                                    {/* Have to merge card/netbanking because it will handled by payment gateway */}
                                    {/* <div className={`second-p-card ${checkActive(2, "tab-active")}`}>
                                        <input type="radio"
                                            onChange={handleChange}
                                            checked={type === "NetBanking"}
                                            value='NetBanking'
                                            className={`second-p-card ${checkActive(2, "tab-active")}`}
                                            onClick={() => handleClick(2)}
                                        />
                                        <p className='radio-para'> NetBanking </p>
                                    </div> */}

                                    <div className={`second-p-card ${checkActive(3, "tab-active")}`}
                                    >
                                        <input type="radio"
                                            onChange={handleChange}
                                            checked={type === "Wallet"}
                                            value='Wallet'
                                            className={`second-p-card ${checkActive(3, "tab-active")}`}
                                            onClick={() => handleClick(3)}
                                        />
                                        <p className='radio-para'> My Wallet </p>
                                    </div>
                                </div>
                            </div>

                            {/* Coupon */}
                            <div className='apply-coupon' style={{ marginLeft: "20px" }}>
                                <p> Apply Coupon </p>
                                <input type="text" name="" id="" className='apply-input' placeholder='Enter' onChange={handleCoupon} />
                                <button className='apply-btn' onClick={applyCouponCode}> Apply </button>
                            </div>

                            <div className={`card-p ${checkActive(1, "tab-active")}`}>

                                <p style={{ color: "#707070", fontSize: "0.875rem" }}>
                                    Amount will be debited from your Card
                                </p>

                                <div className="first-back">
                                    <button className='proceed-btn' onClick={handleCardPayment}> Proceed To Pay </button>
                                </div>

                            </div>

                            {/* NET BANKING */}
                            {/* <div className={`card-p ${checkActive(2, "tab-active")}`}>
                                <div className="card-p-input">
                                    <div className="card-number">
                                        <label htmlFor=""> UserName </label> <br />
                                        <input type="text" name="" id="" placeholder='Enter' className='card-input' />
                                    </div>
                                </div>
                                <div className="card-p-input">
                                    <div className="card-number">
                                        <label htmlFor=""> Password* </label> <br />
                                        <input type="text" name="" id="" placeholder='Enter' className='card-input' />
                                    </div>
                                </div>

                                <div className="first-back">
                                    <button className='proceed-btn'> Proceed To Pay </button>
                                </div>
                            </div> */}

                            <div className={`payment-wallet-card ${checkActive(3, "tab-active")}`}>
                                <div className='card-p-wallet'>
                                    <div className='payment-wallet'>
                                        <p style={{ color: "#707070", fontSize: "0.875rem" }}>Your Wallet Balance</p>
                                        <p style={{ color: "#1A1919", fontSize: "0.875rem" }} >SAR {walletBalance}.00</p>
                                    </div>
                                    <div>
                                        {
                                            remainingBalance && (
                                                <>
                                                    <Checkbox
                                                        inputRef={remainingBalanceRef}
                                                        id='remainingPayment'
                                                    />
                                                    <label className='not-selectable' htmlFor='remainingPayment'>
                                                        Would you like to pay, remaining <b>{remainingBalance}.00</b> SAR from Card ?
                                                    </label>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="first-back">
                                        <button className='proceed-btn'
                                            onClick={handleWalletPayment}
                                        > Make Payment </button>
                                    </div>
                                </div>
                                <p style={{ color: "#707070", fontSize: "0.875rem" }}>
                                    Amount will be debited from your wallet balance
                                </p>
                            </div>

                        </div>
                        <div style={{ borderBottom: "1px solid #F0F0F0" }} ></div>
                        <div className="first-btn-content">
                            <button className='step-cancel-btn' onClick={() => cancel()} >
                                {t('cancel')}
                            </button>
                            <div className="first-back" style={{ marginRight: "15px" }}>
                                <button className='back' onClick={() => navigate('/filldetails')}> Back </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default PaymentFill;