import { useParams, useSearchParams } from "react-router-dom";
import NewChat from "../newVideo/NewChat";

const PopupChat = () => {
    const { roomId } = useParams();
    const [params] = useSearchParams();

    const email = params.get('email');
    return (
        <>
            <NewChat
                userType={'user'}

                /* DIRECT PROPS */
                _email={email}
                _roomId={roomId}
                /* DIRECT PROPS */

                direct={true}
                attatchment={false}
                popup={true}
            />
        </>
    )
}


export default PopupChat;