import { postCaller } from "../../services/api"

export const getHomepageActiveCats = async id => {
    try {
        const response = await postCaller(`get-homepage-active-categories?serviceMasterId=${id}`);
        if (!response.error) {
            return response?.data;
        } else {
            return false;
        }
    }
    catch (e) {
        console.warn("some error occured while getting categories")
        return false;
    }
}