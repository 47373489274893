import React, { useEffect, useRef, useState } from "react";
import "../doctorEditProfile/edit.css";
import LayoutTwo from "../../../layout/Layout";
import { getCaller, updateCaller } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { profiles, doctorProfileSettings, initialAvilability } from "../../../utils/constants";
import moment from 'moment';
import { checkAvailability, generateImageURL, getFormData } from "../../../utils";
import { ThemeButton } from "../../../components/common/Button";
import useGlobal from "../../../hooks/useGlobal";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { TextField } from "@mui/material";


const EditDoctorProfile = () => {
    const { tKey: [tKey] } = useGlobal();

    const navigate = useNavigate();

    /* Time Slot All Ref */
    const checkAllRef = useRef(null);

    const [profileImg, setProfileImg] = useState(null);
    const [serviceData, setServiceData] = useState();
    const [subServiceData, setSubServiceData] = useState([]);
    const [subSelectedCat, setSubSelectedCat] = useState([]);
    const [user, setUser] = useState("");
    const [profileSettingsMaster, setProfileSettingsMaster] = useState({})
    const [masterService, setMasterService] = useState(0);
    const [profilePreview, setProfilePreview] = useState(null)

    if (typeof window !== "undefined") {
        injectStyle();
    }
    const serviceApi = async () => {
        const serviceRes = await getCaller("getallService");
        setServiceData(serviceRes.data);
    };
    const categoryApi = async (id, previous = false) => {
        if (!id) {
            return;
        }
        const subServiceRes = await getCaller(`getallSubService/${id}`);
        previous && setSubSelectedCat(previous)
        setSubServiceData(subServiceRes.data);
    };

    const handleDropdown = (event) => {
        const { value } = event.target;
        setMasterService(value)
        categoryApi(value);
    };

    const { t } = useTranslation();
    const editPatientSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Name is too small")
            .max(30, "Name is too long")
            .required("Name is required"),
        gender: Yup.string().required("Gender is required"),
        age: Yup.number().required("Age is required").min(1).max(200),
        email: Yup.string()
            .email("Invalid email")
            .required("Email is Required")
            .max(60, "More than 60 character is not allowed"),
        mobile: Yup.string()
            .min(10, "Incorrect mobile number")
            .max(10, "Mobile Number is too long")
            .required("Mobile number is required"),
        location: Yup.string()
            .min(2, "Location is too small")
            .max(30, "Location is too large")
            .required("Location is required"),
        dob: Yup.date().required("Date of birth is required"),
        description: Yup.string()
            .required("About is required")
            .max(500, "Description is allowed only 500 characters"),
        experience: Yup.number().required("Experience is required").min(0).max(99),
        license_no: Yup.string()
            .required("License number is required")
            .max(20, "Licence no. more than 20 characters is not allowed"),
        fees: Yup.number()
            .required("Appointment Fees is Required")
            .min(0)
            .max(999999999, "More than 999999999 Fees amount is not allowed"),
        availability: Yup.array()
            .of(
                Yup.object().shape({
                    "day": Yup.string().required('This filed is required'),
                    "day_index": Yup.string().required('This filed is required'),
                    "start": Yup.string().required('This filed is required'),
                    "end": Yup.string().required('This filed is required'),
                },)
            ).required()
    });

    const loadUser = async () => {
        const result = await getCaller("showDoctorProfile");
        console.log('result :>> ', result);

        delete result.data.id;
        delete result.data.allAppointment;
        delete result.data.allpatient;
        delete result.data.call;
        delete result.data.videoCall;
        delete result.data.allAppointment;
        delete result.data.loginId;
        delete result.data.reviews;
        delete result.data.license_url;
        delete result.data.isActive;
        delete result.data.createdAt;
        delete result.data.updatedAt;

        let avail = null;
        if (result?.data?.availability?.length > 0) {
            avail = result.data.availability?.map(
                _avail => {
                    return {
                        ..._avail,
                        start: moment(_avail.start, 'HH:mm:ss'),
                        end: moment(_avail.end, 'HH:mm:ss')
                    }
                }
            )
        }

        formik.setValues((values) => ({
            ...values,

            mobile: result?.data?.mobile ?? "",
            name: result?.data?.name ?? "",
            email: result?.data?.email ?? "",
            dob: result?.data?.dob ?? "",
            age: result?.data?.age ?? "",
            gender: result?.data?.gender ?? "",
            description: result?.data?.description ?? "",
            license_no: result?.data?.license_no ?? "",
            experience: result?.data?.experience ?? "",
            location: result?.data?.location ?? "",
            specialization: result?.data?.specialization ?? "",
            subServiceId: result?.data?.subServiceId ?? "",
            fees: result?.data?.fees ?? "",
            // schedule: result?.data?.schedule,
            // availability: (result?.data?.availability.length > 0 && result?.data?.availability) || initialAvilability,
            availability: avail || initialAvilability,
        }));

        previousMasterService(result);
        setUser(result.data);
        setProfilePreview(generateImageURL(result?.data?.url))
    };

    const previousMasterService = result => {
        const { serviceMasterId, serviceId } = result.data?.subService || {};
        setMasterService(serviceMasterId)
        categoryApi(serviceMasterId, serviceId)
    }

    const getProfileMaster = async () => {
        const response = await getCaller(`master/profile-settings?type=${profiles.doctor}`)
        setProfileSettingsMaster(response.data || {})
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            gender: "",
            age: "",
            mobile: "",
            email: "",
            location: "",
            dob: "",
            description: "",
            experience: "",
            license_no: "",
            fees: "",
            specialization: "",
            subServiceId: "",
            schedule: [],
            availability: initialAvilability
        },
        validationSchema: editPatientSchema,
        onSubmit: async (values, { setFieldError }) => {
            try {

                const _availablity = [...values.availability.map(
                    avail => {
                        return {
                            ...avail,
                            start: avail.start?.format("HH:mm"),
                            end: avail.end?.format("HH:mm")
                        }
                    }
                )]

                const { isPassed, index } = validate_time_slots(_availablity)
                if (!isPassed) {
                    setFieldError(`availability[${index}].start`, 'Slot Should be on interval of 20 minutes')
                    setFieldError(`availability[${index}].end`, 'Slot Should be on interval of 20 minutes')
                    return;
                };


                /* deleting availability */
                delete values.availability;


                /* setting manually */
                values.experience = "" + values.experience;
                values.specialization = subSelectedCat.toString();
                values.subServiceId = Number(subSelectedCat) || null;
                values.mobile = values.mobile.toString()

                const formData = getFormData(values);

                /* Setting Mannully */
                formData.append('schedule', `[{${values.schedule}}]`)
                /* update profile API */
                let response = await updateCaller(`updateDoctorProfile`, values);
                if (response) {

                    /* update availability API */
                    const _response = await updateCaller('profile/availability', { availability: _availablity })
                    await handleProfileUpload();
                    navigate("/dashboard");
                    localStorage.setItem("index", "0");
                } else {
                    alert("Doctor Profile Not Updated");
                }
            } catch (err) {
                console.error(err);
            }
        }
    });

    const validate_time_slots = slots => {
        const slots_interval = 20;

        let isPassed = true;
        let index = '';
        slots.forEach((slot, _index) => {
            if (isPassed === false) return;

            if (!(slot.start) || !(slot.end)) {
                isPassed = false;
                return;
            }

            const start = moment(slot.start, 'HH:mm');
            const end = moment(slot.end, 'HH:mm');

            if (end && start) {
                const duration = moment.duration(end.diff(start));
                const minutes = duration.asMinutes();

                if (
                    minutes &&
                    (minutes % slots_interval !== 0)
                    || minutes === 0
                ) {
                    index = _index
                    isPassed = false;
                } else {
                    isPassed = true;
                }
            }
        })

        return { isPassed, index };
    }

    const handleProfileUpload = async (event) => {
        let formData = new FormData();
        formData.append("doc", profileImg);
        await updateCaller('profile/image', formData, true)
    };

    const dicardBtn = () => {
        navigate("/dashboard");
    };


    const handleStartTime = (element, index) => {
        console.log("🚀 ~ file: EditDoctorProfile.jsx:288 ~ handleStartTime ~ element", element)
        // const value = element.target.value;
        const _temp = formik.values.availability;
        _temp[index].start = element;

        formik.setFieldValue('availability', [..._temp])
    }

    const handleEndTime = (element, index) => {
        // const value = element.target.value;
        const _temp = formik.values.availability;
        // _temp[index].end = value;
        _temp[index].end = element

        formik.setFieldValue('availability', [..._temp])
    }

    const handleSlotCheck = (element, index) => {
        const value = element.target.checked;

        const _temp = formik.values.availability;
        _temp[index].status = value;

        formik.setFieldValue('availability', [..._temp])
    }

    const formatSlot = slot => {
        return moment(slot, "HH:mm:ss").format("HH:mm")
    }

    const handleImageRender = e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setProfileImg(file)
            // if (!checkAvailability(file.type)) { return toast.error("Unsupported File") }

            if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setProfilePreview(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }
    // console.log('subSelectedCat', subSelectedCat)

    /* Handle All Select Day */
    const handleAllSelectDay = ({ target: { checked } }) => {
        const _oldValues = formik.values.availability

        formik.setFieldValue(
            'availability',
            [
                ..._oldValues.map(
                    item => ({ ...item, status: checked })
                )
            ]
        )
    }

    useEffect(() => {
        loadUser();
        getProfileMaster();
        serviceApi();
    }, []);

    useEffect(() => {
        let isPassed = false;
        const avail = formik?.values?.availability;

        for (let i = 0; i < avail?.length; i++) {
            if (avail[i]?.status) {
                isPassed = true;
            } else {
                isPassed = false;
                break;
            }
        }

        if (isPassed) {
            checkAllRef.current.checked = true;
        } else {
            checkAllRef.current.checked = false;
        }
    }, [formik.values.availability])


    return (
        <>
            <LayoutTwo>
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <div className="side-back">
                        <Breadcrumb head="Doctor" head1="Account" head2="Edit Profile" />
                        <div className="edit-container">
                            <div className="edit-content">
                                <div className="edit-body">
                                    <h3>Edit Profile Details</h3>
                                    <div className="edit-doctor-img">
                                        <img
                                            src={profilePreview}
                                            alt=""
                                            height="220"
                                            style={{ borderRadius: "8px" }}
                                        />
                                        <input
                                            type="file"
                                            id="uploadImage"
                                            hidden
                                            onChange={handleImageRender}
                                            accept="image/*"
                                        />
                                        <label
                                            style={{
                                                cursor: "pointer",
                                                color: "#2f77b6",
                                            }}
                                            htmlFor="uploadImage"
                                        >
                                            {t("changePhoto")}
                                        </label>
                                    </div>
                                </div>
                                <div className="edit-body-right">
                                    <div className="edit-form">
                                        <div className="edit-name">
                                            <label htmlFor="name">
                                                {profileSettingsMaster[doctorProfileSettings['Name']]?.label?.[tKey]}
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Name']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.name && formik.errors.name && (
                                                    <span className="error">{formik.errors.name}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="edit-select">
                                            <label htmlFor="gender">
                                                {profileSettingsMaster[doctorProfileSettings['Gender']]?.label?.[tKey]}
                                            </label>
                                            <select
                                                id="gender"
                                                name="gender"
                                                className="edit-select-field"
                                                onChange={formik.handleChange}
                                                value={formik.values.gender}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Gender']]?.status == 1 ? false : true}
                                            >
                                                <option value=""> Select </option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>

                                            </select>
                                            <div>
                                                {formik.touched.gender && formik.errors.gender && (
                                                    <span className="error">{formik.errors.gender}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="edit-age">
                                            <label htmlFor="age">
                                                {profileSettingsMaster[doctorProfileSettings['Age']]?.label?.[tKey]}
                                            </label>
                                            <input
                                                type="number"
                                                id="age"
                                                name="age"
                                                placeholder="Enter"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                onKeyDown={(evt) =>
                                                    evt.key === "e" && evt.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                value={formik.values.age}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Age']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.age && formik.errors.age && (
                                                    <span className="error">{formik.errors.age}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="edit-form">
                                        <div className="edit-mobile">
                                            <label htmlFor="mnumber">
                                                {profileSettingsMaster[doctorProfileSettings['Mobile number']]?.label?.[tKey]}
                                            </label>
                                            <input
                                                type="number"
                                                id="mnumber"
                                                name="mobile"
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter Mobile Number"
                                                onChange={formik.handleChange}
                                                onKeyDown={(evt) =>
                                                    evt.key === "e" && evt.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                value={formik.values.mobile}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Mobile number']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.mobile && formik.errors.mobile && (
                                                    <span className="error">{formik.errors.mobile}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="edit-email">
                                            <label htmlFor="email">
                                                {profileSettingsMaster[doctorProfileSettings['Email id']]?.label?.[tKey]}
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Email id']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.email && formik.errors.email && (
                                                    <span className="error">{formik.errors.email}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="edit-form">
                                        <div className="edit-mobile">
                                            <label htmlFor="location">
                                                {profileSettingsMaster[doctorProfileSettings['Location']]?.label?.[tKey]}
                                            </label> <br />
                                            <input
                                                type="text"
                                                id="location"
                                                name="location"
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter Location"
                                                onChange={formik.handleChange}
                                                value={formik.values.location}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Location']]?.status ? false : true}
                                            />
                                            <div>
                                                {formik.touched.location && formik.errors.location && (
                                                    <span className="error">
                                                        {formik.errors.location}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="edit-email">
                                            <label htmlFor="dob">
                                                {profileSettingsMaster[doctorProfileSettings['Date of Birth']]?.label?.[tKey]}
                                            </label> <br />
                                            <input
                                                type="date"
                                                id="dob"
                                                name="dob"
                                                placeholder="Enter Date"
                                                onChange={formik.handleChange}
                                                value={formik.values.dob}
                                                className="edit-select-field"
                                                disabled={profileSettingsMaster[doctorProfileSettings['Date of Birth']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.dob && formik.errors.dob && (
                                                    <span className="error">{formik.errors.dob}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="edit-textarea">
                                        <label htmlFor="desc">
                                            {profileSettingsMaster[doctorProfileSettings['About']]?.label?.[tKey]}
                                        </label> <br />
                                        <textarea
                                            name="description"
                                            id="desc"
                                            placeholder="Describe your profile bio here"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.description}
                                            disabled={profileSettingsMaster[doctorProfileSettings['About']]?.status == 1 ? false : true}
                                        ></textarea>
                                        <div>
                                            {formik.touched.description &&
                                                formik.errors.description && (
                                                    <span className="error">
                                                        {formik.errors.description}
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="edit-form">
                                        <div className="edit-email">
                                            <label htmlFor="year">
                                                {profileSettingsMaster[doctorProfileSettings['Years of experience']]?.label?.[tKey]}
                                            </label>
                                            <input
                                                type="number"
                                                name="experience"
                                                id="year"
                                                placeholder="Enter experience"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                onKeyDown={(evt) =>
                                                    evt.key === "e" && evt.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                value={formik.values.experience}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Years of experience']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.experience &&
                                                    formik.errors.experience && (
                                                        <span className="error">
                                                            {formik.errors.experience}
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="edit-file">
                                            <label htmlFor="upload">
                                                {profileSettingsMaster[doctorProfileSettings['Attached File']]?.label?.[tKey]}
                                            </label> <br />
                                            <input
                                                type="file"
                                                name="url"
                                                id="upload"
                                                placeholder="Upload"
                                                className="file-input"
                                                onChange={(event) => {
                                                    handleProfileUpload(event);
                                                }}

                                            />
                                            <div>
                                                {formik.touched.url && formik.errors.url && (
                                                    <span className="error">{formik.errors.url}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="edit-form">
                                        <div className="edit-email">
                                            <label htmlFor="special">
                                                {profileSettingsMaster[doctorProfileSettings['Specialisation']]?.label?.[tKey]}
                                            </label> <br />
                                            <select
                                                name="specialization"
                                                className="edit-select-field"
                                                onChange={(e) => {
                                                    handleDropdown(e);
                                                }}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Specialisation']]?.status == 1 ? false : true}
                                                value={masterService}
                                            >
                                                <option value={0}> Choose Services </option>
                                                {
                                                    serviceData &&
                                                    serviceData.length > 0 &&
                                                    serviceData.map((value, index) => {
                                                        return (
                                                            <option
                                                                selected={value.serviceMasterId === user?.subService?.service_master?.serviceMasterId}
                                                                key={`service-data-${index}`}
                                                                value={value.serviceMasterId}
                                                            >
                                                                {value.services?.[tKey]}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                            {subServiceData.length > 0 && (
                                                <select
                                                    className="edit-select-field"
                                                    value={subSelectedCat}
                                                    onChange={
                                                        element => {
                                                            const { value } = element.target;
                                                            setSubSelectedCat(value)
                                                            formik.setFieldValue('subServiceId', value)
                                                        }
                                                    }
                                                >
                                                    <option value="">Choose Subcategory </option>
                                                    {subServiceData?.map((el, index) => (
                                                        <>
                                                            <option value={el.serviceId} key={index}>
                                                                {el.sub_services?.[tKey]}
                                                            </option>
                                                        </>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                        <div className="edit-file">
                                            <label htmlFor="fees">
                                                {profileSettingsMaster[doctorProfileSettings['Appointment Fees']]?.label?.[tKey]}
                                            </label> <br />
                                            <input
                                                type="number"
                                                name="fees"
                                                id="fees"
                                                placeholder="Appointment Fees"
                                                onChange={formik.handleChange}
                                                onKeyDown={(evt) =>
                                                    evt.key === "e" && evt.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.fees}
                                                disabled={profileSettingsMaster[doctorProfileSettings['Appointment Fees']]?.status == 1 ? false : true}
                                            />
                                            <div>
                                                {formik.touched.fees && formik.errors.fees && (
                                                    <span className="error">{formik.errors.fees}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="edit-saudi">
                                        <label htmlFor="saudi">
                                            {profileSettingsMaster[doctorProfileSettings['Saudi Commission License']]?.label?.[tKey]}

                                        </label>{" "}
                                        <br />
                                        <input
                                            type="text"
                                            id="saudi"
                                            name="license_no"
                                            placeholder="Enter License"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.license_no}
                                            disabled={profileSettingsMaster[doctorProfileSettings['Saudi Commission License']]?.status == 1 ? false : true}
                                        />
                                        <div>
                                            {formik.touched.license_no &&
                                                formik.errors.license_no && (
                                                    <span className="error">
                                                        {formik.errors.license_no}
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="edit-table">
                                        <div style={{ overflowX: "auto" }}>
                                            <table
                                                className="table-content-edit"
                                                style={{ marginTop: "2rem" }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th className='check-day'>
                                                            <input
                                                                id='select-all'
                                                                type='checkbox'
                                                                name='schedule'
                                                                onClick={handleAllSelectDay}
                                                                ref={checkAllRef}
                                                            />
                                                            <label
                                                                className={'not-selectable'}
                                                                style={{
                                                                    textTransform: 'capitalize',
                                                                    paddingLeft: '.5rem'
                                                                }}
                                                                htmlFor={`select-all`}
                                                            >
                                                                Day
                                                            </label>
                                                        </th>
                                                        <th> From </th>
                                                        <th> To </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Array.isArray(formik?.values?.availability)
                                                        && formik?.values?.availability
                                                        && formik?.values?.availability?.map((item, i) => {

                                                            return (
                                                                <React.Fragment key={`availability_slots_${i}`}>
                                                                    <tr>
                                                                        <td className="check-day">
                                                                            <input
                                                                                type='checkbox'
                                                                                checked={item.status || false}
                                                                                onChange={element => handleSlotCheck(element, i)}
                                                                                name={`schedule.${i}.enabled`}
                                                                                id={`availability.label.${i}`}
                                                                            />
                                                                            <label
                                                                                className={'not-selectable'}
                                                                                style={{
                                                                                    textTransform: 'capitalize',
                                                                                    paddingLeft: '.5rem',

                                                                                }}
                                                                                htmlFor={`availability.label.${i}`}
                                                                            >
                                                                                {item?.day}
                                                                            </label>
                                                                        </td>

                                                                        <td>
                                                                            <TimePicker
                                                                                ampm={true}
                                                                                name={`availability-${i}-start`}
                                                                                value={formik.values.availability?.[i]?.start}
                                                                                onChange={element => handleStartTime(element, i)}
                                                                                renderInput={
                                                                                    (params) => (
                                                                                        <TextField
                                                                                            size='small'
                                                                                            // disabled
                                                                                            // onKeyDown={e => e.preventDefault()}
                                                                                            {...params}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            />
                                                                            <div className='error'>
                                                                                <span>
                                                                                    {
                                                                                        formik?.touched?.availability?.[i]?.start
                                                                                        && formik?.errors?.availability?.[i]?.start
                                                                                        && formik?.errors?.availability?.[i]?.start
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <TimePicker
                                                                                ampm={true}
                                                                                name={`availability-${i}-end`}
                                                                                value={formik.values.availability?.[i]?.end}
                                                                                onChange={element => handleEndTime(element, i)}
                                                                                disabled={!(formik?.values?.availability?.[i]?.start)}
                                                                                renderInput={
                                                                                    (params) => (
                                                                                        <TextField
                                                                                            size='small'
                                                                                            error={false}
                                                                                            // disabled
                                                                                            // onKeyDown={e => e.preventDefault()}
                                                                                            {...params}

                                                                                        />
                                                                                    )
                                                                                }
                                                                            />
                                                                            <div className='error'>
                                                                                <span>
                                                                                    {
                                                                                        formik?.values?.availability?.[i]?.start
                                                                                        && formik?.touched?.availability?.[i]?.end
                                                                                        && formik?.errors?.availability?.[i]?.end
                                                                                        && formik?.errors?.availability?.[i]?.end
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-footer">
                                <div className="edit-button-content">
                                    <button
                                        type="button"
                                        className="discard-btn"
                                        onClick={() => {
                                            dicardBtn();
                                        }}
                                    >
                                        Discard
                                    </button>
                                    <ThemeButton
                                        className="save-btn"
                                        type="submit"
                                        text={'Save Changes'}
                                    />
                                    {/* <button type="submit">
                                        {" "}
                                        Save Changes{" "}
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </LayoutTwo>
        </>
    );
};

export default EditDoctorProfile;
