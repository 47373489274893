import * as Yup from 'yup';
import {Box, styled} from "@mui/system";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {updateCaller} from "../../../services/api";
import {Backdrop, Fade, Modal, Typography} from "@mui/material";
import {toast_messages} from "../../../utils/constants";
import {toast} from 'react-toastify';


const RescheduledModal = (
    {
        open,
        handleClose,
        currentAppointment
    }) => {
    const navigate = useNavigate();

    const rescheduleSchema = Yup.object().shape({
        reason: Yup.array().min(1, 'Please select atleast one resson').required('Please select atleast one resson'),
        description: Yup.string()
            .min(10, "Too Short!")
            .max(300, "Too Long!")
            .required("Message is Required")
    })
    const reason = ["Inappropriate images", "Wrong images"];
    const formik = useFormik({
        initialValues: {
            reason: [],
            description: "",
        },
        validationSchema: rescheduleSchema,
        onSubmit: async (values, id) => {
            const response = await updateCaller(`RescheduledByUser/${currentAppointment.appointmentId}`, values)
            if (response?.status === true) {
                navigate(`/bookappointment/${currentAppointment.doctorId}`, {
                    state: {
                        rescheduled: currentAppointment /* Passing bookapppointment route */,
                    }
                })
            } else {
                toast.info(response?.message || toast_messages.common.UNKNOWN)
            }
        }
    })

    const handleChange = (e) => {
        const {checked, name} = e.target;
        if (checked) {
            formik.setFieldValue("reason", [...formik.values.reason, name]);
        } else {
            formik.setFieldValue(
                "reason",
                formik.values.reason.filter((v) => v !== name)
            );
        }
    };


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <form action="" onSubmit={formik.handleSubmit}>
                    <StyledBox>
                        <div className="rescPopup">
                            <h4>Reschedule Appointment</h4>
                        </div>
                        <div id="transition-modal-description" style={{padding: '2rem'}}>
                            {/* Select at least one reason */}
                            <p style={{color: "#707070"}}>Select Reason</p>
                            {
                                reason.map(
                                    (tag) => (
                                        <div className='rescCheck' key={tag}>
                                            <input
                                                id={tag}
                                                type="checkbox"
                                                name={tag}
                                                checked={formik.values.reason.includes(tag)}
                                                onChange={handleChange}
                                                style={{margin: '0 .5rem'}}
                                            />
                                            <label htmlFor={tag}>{tag}</label>
                                        </div>
                                    )
                                )
                            }
                            <span className="error">
                                {
                                    formik.touched.reason
                                    && formik.errors.reason
                                    && formik.errors.reason
                                }
                            </span>

                            {/* Descriptions Textarea */}
                            <div className='reshText'>
                                <label htmlFor="Comment" style={{color: "#707070"}}> Comments </label> <br/>
                                <textarea name="description" id="" placeholder='Describe reason'
                                          value={formik.values.description}
                                          onChange={formik.handleChange}
                                ></textarea>
                                <span className="error">
                                    {
                                        formik.touched.description
                                        && formik.errors.description
                                        && formik.errors.description
                                    }
                                </span>
                            </div>

                        </div>
                        <div className="reshbtn-content">
                            <button className='resh-cancel' onClick={handleClose}> Cancel</button>
                            <button type='submit' className='resh-submit'> Submit</button>
                        </div>
                    </StyledBox>
                </form>
            </Fade>
        </Modal>
    )
}


const StyledBox = styled(Box)(
    ({theme}) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: "100%",
        maxHeight: 600,
        overflow: "hidden",
        borderRadius: "1rem",
        bgcolor: 'white',
        display: "block",
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    })
)

export default RescheduledModal