import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../DoctorDashboard/doctorEditProfile/edit.css'
import { LayoutTwo } from '../../../layout/Layout'
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { getCaller, updateCaller } from '../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";
import { profiles, userProfileSettings } from '../../../utils/constants';
import { checkAvailability, generateImageURL } from '../../../utils';
import { ThemeButton, UnstyledButton } from '../../../components/common/Button';
import useGlobal from '../../../hooks/useGlobal';



const MAX_FAMILY_MEMBERS = 5;

const UserProfileEdit = () => {

    const { tKey: [tKey] } = useGlobal();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [profileImg, setProfileImg] = useState(null);
    const [profilePreview, setProfilePreview] = useState(null)


    /* ISOLATED STATES */
    const [profileSettingsMaster, setProfileSettingsMaster] = useState({});

    /* One liners */
    const getBackToDashboard = () => navigate('/dashboard/user/account');

    const { t } = useTranslation();
    async function getUserData() {
        const resp = await getCaller('showPatientProfile');
        setUser(resp.data);
        delete resp.data.id;
        delete resp.data.loginId;
        delete resp.data.walletId;
        delete resp.data.reviews;
        delete resp.data.license_url;
        // delete resp.data.url;
        delete resp.data.isActive;
        delete resp.data.createdAt;
        delete resp.data.updatedAt;
        formik.setValues(values => ({
            ...values,
            mobile: resp?.data?.mobile ?? "",
            name: resp?.data?.name ?? "",
            gender: resp?.data?.gender ?? "",
            dob: resp?.data?.dob ?? "",
            location: resp?.data?.location ?? "",
            email: resp?.data?.email ?? "",
            age: resp?.data?.age ?? "",
            about: resp?.data?.about ?? "",
            condition: resp?.data?.condition ?? "",
            members: resp?.data?.members ?? []
        }))

        setProfilePreview(generateImageURL(resp?.data?.url))

    }

    const uploadProfileImage = async (event) => {
        let formData = new FormData();
        formData.append("doc", profileImg);
        await updateCaller('profile/image', formData, true)
        // const response = await fetch(`${process.env.REACT_APP_BASE_URL}/uploadFile`,
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('token')}`
        //         },
        //         body: formData
        //     }
        // );
        // getUserData();
    }


    const editPatientSchema = Yup.object().shape({
        name: Yup.string().min(2, "Name is too small").max(30, "Name is too long").required('Name is required'),
        gender: Yup.string().required('Gender is required'),
        age: Yup.number().min(1, "Incorrect Age").max(999, "Age is too long").required('Age is required'),
        mobile: Yup.string().min(10, 'Incorrect mobile number').required('Mobile number is required'),
        location: Yup.string().min(2, "Location is too small").required('Location is required'),
        dob: Yup.date().required('Date of birth is required'),
        email: Yup.string().required('Email is required'),
        about: Yup.string().required('About is required'),
        condition: Yup.string().required('Health Condition is required'),
        members: Yup.array()
    })

    const formik = useFormik({
        initialValues: {
            name: "",
            gender: "",
            age: "",
            mobile: "",
            email: "",
            location: "",
            dob: '',
            about: "",
            condition: "",
            members: [
                {
                    name: "",
                    gender: "",
                    age: ''
                }
            ]
        },
        validationSchema: editPatientSchema,
        onSubmit: async (values) => {
            const response = await updateCaller('addPatientProfile', values)
            if (response && response.status.toLowerCase() === 'success') {
                await uploadProfileImage();
                getBackToDashboard()
            }
            else {
                alert("Patient Profile Not Updated")
            }

        }
    })
    if (typeof window !== "undefined") {
        injectStyle();
    }


    function deleteMembers(i) {
        const members = formik.values.members.splice(i, 1);
        formik.setFieldValue('members', [...formik.values.members])
    }


    const getProfileMaster = async () => {
        let response = await getCaller(`master/profile-settings?type=${profiles.user}`)
        setProfileSettingsMaster(response?.data || {})
    }

    const handleImageRender = e => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0];
            setProfileImg(file)

            if (!checkAvailability(file.type)) { return alert("Unsupported File") }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let base64data = reader.result
                setProfilePreview(base64data)
                base64data = null;
                file = null;
                reader = null;
            }
        }
    }

    const handleAddMember = () => {
        const currentMembers = formik.values.members;

        if (currentMembers.length >= MAX_FAMILY_MEMBERS) {
            return;
        }
        formik.setFieldValue(
            'members',
            [
                ...currentMembers,
                {
                    name: "",
                    age: "",
                    gender: ""
                }
            ], false
        )
    }

    useEffect(() => {
        getUserData();
        getProfileMaster();
    }, [])

    console.log("🚀 ~ file: UserProfileEdit.jsx:468 ~ UserProfileEdit ~ formik.touched", formik.touched)


    return (
        <>
            <LayoutTwo>

                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <div className="edit-container">
                        <div className="edit-content">
                            <div className="edit-body">
                                <h3>Edit Profile Details</h3>
                                <div className="edit-doctor-img">
                                    <img src={profilePreview} alt="" width="175"
                                        height="175"
                                        style={{ borderRadius: '8px' }}
                                    />
                                    <input type="file" id="uploadImage" hidden onChange={handleImageRender} accept="image/*" />
                                    <label style={{
                                        cursor: 'pointer',
                                    }} htmlFor="uploadImage">{t('changePhoto')}</label>

                                </div>
                            </div>
                            <div className="edit-body-right">
                                <div className="edit-form">
                                    <div className="edit-name">
                                        <label htmlFor="name">
                                            {profileSettingsMaster[userProfileSettings['Name']]?.label?.[tKey]}

                                        </label>
                                        <input type="text" name="name" placeholder="Enter name"
                                            onChange={formik.handleChange}
                                            value={formik.values.name !== null && formik.values.name}
                                            disabled={profileSettingsMaster[userProfileSettings['Name']]?.status == 1 ? false : true}

                                        />
                                        <div>
                                            {formik.touched.name && formik.errors.name && (
                                                <span className="error">{formik.errors.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-select">
                                        <label htmlFor="gender">
                                            {profileSettingsMaster[userProfileSettings['Gender']]?.label?.[tKey]}
                                        </label>
                                        <select value={formik.values.gender !== null && formik.values.gender} name="gender" className='edit-select-field'
                                            onChange={formik.handleChange}
                                            disabled={profileSettingsMaster[userProfileSettings['Gender']]?.status == 1 ? false : true}

                                        >
                                            <option value="" disabled>Select gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <div>
                                            {formik.touched.gender && formik.errors.gender && (
                                                <span className="error">{formik.errors.gender}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-age">
                                        <label htmlFor="age">
                                            {profileSettingsMaster[userProfileSettings['Age']]?.label?.[tKey]}
                                        </label>
                                        <input type="text" name="age" placeholder="Enter age"
                                            onChange={formik.handleChange}
                                            value={formik.values.age !== null && formik.values.age}
                                            disabled={profileSettingsMaster[userProfileSettings['Age']]?.status == 1 ? false : true}
                                        />
                                        <div>
                                            {formik.touched.age && formik.errors.age && (
                                                <span className="error">{formik.errors.age}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="edit-form">
                                    <div className="edit-mobile">
                                        <label htmlFor="mnumber">
                                            {profileSettingsMaster[userProfileSettings['Mobile number']]?.label?.[tKey]}
                                        </label>
                                        <input type="text" name="mobile" placeholder="Enter Mobile Number"
                                            onChange={formik.handleChange}
                                            value={formik.values.mobile !== null && formik.values.mobile}
                                            disabled={profileSettingsMaster[userProfileSettings['Mobile number']]?.status == 1 ? false : true}
                                        />
                                        <div>
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <span className="error">{formik.errors.mobile}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-email">
                                        <label htmlFor="email">
                                            {profileSettingsMaster[userProfileSettings['Email id']]?.label?.[tKey]}

                                        </label>
                                        <input type="text" value={formik.values.email !== null && formik.values.email} name="email" placeholder="Enter Email"
                                            onChange={formik.handleChange}
                                            disabled={profileSettingsMaster[userProfileSettings['Email id']]?.status == 1 ? false : true}

                                        />
                                        <div>
                                            {formik.touched.email && formik.errors.email && (
                                                <span className="error">{formik.errors.email}</span>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="edit-form">
                                    <div className="edit-mobile">
                                        <label htmlFor="location">
                                            {profileSettingsMaster[userProfileSettings['Location']]?.label?.[tKey]}
                                        </label>
                                        <input type="text" name="location" placeholder="Enter location"
                                            value={formik.values.location !== null && formik.values.location}
                                            onChange={formik.handleChange}
                                            disabled={profileSettingsMaster[userProfileSettings['Location']]?.status == 1 ? false : true}

                                        />
                                        <div>
                                            {formik.touched.location && formik.errors.location && (
                                                <span className="error">{formik.errors.location}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="edit-email">
                                        <label htmlFor="date">
                                            {profileSettingsMaster[userProfileSettings['Date of Birth']]?.label?.[tKey]}
                                        </label>
                                        <input type="date" name="dob" placeholder="" className='user-date'
                                            onChange={formik.handleChange}
                                            value={formik.values.dob !== null && formik.values.dob}
                                            disabled={profileSettingsMaster[userProfileSettings['Date of Birth']]?.status == 1 ? false : true}

                                        />
                                        <div>
                                            {formik.touched.location && formik.errors.location && (
                                                <span className="error">{formik.errors.location}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="edit-textarea">
                                    <label htmlFor="">
                                        {profileSettingsMaster[userProfileSettings['About']]?.label?.[tKey]}
                                    </label> <br />
                                    <textarea
                                        name="about"
                                        placeholder='Enter the description'
                                        value={formik.values.about !== null && formik.values.about}
                                        onChange={formik.handleChange}
                                        disabled={profileSettingsMaster[userProfileSettings['About']]?.status == 1 ? false : true}

                                    ></textarea>
                                    <div>
                                        {formik.touched.about && formik.errors.about && (
                                            <span className="error">{formik.errors.about}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="edit-form">
                                    <div className="edit-mobile">
                                        <label htmlFor="special">
                                            {profileSettingsMaster[userProfileSettings['Health Condition']]?.label?.[tKey]}
                                        </label>
                                        <select name="condition" className='edit-select-field'
                                            onChange={formik.handleChange}
                                            value={formik.values.condition !== null && formik.values.condition}
                                            disabled={profileSettingsMaster[userProfileSettings['Health Condition']]?.status == 1 ? false : true}

                                        >
                                            <option value="" disabled>Select Health Condition</option>
                                            <option value="Daiabetic, Backbone issues"> Daiabetic, Backbone issues </option>
                                            <option value="radiology issues"> Radiology issues </option>
                                        </select>
                                        <div>
                                            {formik.touched.condition && formik.errors.condition && (
                                                <span className="error">{formik.errors.condition}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="edit-table">
                                    <div style={{
                                        display: 'flex', justifyContent: 'space-between',
                                        padding: '0.5rem  0rem'
                                    }}>
                                        <p>
                                            {profileSettingsMaster[userProfileSettings['Family Members']]?.label?.[tKey]}
                                        </p>
                                        <ThemeButton
                                            className='save-btn'
                                            onClick={handleAddMember}
                                            disabled={formik.values.members?.length >= MAX_FAMILY_MEMBERS}
                                            text='Add Member'
                                        />
                                        {/* <button
                                            type='button'
                                        >
                                            Add Member
                                        </button> */}
                                    </div>
                                    <div style={{ overflowX: "auto" }}>
                                        <table className='family-table'>
                                            <thead>
                                                <tr>
                                                    <th> Name </th>
                                                    <th> Gender </th>
                                                    <th> Age </th>
                                                    <th> Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formik.values.members !== null && formik?.values?.members?.map((e, i) => (
                                                    <tr key={i}>
                                                        <td> <input type="text" name={`members.${i}.name`} id="member-{i}" className='family-input'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.members[i].name}
                                                            placeholder="Enter name"
                                                        /> </td>
                                                        <td>
                                                            <select name={`members.${i}.gender`} id="" className='family-input-select'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.members[i].gender}
                                                            >
                                                                <option value={""} disabled>Select gender </option>
                                                                <option value="male"> Male </option>
                                                                <option value="female"> Female </option>
                                                            </select>
                                                        </td>

                                                        <td>
                                                            <input type="text" name={`members.${i}.age`} id="" className='family-input'
                                                                onChange={formik.handleChange}
                                                                value={formik.values.members[i].age}
                                                                placeholder={"Enter age"}
                                                            />
                                                        </td>
                                                        <td>
                                                            <UnstyledButton
                                                                text='Delete'
                                                                onClick={e => deleteMembers(i)}
                                                                className="save-btn"
                                                                sx={{
                                                                    background: 'crimson'
                                                                }}
                                                            // style={{
                                                            //     background: 'crimson'
                                                            // }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='edit-footer'>
                            <div className='edit-button-content'>
                                <UnstyledButton
                                    onClick={getBackToDashboard}
                                    className='discard-btn'
                                    text='Discard'
                                />
                                <ThemeButton
                                    className='save-btn'
                                    type='submit'
                                    text='Save Changes'
                                    disabled={Object.keys(formik.errors).length > 0}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </LayoutTwo>

        </>
    )
}

export default UserProfileEdit