import { useEffect, useState } from "react";
import Navbar from "../layout/Navbar/Navbar";
import { getCaller } from "../services/api";
import InfiniteScroll from 'react-infinite-scroll-component';
import { generateQuery } from "../utils";
import TeamCard from "../container/about/Teams/TeamCard";

/* CSS */
import './clinicpublicprofile.css'


const ClinicPublicProfile = () => {
    const [data, setData] = useState({ rows: [], count: 0 })
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(1)

    const fetchTeamsListing = async (payload = {}) => {
        try {
            let URL = `public/teams-listing?limit=6&`
            URL = generateQuery(URL, payload);

            const response = await getCaller(URL);

            if (response?.error === false) {
                setRows(rows.concat(response?.data.rows || []));
                setData(response?.data)
                // setData(response?.data)
            }
        } catch (error) {
            console.error("🚀 ~ file: ClinicPublicProfile.jsx:11 ~ fetchTeamsListing ~ error", error)
        }
    }

    const fetchMore = () => setPage(prev => prev + 1)


    useEffect(() => {
        fetchTeamsListing({ page: page })
    }, [page])

    useEffect(() => {
        fetchTeamsListing()
    }, [])

    return (
        <>
            <Navbar />
            <div className="heading">
                Our Teams
            </div>
            <div className="scrollable-div">
                <InfiniteScroll
                    dataLength={data.count} //This is important field to render the next data
                    next={fetchMore}
                    hasMore={data.count !== data.rows.length}
                    // loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    {
                        rows?.map(
                            (item, index) => {
                                return <TeamCard key={`team-card-${index}`} data={item} />
                            }
                        )
                    }
                </InfiniteScroll>
            </div>

        </>

    )
}

export default ClinicPublicProfile;