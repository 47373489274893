export const toast_messages = {
    common: {
        UNKNOWN: 'Some error unknown occured'
    }
}


export const constants = {
    USER_DATE_FORMAT: "DD-MM-YYYY",
    DATABASE_DATE_FORMAT: "YYYY-MM-DD",
    USER_DATE_FORMAT_WW: "DD MMM YYYY", /* with words */
    USER_DATE_FORMAT_WW_TIME: "DD MMM YYYY, hh:mm A", /* with words */
    N_A: 'N/A',
    DOTS: '...',
}


export const extensions = {
    IMAGES: ['png', 'jpg', 'jpeg']
}

export const fix_pages = {
    'about': 'ABOUT',
    'privacy': 'PRIVACY',
    'contact': 'CONTACT',
    'terms': 'TERMS',
    'homepage': 'HOMEPAGE',
    'logo': "LOGO",
    'social_link': 'SOCIAL_LINK',
    'email': 'EMAIL',
    'address': "ADDRESS"
}

export const profiles = {
    'user': "user",
    'doctor': "doctor",
    'appointment': 'appointment'
}


export const doctorProfileSettings = {
    'Name': 'Name',
    'Gender': 'Gender',
    'Age': 'Age',
    'Specialisation': 'Specialisation',
    'Appointment Fees': 'Appointment Fees',
    'Saudi Commission License': 'Saudi Commission License',
    'About': 'About',
    'Timing Slots': 'Timing Slots',
    'Mobile number': 'Mobile number',
    'Email id': 'Email id',
    'Years of experience': 'Years of experience',
    'Location': 'Location',
    'Date of Birth': 'Date of Birth',
    'Health Condition': 'Health Condition',
    'Attached File': 'Attached File'
}

export const userProfileSettings = {
    'Name': 'Name',
    'Gender': 'Gender',
    'Age': 'Age',
    'Mobile number': 'Mobile number',
    'Email id': 'Email id',
    'Location': 'Location',
    'Date of Birth': 'Date of Birth',
    'Health Condition': 'Health Condition',
    'About': 'About',
    'Family Members': 'Family Members',
}

// export const s3constant = {
//     'ACCESS_KEY': 'AKIAZYSYCNHPFUMY3XEX',
//     'SECRET_KEY': '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',
//     'REGION': 'ap-south-1',
//     'VERSION': 'v4',
//     'BUCKET_NAME': 'itwapp',
//     'S3URL': 'https://itwapp.s3.ap-south-1.amazonaws.com/'

// }

export const s3constant = {
    'ACCESS_KEY': 'AKIAZYSYCNHPKFFDQ24Y',
    'SECRET_KEY': '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',
    'REGION': 'ap-south-1',
    'VERSION': 'v4',
    'BUCKET_NAME': 'var-s3',
    'S3URL': 'https://var-s3.s3.ap-south-1.amazonaws.com/'

}

export const appointmentsSettings = {
    'Booking for': 'Booking for',
    'Patient Name': 'Patient Name',
    'Age': 'Age',
    'Gender': 'Gender',
    'Description': 'Description',
    'Upload Documents': 'Upload Documents'
}

export const appointmentStatus = {
    'Upcoming': 'Upcoming',
    'Completed': 'Completed',
    'inReview': 'inReview',
    'Rescheduled': 'Rescheduled',
    'Cancelled': 'Cancelled',
    'payment_pending': 'payment_pending',
    'reject': 'reject',
}

export const URLS = {
    API_BASE_URL: process.env.REACT_APP_BASE_URL
}

export const initialAvilability = [
    {
        "day": "monday",
        "day_index": 1,
        "start": "",
        "end": "",
        "status": null
    },
    {
        "day": "tuesday",
        "day_index": 2,
        "start": "",
        "end": "",
        "status": null
    },
    {
        "day": "wednesday",
        "day_index": 3,
        "start": "",
        "end": "",
        "status": null
    },
    {
        "day": "thursday",
        "day_index": 4,
        "start": "",
        "end": "",
        "status": null
    },
    {
        "day": "friday",
        "day_index": 5,
        "start": "",
        "end": "",
        "status": null
    }
]
